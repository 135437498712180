import {Box, Button, Grid, Link, Typography} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import useConfig from "../../hooks/useConfig";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

const FormListField = ({i18nKeyLabel, i18nKeyAddButton, editable, values, getValueText, onNew, onEdit, onDelete, disabled, error, showValidation, required}) => {
    const theme = useTheme();
    const {t} = useTranslation();

    const {gridItemSxLabelMultiline, gridItemSxLabelMultilineEditable, gridItemSxValue, gridItemSxValueEditable} = useConfig();

    const gridItemSxValueConditional = editable ? gridItemSxValueEditable : gridItemSxValue;
    if (values.length > 0 && editable) {
        gridItemSxValueConditional.paddingTop = '4px';
    }

    return (<>
        <Grid item xs={12} sm={4} sx={editable ? gridItemSxLabelMultilineEditable : gridItemSxLabelMultiline}>
            <Box sx={{padding: 0}}><Trans i18nKey={i18nKeyLabel}>{i18nKeyLabel}</Trans>
            {editable && required && <Typography component='span' sx={{color: theme.palette.error.main}}>*</Typography>}</Box>
        </Grid>
        <Grid item xs={12} sm={8} sx={{...gridItemSxValueConditional}}>
            {values.length > 0 ?
                <Box>
                    {values.map((value, index) => {
                        return (
                            <Box sx={{fontSize: 14}}
                                 key={'form-list-field-' + i18nKeyLabel + '-' + index}>
                                {editable ?
                                    <Link component="button"
                                          onClick={() => onEdit(value, index)}>{getValueText(value)}</Link>
                                    :
                                    <div>{getValueText(value)}</div>
                                }
                                {editable && (
                                    <IconButton
                                        variant="link"
                                        size="small"
                                        sx={{marginLeft: "10px"}}
                                        onClick={() => onDelete(index)}
                                    >
                                        <ClearIcon fontSize="inherit" />
                                    </IconButton>
                                )}

                            </Box>
                        );
                    })
                    }
                </Box>
                :
                !editable && "-"
            }
            {editable && (
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    sx={{
                        backgroundColor: '#fff',
                        textTransform: 'none',
                        marginTop: values.length > 0 ?? '11px'
                    }}
                    onClick={onNew}
                >
                    <Trans i18nKey={i18nKeyAddButton}>{i18nKeyAddButton}</Trans>
                </Button>
            )}
        </Grid>
    </>);
}

export default FormListField;
