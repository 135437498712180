import FormListField from "../forms/FormListField";
import {Trans, useTranslation} from "react-i18next";
import moment from "moment";
import {
    Autocomplete,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, MenuItem, Stack,
    TextField
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import FormDialog from "../forms/FormDialog";
import React, {useEffect, useState} from "react";
import FormAutocomplete from "../forms/FormAutocomplete";
import FormTextField from "../forms/FormTextField";
import FormDateField from "../forms/FormDateField";
import FormSelect from "../forms/FormSelect";

const ClientCareDegreesField = ({
                                    i18nKeyLabel,
                                    i18nKeyAddButton,
                                    i18nKeyDateFormat,
                                    values,
                                    onChange,
                                    editable
                                }) => {
    const {t} = useTranslation();
    const [value, setValue] = useState(null);
    const [index, setIndex] = useState(null);
    const [selectedCareDegree, setSelectedCareDegree] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    const handleSaveModal = () => {
        const newValues = [...values];
        if (index !== null) {
            newValues.splice(index, 1, value);
        } else {
            newValues.push(value);
        }
        setOpenModal(false);
        onChange(newValues);
    }

    return (<>
        <FormListField
            i18nKeyLabel={i18nKeyLabel}
            i18nKeyAddButton={i18nKeyAddButton}
            editable={editable}
            values={values}
            getValueText={(value) => {
                let text = t("clients.care-degrees.degree." + value.degree, "clients.care-degrees.degree." + value.degree);
                if (value.validFrom) {
                    text += ' (' + t(i18nKeyDateFormat, {date: value.validFrom.toJSDate()}) + ')';
                }
                return text;
            }}
            onNew={() => {
                setValue({
                    id: null,
                    validFrom: null,
                    degree: null,
                    persisted: false,
                });
                setIndex(null);
                setOpenModal(true);
            }}
            onEdit={(value, index) => {
                setValue(value);
                setIndex(index);
                setOpenModal(true);
            }}
            onDelete={(index) => {
                const newValues = [...values];
                newValues.splice(index, 1);
                onChange(newValues);
            }}
        />
        <FormDialog
            open={openModal}
            onClose={handleCloseModal}
            onCancel={handleCloseModal}
            onSave={handleSaveModal}
            i18nKeyTitle="clients.care-degrees.care-degree.title"
        >
            <FormSelect
                value={value?.degree ?? ""}
                editable={editable}
                onChange={(event) => {
                    setValue({...value, degree: event.target.value});
                }}
                i18nKeyLabel="clients.care-degrees.care-degree.label"
                i18nKeyPlaceholder="clients.care-degrees.are-degree.placeholder"
                getOptionLabel={(option) => t("clients.care-degrees.degree." + option, "clients.care-degrees.degree." + option)}
                valueText={t("clients.care-degrees.degree." + value?.degree, "clients.care-degrees.degree." + value?.degree)}
                disabled={false}
                showValidation={false}
                error={false}
                sx={{minWidth: 300}}
            >
                <MenuItem value={"1"}><Trans i18nKey="clients.care-degrees.degree.1">1</Trans></MenuItem>
                <MenuItem value={"2"}><Trans i18nKey="clients.care-degrees.degree.2">2</Trans></MenuItem>
                <MenuItem value={"3"}><Trans i18nKey="clients.care-degrees.degree.3">3</Trans></MenuItem>
                <MenuItem value={"4"}><Trans i18nKey="clients.care-degrees.degree.4">4</Trans></MenuItem>
                <MenuItem value={"5"}><Trans i18nKey="clients.care-degrees.degree.5">5</Trans></MenuItem>
            </FormSelect>


            <FormDateField
                value={value?.validFrom}
                editable={editable}
                onChange={(newValue) => {
                    setValue({...value, validFrom: newValue});
                }}
                i18nKeyLabel="clients.care-degrees.valid-from.label"
                i18nKeyPlaceholder="clients.care-degrees.valid-from.placeholder"
                valueText={value?.validFrom ? t("clients.care-degrees.valid-from.date", {date: moment(value.validFrom).toDate()}) : null}
                disabled={false}
                showValidation={false}
                error={false}/>

        </FormDialog>
    </>);
}

export default ClientCareDegreesField;
