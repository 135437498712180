import {
    Box,
    Button,
    FormControl,
    Grid,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TextField, Typography
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import useApi from "../../hooks/useApi";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import moment from "moment";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {DateTime} from "luxon";
import useRouting from "../../hooks/useRouting";
import {useTheme} from "@mui/material/styles";
import FormTextField from "../forms/FormTextField";
import FormSelect from "../forms/FormSelect";
import FormDateField from "../forms/FormDateField";
import FormSection from "../forms/FormSection";
import FormPhoneField from "../forms/FormPhoneField";

const ClientMasterData = ({
                              editable,
                              onSave,
                              onCancel,
                              setIsValid,
                              showValidation,
                              clientMasterData,
                              setClientMasterData,
                              showButtons = false
                          }) => {

    const {t} = useTranslation();
    const theme = useTheme();

    const [disabled, setDisabled] = useState(false);
    const [errFirstName, setErrFirstName] = useState(false);
    const [errLastName, setErrLastName] = useState(false);
    const [errGender, setErrGender] = useState(false);
    const [errStreet, setErrStreet] = useState(false);
    const [errStreetNumber, setErrStreetNumber] = useState(false);
    const [errZip, setErrZip] = useState(false);
    const [errCity, setErrCity] = useState(false);
    const [errBirthDate, setErrBirthDate] = useState(false);
    const [errPhone, setErrPhone] = useState(false);

    const headlineSx = {
        padding: 2,
    }

    const labelSx = {
        padding: 2,
        fontSize: 14,
        fontWeight: 600,
        color: '#6B7280',
        '@media (max-width:600px)': {
            paddingBottom: 0
        }
    }

    const labelMultilineSx = {
        paddingTop: editable ? 1 : 2,
        paddingBottom: editable ? 1 : 2,
        paddingLeft: 2,
        paddingRight: 2,
        fontSize: 14,
        fontWeight: 600,
        color: '#6B7280',
        '@media (max-width:600px)': {
            paddingBottom: 0
        }
    }

    const valueSx = {
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: editable ? 1 : 2,
        paddingBottom: editable ? 1 : 2,
        fontSize: 14,
    }

    const validate = () => {
        let valid = true;

        if (clientMasterData.firstName === '') {
            setErrFirstName(true);
            valid = false;
        } else {
            setErrFirstName(false);
        }

        if (clientMasterData.lastName === '') {
            setErrLastName(true);
            valid = false;
        } else {
            setErrLastName(false);
        }

        if (clientMasterData.gender === '') {
            setErrGender(true);
            valid = false;
        } else {
            setErrGender(false);
        }

        if (clientMasterData.street === '') {
            setErrStreet(true);
            valid = false;
        } else {
            setErrStreet(false);
        }

        if (clientMasterData.streetNumber === '') {
            setErrStreetNumber(true);
            valid = false;
        } else {
            setErrStreetNumber(false);
        }

        if (clientMasterData.zip === '') {
            setErrZip(true);
            valid = false;
        } else {
            setErrZip(false);
        }

        if (clientMasterData.city === '') {
            setErrCity(true);
            valid = false;
        } else {
            setErrCity(false);
        }

        if (clientMasterData.birthDate === null) {
            setErrBirthDate(true);
            valid = false;
        } else {
            setErrBirthDate(false);
        }

        if (clientMasterData.phone === '') {
            setErrPhone(true);
            valid = false;
        } else {
            setErrPhone(false);
        }

        setIsValid(valid);
        return valid;
    }

    const handleFieldChange = (event, fieldName) => {
        setClientMasterData({...clientMasterData, [fieldName]: event.target.value});
    }

    const handleTitleChange = (event) => {
        setClientMasterData({...clientMasterData, title: event.target.value});
    }

    const handleFirstNameChange = (event) => {
        setClientMasterData({...clientMasterData, firstName: event.target.value});
    }

    const handleLastNameChange = (event) => {
        setClientMasterData({...clientMasterData, lastName: event.target.value});
    }

    const handleGenderChange = (event) => {
        setClientMasterData({...clientMasterData, gender: event.target.value});
    }

    const handleStreetChange = (event) => {
        setClientMasterData({...clientMasterData, street: event.target.value});
    }

    const handleStreetNumberChange = (event) => {
        setClientMasterData({...clientMasterData, streetNumber: event.target.value});
    }

    const handleZipChange = (event) => {
        setClientMasterData({...clientMasterData, zip: event.target.value});
    }

    const handleCityChange = (event) => {
        setClientMasterData({...clientMasterData, city: event.target.value});
    }

    const handleBirthDateChange = (value) => {
        setClientMasterData({...clientMasterData, birthDate: value});
    }

    const handleEmailChange = (event) => {
        setClientMasterData({...clientMasterData, email: event.target.value});
    }

    const handlePhoneChange = (event) => {
        setClientMasterData({...clientMasterData, phone: event.target.value});
    }

    const handlePhoneCountryCodeChange = (event) => {
        setClientMasterData({...clientMasterData, phoneCountryCode: event.target.value});
    }

    useEffect(() => {
        validate();
    }, [showValidation, clientMasterData]);

    return (
        <FormSection
            editable={editable}
            showButtons={showButtons}
            title={t('clients.master-data', 'Master data')}
            onSave={onSave}
            onCancel={onCancel}
        >
            <FormSelect
                i18nKeyLabel="clients.title.label"
                i18nKeyPlaceholder="clients.title.placeholder"
                editable={editable}
                valueText={clientMasterData.title ? t('clients.title.' + clientMasterData.title, clientMasterData.title) : '-' }
                value={clientMasterData.title}
                onChange={handleTitleChange}
                disabled={disabled}
                showValidation={showValidation}
                required={false}
                displayEmpty={true}>
                <MenuItem sx={{fontSize: 14}} value={'doctor'}><Trans i18nKey="clients.title.doctor"></Trans></MenuItem>
                <MenuItem sx={{fontSize: 14}} value={'professor'}><Trans
                    i18nKey="clients.title.professor"></Trans></MenuItem>
                <MenuItem sx={{fontSize: 14}} value={'professor-doctor'}><Trans
                    i18nKey="clients.title.professor-doctor"></Trans></MenuItem>
            </FormSelect>

            <FormTextField
                required={true}
                value={clientMasterData.firstName}
                editable={editable}
                onChange={(e) => handleFieldChange(e, 'firstName')}
                i18nKeyLabel="clients.first-name.label"
                i18nKeyPlaceholder="clients.first-name.placeholder"
                valueText={clientMasterData.firstName}
                disabled={disabled}
                showValidation={showValidation}
                error={errFirstName}/>

            <FormTextField
                required={true}
                value={clientMasterData.lastName}
                editable={editable}
                onChange={handleLastNameChange}
                i18nKeyLabel="clients.last-name.label"
                i18nKeyPlaceholder="clients.last-name.placeholder"
                valueText={clientMasterData.lastName}
                disabled={disabled}
                showValidation={showValidation}
                error={errLastName}/>

            <FormSelect
                i18nKeyLabel="clients.gender.label"
                i18nKeyPlaceholder="clients.gender.placeholder"
                editable={editable}
                valueText={clientMasterData.gender ? t('clients.gender.' + clientMasterData.gender, clientMasterData.gender) : '-'}
                value={clientMasterData.gender}
                onChange={handleGenderChange}
                disabled={disabled}
                showValidation={showValidation}
                error={errGender}
                required={true}
                displayEmpty={true}>
                <MenuItem sx={{fontSize: 14}} value={'m'}><Trans i18nKey="clients.gender.m"></Trans></MenuItem>
                <MenuItem sx={{fontSize: 14}} value={'f'}><Trans i18nKey="clients.gender.f"></Trans></MenuItem>
                <MenuItem sx={{fontSize: 14}} value={'x'}><Trans i18nKey="clients.gender.x"></Trans></MenuItem>
            </FormSelect>

            <FormTextField
                required={true}
                value={clientMasterData.street}
                editable={editable}
                onChange={handleStreetChange}
                i18nKeyLabel="clients.street.label"
                i18nKeyPlaceholder="clients.street.placeholder"
                valueText={clientMasterData.street}
                disabled={disabled}
                showValidation={showValidation}
                error={errStreet}/>

            <FormTextField
                required={true}
                value={clientMasterData.streetNumber}
                editable={editable}
                onChange={handleStreetNumberChange}
                i18nKeyLabel="clients.street-number.label"
                i18nKeyPlaceholder="clients.street-number.placeholder"
                valueText={clientMasterData.streetNumber}
                disabled={disabled}
                showValidation={showValidation}
                error={errStreetNumber}/>

            <FormTextField
                required={true}
                value={clientMasterData.zip}
                editable={editable}
                onChange={handleZipChange}
                i18nKeyLabel="clients.zip.label"
                i18nKeyPlaceholder="clients.zip.placeholder"
                valueText={clientMasterData.zip}
                disabled={disabled}
                showValidation={showValidation}
                error={errZip}/>

            <FormTextField
                required={true}
                value={clientMasterData.city}
                editable={editable}
                onChange={handleCityChange}
                i18nKeyLabel="clients.city.label"
                i18nKeyPlaceholder="clients.city.placeholder"
                valueText={clientMasterData.city}
                disabled={disabled}
                showValidation={showValidation}
                error={errCity}/>

            <FormDateField
                i18nKeyLabel="clients.birthdate.label"
                i18nKeyPlaceholder="clients.birthdate.placeholder"
                i18nKeyDateFormat="clients.birthdate.date"
                editable={editable}
                valueText={clientMasterData.birthDate}
                value={clientMasterData.birthDate}
                onChange={handleBirthDateChange}
                disabled={disabled}
                showValidation={showValidation}
                error={errBirthDate}
                required={true}
            />

            {/* TODO: country */}

            <FormTextField
                value={clientMasterData.email}
                editable={editable}
                onChange={handleEmailChange}
                i18nKeyLabel="clients.email.label"
                i18nKeyPlaceholder="clients.email.placeholder"
                valueText={clientMasterData.email}
                disabled={disabled}
                showValidation={showValidation}
            />

            <FormPhoneField
                valuePhone={clientMasterData.phone}
                valueCountryCode={clientMasterData.phoneCountryCode}
                editable={editable}
                onPhoneChange={handlePhoneChange}
                onCountryCodeChange={handlePhoneCountryCodeChange}
                i18nKeyLabel="clients.phone.label"
                i18nKeyPlaceholder="clients.phone.placeholder"
                valueText={clientMasterData.phone}
                disabled={disabled}
                showValidation={showValidation}
                error={errPhone}
                required={true}
            />

        </FormSection>
    )
}

export default ClientMasterData;
