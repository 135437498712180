import React, {useContext} from 'react';
import useAccess from "./useAccess";


// This component is meant to be used everywhere a restriction based on user permission is needed
const AccessControl = ({permissions = [], or = false, and = true, children}) => {

    const {isAllowedTo} = useAccess();

    // If the user has that permission, render the children
    if(permissions.length === 0 || ((isAllowedTo(permissions) && and) || or)) {
        return <>{children}</>;
    }

    // Otherwise, do not render anything
    return null;
};

export default AccessControl;
