import FormListField from "../forms/FormListField";
import {Trans, useTranslation} from "react-i18next";
import moment from "moment";
import {
    Autocomplete,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, Stack,
    TextField
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import FormDialog from "../forms/FormDialog";
import React, {useEffect, useState} from "react";
import FormAutocomplete from "../forms/FormAutocomplete";
import FormTextField from "../forms/FormTextField";
import FormDateField from "../forms/FormDateField";

const ClientInstitutionsField = ({i18nKeyLabel, i18nKeyAddButton, i18nKeyDateFormat, values, onChange, institutions, editable}) => {
    const {t} = useTranslation();
    const [value, setValue] = useState(null);
    const [index, setIndex] = useState(null);
    const [selectedInstitution, setSelectedInstitution] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (institutions && value) {
            const institution = institutions.find((institution) => institution.id === value.insuranceId);
            setSelectedInstitution(institution ? institution : null);
        }
    }, [institutions, value?.insuranceId]);

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    const handleSaveModal = () => {
        const newValues = [...values];
        if (index !== null) {
            newValues.splice(index, 1, value);
        } else {
            newValues.push(value);
        }
        setOpenModal(false);
        onChange(newValues);
    }

    return (<>
        <FormListField
            i18nKeyLabel={i18nKeyLabel}
            i18nKeyAddButton={i18nKeyAddButton}
            editable={editable}
            values={values}
            getValueText={(value) => {
                let text = value.shortname;
                if (value.validFrom) {
                    text += ' (' + t(i18nKeyDateFormat, {date: value.validFrom.toJSDate()}) + ')';
                }
                return text;
            }}
            onNew={() => {
                setValue({
                    insuranceId: null,
                    insuranceNumber: '',
                    validFrom: null,
                    validTo: null,
                    persisted: false,
                });
                setIndex(null);
                setOpenModal(true);
            }}
            onEdit={(value, index) => {
                if (institutions) {
                    setSelectedInstitution(institutions.find((institution) => institution.id === value.insuranceId));
                }
                setValue(value);
                setIndex(index);
                setOpenModal(true);
            }}
            onDelete={(index) => {
                const newValues = [...values];
                newValues.splice(index, 1);
                onChange(newValues);
            }}
        />
        <FormDialog
            open={openModal}
            onClose={handleCloseModal}
            onCancel={handleCloseModal}
            onSave={handleSaveModal}
            i18nKeyTitle="clients.insurance.title"
        >
            { !institutions ?
                <Stack sx={{flexGrow: 1, padding: 2}} alignItems="center">
                    <CircularProgress/>
                </Stack>
                :
                <>
                    <FormAutocomplete
                        value={selectedInstitution}
                        editable={editable}
                        onChange={(newValue) => {
                            setSelectedInstitution(newValue);
                            setValue({...value, insuranceId: newValue.id, shortname: newValue.shortname});
                        }}
                        i18nKeyLabel="clients.insurance.label"
                        i18nKeyPlaceholder="clients.insurance.placeholder"
                        options={institutions}
                        getOptionLabel={(option) => option.shortname}
                        valueText={value ? value.shortname : null}
                        disabled={!!value?.persisted}
                        showValidation={false}
                        error={false} />

                    <FormTextField
                        value={value?.insuranceNumber}
                        editable={editable}
                        onChange={(event) => {
                            setValue({...value, insuranceNumber: event.target.value});
                        }}
                        i18nKeyLabel="clients.insurance-number.label"
                        i18nKeyPlaceholder="clients.insurance-number.placeholder"
                        valueText={value?.insuranceNumber}
                        disabled={false}
                        showValidation={false}
                        error={false} />

                    <FormDateField
                        value={value?.validFrom}
                        editable={editable}
                        onChange={(newValue) => {
                            setValue({...value, validFrom: newValue});
                        }}
                        i18nKeyLabel="clients.insurance-valid-from.label"
                        i18nKeyPlaceholder="clients.insurance-valid-from.placeholder"
                        valueText={value?.validFrom ? t(i18nKeyDateFormat, {date: value.validFrom.toJSDate()}) : null}
                        disabled={false}
                        showValidation={false}
                        error={false} />

                </>
            }


        </FormDialog>
    </>);
}

export default ClientInstitutionsField;
