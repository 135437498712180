import {FormControl, Grid, MenuItem, Select, Typography} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import useConfig from "../../hooks/useConfig";

const FormSelect = ({
                        i18nKeyLabel,
                        i18nKeyPlaceholder,
                        editable,
                        children,
                        valueText,
                        value,
                        onChange,
                        disabled,
                        error,
                        showValidation,
                        required,
                        displayEmpty = true
                    }) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const {gridItemSxLabel, gridItemSxValue, gridItemSxValueEditable} = useConfig();

    return (<>
        <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
            <Trans i18nKey={i18nKeyLabel}>{i18nKeyLabel}</Trans>
            {editable && required && <Typography component='span' sx={{color: theme.palette.error.main}}>*</Typography>}
        </Grid>
        <Grid item xs={12} sm={8} sx={editable ? gridItemSxValueEditable : gridItemSxValue}>
            {editable ?
                <FormControl
                    sx={{minWidth: 120}}
                    error={showValidation && error}
                    disabled={disabled}
                    size='small'>
                    <Select
                        sx={{fontSize: 14}}
                        value={value} onChange={onChange} displayEmpty={displayEmpty}>
                        {children}
                    </Select>
                </FormControl>
                :
                valueText ?? "-"
            }
        </Grid></>);
}

export default FormSelect;
