import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    CircularProgress, FormControl,
    Grid, InputAdornment,
    MenuItem, OutlinedInput,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import useApi from "../../hooks/useApi";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import useRouting from "../../hooks/useRouting";
import FormSection from "../forms/FormSection";
import FormTextField from "../forms/FormTextField";
import FormSelect from "../forms/FormSelect";
import FormAutocomplete from "../forms/FormAutocomplete";
import FormDateField from "../forms/FormDateField";
import {DateTime} from "luxon";
import {useTab} from "@mui/base";
import moment from "moment";


const ClientProtocol = (props) => {

    const {t} = useTranslation();
    const {id} = useParams();
    const {protocolId} = useParams();
    const routing = useRouting();

    const {queryClient, queryClientProtocol, saveOrUpdateClientProtocol} = useApi();
    const {data: client = null} = queryClient(id);
    const {goToClientProtocols, goToClientProtocol} = useRouting();


    const {data: clientProtocol = null, isLoading, refetch} = queryClientProtocol(id, protocolId);

    const [editable, setEditable] = useState(protocolId === 'new');

    const [disabled, setDisabled] = useState(false);
    const [description, setDescription] = useState("");

    const [errProtocolDescription, setErrProtocolDescription] = useState(false);
    const [showValidation, setShowValidation] = useState(false);

    const init = () => {
        setDescription(clientProtocol?.data?.content);
    }

    useEffect(() => {
        if (clientProtocol && clientProtocol?.data?.id) {
            init();
        }
    }, [clientProtocol]);

    const handleCancel = () => {
        setEditable(false);
        if (clientProtocol && clientProtocol?.data?.id) {
            init();
        } else {
            goToClientProtocols(id);
        }
    };

    const validate = () => {
        let valid = true;

        if (description === '') {
            setErrProtocolDescription(true);
            valid = false;
        } else {
            setErrProtocolDescription(false);
        }
        return valid;

    }
    const handleSave = () => {
        setDisabled(true);

        if (validate()) {
            let data = {};
            if (protocolId && protocolId !== "new") {
                data.id = protocolId;
            }
            data.content = description;

            saveOrUpdateClientProtocol(id, data).then((response) => {
                setDisabled(false);
                setEditable(false);
                if (response.data.id) {
                    refetch();
                    goToClientProtocol(id, response.data.id);
                } else {
                    refetch();
                }
            }).catch((error) => {
                setDisabled(false);
            });
        } else {
            setShowValidation(true);
            setDisabled(false);
        }
    };

    return (
        <Box sx={{padding: 2}}>
            {isLoading ?
                <Stack sx={{flexGrow: 1, padding: 2}} alignItems="center">
                    <CircularProgress/>
                </Stack>
                :
                <>
                    <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: 2}}>
                        <NavLink underline="hover" color="inherit" to="/clients">
                            <Trans i18nKey="clients.header">Clients</Trans>
                        </NavLink>
                        <NavLink color="text.primary" underline="hover" to={routing.getClientPath(id)}>
                            <Typography color="text.primary">{client?.person.last_name}, {client?.person.first_name}</Typography></NavLink>
                        <NavLink underline="hover" color="inherit"
                                 to={routing.getClientTabPath(id, 'protocols')}>
                            <Trans i18nKey="clients.protocols.header">Protocols</Trans>
                        </NavLink>
                        <Typography
                            color="text.primary">{clientProtocol?.data ? t('clients.protocols.created-at.date-time' , {date: moment(clientProtocol.data.created_at).toDate()}) : t('clients.protocols.title-new', 'New Protocol')}</Typography>
                    </Breadcrumbs>
                    <Paper elevation={0} sx={{marginBottom: 2, padding: 2, maxWidth: 1024}}>
                        <Grid container alignItems="center">
                            <Grid item xs={8}>
                                <Box sx={{paddingLeft: 2, display: 'flex', justifyContent: 'flex-start'}}>
                                    {editable && clientProtocol?.data?.id &&
                                        <Typography variant="h5"><Trans i18nKey={"clients.protocols.edit-title"}>Edit
                                            Protocol</Trans></Typography>
                                    }
                                    {editable && !clientProtocol?.data &&
                                        <Typography variant="h5"><Trans
                                            i18nKey={"clients.protocols.create-new-protocol"}>Create new Protocol
                                        </Trans></Typography>
                                    }
                                    {!editable &&
                                        <Typography
                                            variant="h5">{clientProtocol?.data ? t('clients.protocols.title', 'Protocol from')+" ("+t('clients.protocols.created-at.date-time' , {date: moment(clientProtocol.data.created_at).toDate()})+")" : t('clients.protocols.title-new', 'New Protocol')}</Typography>
                                    }

                                </Box>
                            </Grid>
                            <Grid item xs={4} justifyContent="flex-end">
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {!editable &&
                                        <Button
                                            sx={{textTransform: 'none'}}
                                            variant="outlined"
                                            size="small"
                                            disableElevation
                                            onClick={() => {
                                                setEditable(!editable)
                                            }}
                                        >
                                            <Trans i18nKey="employee.edit">Edit</Trans>
                                        </Button>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>

                    <FormSection
                        editable={editable}
                        onCancel={handleCancel}
                        onSave={handleSave}
                        showButtons={true}
                    >

                        <FormTextField
                            multiline={true}
                            error={errProtocolDescription}
                            value={description}
                            editable={editable}
                            showButtons={true}
                            i18nKeyLabel="clients.protocols.description"
                            valueText={description ?? '-'}
                            onChange={(e) => setDescription(e.target.value)}
                            disabled={disabled}
                            showValidation={showValidation}
                        />

                    </FormSection>
                </>
            }
        </Box>
    );
}

export default ClientProtocol;
