import useUser from "../../hooks/useUser";

const useAccess = () => {
    const {getPermissions} = useUser();
    const userPermissions = getPermissions();

    const isAllowedTo = (permissions) => {
        if (typeof permissions === 'string') {
            permissions = [permissions];
        }
        for (const permission of permissions) {
            if (userPermissions.includes(permission))
                return true;
        }
        return false;
    }

    return {
        isAllowedTo
    }
}

export default useAccess;
