import {Button, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {Trans} from "react-i18next";
import Dialog from "@mui/material/Dialog";

const FormDialog = ({i18nKeyTitle, open, onClose, onCancel, onSave, children}) => {
    return (<Dialog
        open={open}
        onClose={onClose}
        maxWidth={'sm'}
        fullWidth={true}
    >
        <DialogTitle><Trans i18nKey={i18nKeyTitle}>{i18nKeyTitle}</Trans></DialogTitle>
        <DialogContent dividers>
            <Grid container alignItems="center">
                {children}
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button
                variant="outlined"
                size="medium"
                sx={{textTransform: 'none', width: "93px", height: "34px", marginRight: 0}}
                onClick={onCancel}
            >
                <Trans i18nKey="forms.form-dialog.cancel">Cancel</Trans>
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                disableElevation
                sx={{textTransform: 'none', width: "93px", height: "34px", marginRight: 0}}
                onClick={onSave}
            >
                <Trans i18nKey="forms.form-dialog.add">Add</Trans>
            </Button>
        </DialogActions>
    </Dialog>);
}

export default FormDialog;
