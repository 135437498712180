import {useTheme} from "@mui/material/styles";
import useRouting from "../hooks/useRouting";
import {Box, Button, Fab, Grid, Paper, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import {Trans, useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import React from "react";
import AddIcon from '@mui/icons-material/Add';
import AccessControl from "./access/AccessControl";

function QuickSearchToolbar(props) {
    const {t} = useTranslation();

    return (
        <Grid container
              item
              direction="row"
              justifyContent="flex-end"
              sx={{
                  p: 2
              }}
        >
            <Box
                sx={{
                    flexGrow: 1,
                    m: 0,
                    p: 0
                }}>
                <TextField
                    variant="outlined"
                    size="small"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    placeholder={t("quick-search-toolbar.search", "Search")}
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                onClick={props.clearSearch}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                    sx={{
                        maxWidth: 600,
                        margin: '0 4px 0',
                        marginTop: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        width: {
                            xs: 1,
                            sm: 'auto',
                        },
                        m: (theme) => theme.spacing(1, 0.5, 1.5),
                        '& .MuiSvgIcon-root': {
                            mr: 0.5,
                        },
                        '& .MuiInput-underline:before': {
                            borderBottom: 1,
                            borderColor: 'divider',
                        },
                        backgroundColor: '#fff'
                    }}
                />
            </Box>
            <AccessControl permissions={props.permissions} >
                <Button
                    variant="contained"
                    size="small"
                    shadow={0}
                    sx={{
                        textTransform: 'none',
                        fontSize: 14,
                        '@media (max-width: 599px)': {
                            display: 'none',
                        },
                    }}
                    disableElevation
                    onClick={props.onClickCreate}
                >
                    {props.createButtonLabel}
                </Button>
                <Fab
                    sx={{
                        position: 'fixed',
                        bottom: 16,
                        right: 16,
                        '@media (min-width: 600px)': {
                            display: 'none',
                        },
                    }}
                    color="primary"
                    aria-label="add"
                    onClick={props.onClickCreate}
                >
                    <AddIcon />
                </Fab>
            </AccessControl>
        </Grid>
    );
}
export default QuickSearchToolbar;

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    onClickCreate: PropTypes.func,
    onClickDownload: PropTypes.func,
    createButtonLabel: PropTypes.any
};
