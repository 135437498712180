import {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import useApi from "../hooks/useApi";
import {Trans, useTranslation} from "react-i18next";
import Autocomplete from "./Autocomplete";
import moment from "moment";
import {useParams} from "react-router-dom";
import Spinner from "./Spinner";
import {CheckIcon, DocumentCheckIcon, PencilIcon} from "@heroicons/react/24/outline";
import ConfirmationModal from "./ConfirmationModal";
import {PlusIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {TrashIcon} from "@heroicons/react/20/solid";

const EventModal = ({currentUserId, currentEmployeeId, teamId, canTeamEvents, isOpen, onClose, date}) => {
    const {t} = useTranslation();
    const {id} = useParams();
    const {type} = useParams();

    const {
        queryEmployees,
        queryEventTypes,
        queryPatients,
        queryServices,
        queryActiveBudgets,
        saveOrUpdateEvent,
        queryEvent,
        updateEventStatus,
        deleteEvent
    } = useApi();

    const {data: employees = [], isLoading: isLoadingEmployees = true} = queryEmployees();
    const {data: eventTypes = [], isLoading: isLoadingEventTypes = true} = queryEventTypes();
    const {data: teamClients = [], isLoading: isLoadingTeamClients = true} = queryPatients(null, teamId);
    const {data: services = [], isLoading: isLoadingServices = true} = queryServices();
    const {data: event = null, isLoading: isLoadingEvent, refetch} = queryEvent(id);


    // const userIdMeta = document.querySelector('meta[name="user_id"]');
    // const userId = userIdMeta ? userIdMeta.content : null;
    const [editable, setEditable] = useState(!id);
    const [disabled, setDisabled] = useState(false);
    const [eventId, setEventId] = useState(id ? parseInt(id) : null);
    const [status, setStatus] = useState(null);
    const [employeeId, setEmployeeId] = useState(canTeamEvents ? -1 : currentEmployeeId);
    const [employeeIdError, setEmployeeIdError] = useState(false);
    const [employeeIds, setEmployeeIds] = useState([]);
    const [eventTypeId, setEventTypeId] = useState(type ? type.toUpperCase() : "NONE");
    const [eventTypeIdError, setEventTypeIdError] = useState(false);
    const [client, setClient] = useState(null);
    const [clientError, setClientError] = useState(false);
    const [listOfEmployeesError, setListOfEmployeesError] = useState(false);
    const [service, setService] = useState(null);
    const [serviceError, setServiceError] = useState(false);
    const [budgetId, setBudgetId] = useState(-1);
    const [budgetIdError, setBudgetIdError] = useState(false);
    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState(false);
    const [location, setLocation] = useState("");
    const [locationError, setLocationError] = useState(false);
    const [start, setStart] = useState(date ?? "");
    const [startError, setStartError] = useState(false);
    const [end, setEnd] = useState("");
    const [endError, setEndError] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [notes, setNotes] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationModalMessage, setConfirmationModalMessage] = useState("");
    const [confirmationModalData, setConfirmationModalData] = useState(null);
    const [listOfUpdatedEmployees, setListOfUpdatedEmployees] = useState([]);

    const {data: budgets = [], isLoading: isLoadingBudgets = true} = queryActiveBudgets(client);
    const {data: clients = [], isLoading: isLoadingClients = true} = queryPatients(employeeId, teamId);

    useEffect(() => {
        if (date) {
            if (date.allDay) {
                setStart(moment(date.dateStr).format('YYYY-MM-DD'));
                setEnd(moment(date.dateStr).format('YYYY-MM-DD'));
            } else {
                setStart(moment(date.dateStr).format('YYYY-MM-DDTHH:mm'))
                setEnd(moment(date.dateStr).add(1, 'hours').format('YYYY-MM-DDTHH:mm'));
            }
        } else if(eventTypeId === "INTERNAL") {
            setStart(moment().hour(8).minute(0).format('YYYY-MM-DDTHH:mm'));
            setEnd(moment().hour(8).minute(30).format('YYYY-MM-DDTHH:mm'));
        } else {
            setStart("");
            setEnd("");
        }
    }, [date]);

    const getEmployeeName = (id) => {
        if (employees) {
            const employee = employees.data.find((employee) => employee.id === id);
            if (employee) {
                return employee ? `${employee.person.first_name} ${employee.person.last_name}` : "";
            }
            return <Trans i18nKey="team-calendar.not-assigned">Not assigned</Trans>;
        }
        return "";
    }

    const getClient = (id) => {
        let client = null;
        if (teamClients) {
            client = teamClients.find((c) => c.id === id)
        }
        if (!client && event.client?.id === id) {
            client = event.client;
        }
        return client;
    }

    const getEmployee = (id) => {
        let employee = null;
        if (employees) {
            employee = employees.data.find((employee) => employee.id === id)
        }
        if (!employee && event.employees.length > 0) {
            employee = event.employees.find((employee) => employee.id === id);
        }
        return employee;
    }

    const getClientName = (id) => {
        const c = getClient(id);
        if (c) {
            return c.person.first_name + ' ' + c.person.last_name;
        }
        return "";
    }

    const getService = (id) => {
        if (services) {
            return services.find((service) => service.id === id);
        }
        return null;
    }

    const getServiceName = (id) => {
        const s = getService(id);
        if (s) {
            return s.name;
        }
        return "";
    }

    const getBudget = (id) => {
        if (budgets) {
            return budgets.find((budget) => budget.id === id);
        }
        return null;
    }

    useEffect(() => {
        if (event) {
            setStatus(event.status);
            setEmployeeId(event.employee_ids.length > 0 ? event.employee_ids[0] : '-1');
            setEmployeeIds(event.employee_ids);
            setEventTypeId(event.event_type_id);
            setClient(getClient(parseInt(event.client_id)));
            setService(getService(parseInt(event.service_id)));
            //(getEmployee(parseInt(event.employees)));
            setBudgetId(event.budget_type_id ?? -1);
            setTitle(event.title);
            setIsPaid(event.paid);
            setLocation(event.location);
            setStart(moment(event.start).format('YYYY-MM-DDTHH:mm'));
            setEnd(moment(event.end).format('YYYY-MM-DDTHH:mm'));
            setNotes(event.note);
        }
    }, [event]);

    useEffect(() => {
        if (event) {
            setClient(getClient(parseInt(event.client_id)));
        }
    }, [teamClients]);

    useEffect(() => {
        if (event) {
            setService(getService(parseInt(event.service_id)));
        }
    }, [services]);

    useEffect(() => {
        if (client && service && eventTypeId === "CLIENT_APPOINTMENT") {
            setTitle(client.person.first_name + ' ' + client.person.last_name + ' -- ' + service.name);
        }
    }, [client, service]);

    useEffect(() => {
        setBudgetId(-1);
        console.log(client);
        if (client?.person?.address) {
            setLocation(client.person.address.street + ' ' + client.person.address.street_number + ', ' + client.person.address.zip + ' ' + client.person.address.city);
        } else {
            setLocation("");
        }
    }, [client]);

    const filterClients = (options, inputValue) => {
        return options.filter((option) => {
            if (inputValue === "")
                return true;
            inputValue = inputValue.replace(/[^a-zA-Z0-9 ]/g, '');
            let found = false;
            inputValue.split(" ").filter(Boolean).forEach((word) => {
                if (option.person && option.person.first_name.toLowerCase().startsWith(word.toLowerCase()) ||
                    option.person && option.person.last_name.toLowerCase().startsWith(word.toLowerCase()) ||
                    option.client_number.toLowerCase().startsWith(word.toLowerCase()))
                    found = true;
            });
            return found;
        });
    };

    const sortClients = (options) => {
        return options.sort((a, b) => {
            if (a.person && b.person) {
                if (a.person.last_name < b.person.last_name)
                    return -1;
                if (a.person.last_name > b.person.last_name)
                    return 1;
                if (a.person.first_name < b.person.first_name)
                    return -1;
                if (a.person.first_name > b.person.first_name)
                    return 1;
            }
            return 0;
        });
    }

    const renderClient = (option) => {
        return option && option.person ? `${option.person.last_name}, ${option.person.first_name}` : "";
    }

    const filterEmployees = (options, inputValue) => {
        return options.filter((option) => {
            if (inputValue === "")
                return true;
            inputValue = inputValue.replace(/[^a-zA-Z0-9 ]/g, '');
            let found = false;
            inputValue.split(" ").filter(Boolean).forEach((word) => {
                if (option.person && option.person.first_name.toLowerCase().startsWith(word.toLowerCase()) ||
                    option.person && option.person.last_name.toLowerCase().startsWith(word.toLowerCase()))
                    found = true;
            });
            return found;
        });
    };

    const renderEmployee = (employee) => {
        return employee && employee.person ? `${employee.person.first_name} ${employee.person.last_name}` : "";
    }

    const renderEmployeeById = (employeeId) => {
        const employee = getEmployee(employeeId);
        return renderEmployee(employee);
    }

    const filterServices = (options, inputValue) => {
        return options.filter((option) => {
            if (inputValue === "")
                return true;
            let found = false;
            inputValue.split(" ").filter(Boolean).forEach((word) => {
                if (option.name.toLowerCase().startsWith(word.toLowerCase()))
                    found = true;
            });
            return found;
        });
    };

    const renderServices = (option) => {
        return option ? option.name : "";
    }

    const onChangeStart = (e) => {
        setStart(e.target.value);
    }

    const onChangedStart = (e) => {
        if (end === "" || moment(e.target.value).isSameOrAfter(end)) {
            if (eventTypeId === "CLIENT_APPOINTMENT")
                setEnd(moment(e.target.value).add(1, 'hours').format('YYYY-MM-DDTHH:mm'));
            else
                setEnd(moment(e.target.value).format('YYYY-MM-DDTHH:mm'));
        }
    }

    const onChangeEnd = (e) => {
        setEnd(e.target.value);
    }

    const onChangedEnd = (e) => {
        if (start === "" || moment(e.target.value).isSameOrBefore(start)) {
            if (eventTypeId === "CLIENT_APPOINTMENT")
                setStart(moment(e.target.value).subtract(1, 'hours').format('YYYY-MM-DDTHH:mm'));
            else
                setStart(moment(e.target.value).format('YYYY-MM-DDTHH:mm'));
        }
    }

    const validate = () => {
        let valid = true;
        setErrorMessage("");
        if (eventTypeId === "CLIENT_APPOINTMENT") {
            if (employeeId === -1 && !canTeamEvents) {
                setEmployeeIdError(true);
                valid = false;
            } else {
                setEmployeeIdError(false);
            }
            if (eventTypeId === "NONE") {
                setEventTypeIdError(true);
                valid = false;
            } else {
                setEventTypeIdError(false);
            }
            if (client === null) {
                setClientError(true);
                valid = false;
            } else {
                setClientError(false);
            }
            // if (budgetId === -1) {
            //     setBudgetIdError(true);
            //     valid = false;
            // } else {
            //     setBudgetIdError(false);
            // }
            if (service === null) {
                setServiceError(true);
                valid = false;
            } else {
                setServiceError(false);
            }
            if (title === "") {
                setTitleError(true);
                valid = false;
            } else {
                setTitleError(false);
            }
            if (location === "") {
                setLocationError(true);
                valid = false;
            } else {
                setLocationError(false);
            }
        }
        if (start === '') {
            setStartError(true);
            valid = false;
        } else {
            setStartError(false);
        }
        if (end === '') {
            setEndError(true);
            valid = false;
        } else {
            setEndError(false);
        }
        return valid;
    }

    const handleSave = () => {
        setDisabled(true);
        if (validate()) {
            let data = {};
            if (id) {
                data.id = eventId;
            }
            data.start = start;
            data.team_id = teamId;
            data.end = end;
            data.note = notes;
            data.event_type_id = eventTypeId;
            data.employee_ids = employeeId && employeeId !== -1 ? [employeeId] : [];
            data.all_day = 1;
            if (eventTypeId === "INTERNAL") {
                data.user_id = currentUserId;
                data.paid = isPaid;
                data.employee_ids = employeeIds.find((id) => id !== -1);
                data.all_day = 0;
                data.title = title;
            }
            if (eventTypeId === "CLIENT_APPOINTMENT") {
                data.status = 'NOT_PERFORMED';
                data.service_id = service.id;
                data.client_id = client.id;
                data.budget_type_id = budgetId !== -1 ? budgetId : null;
                data.title = title;
                data.location = location;
                data.all_day = 0;
            } else {
                data.status = 'NULL';
            }


            saveOrUpdateEvent(data).then((response) => {
                /* newUpdatedEventCallback(true);*/
                setDisabled(false);
                onClose(response.data, eventId === null ? 'created' : 'updated');
            }).catch((error) => {
                setDisabled(false);
                if (error.response?.status === 422) {
                    let errors = error.response.data.errors;
                    if (errors.user_id) {
                        if (errors.user_id.includes("validation.events.user_id_unique")) {
                            setStartError(true);
                            setEndError(true);
                            setErrorMessage(t('validation.events.user_id_unique'));
                        } else {
                            setEmployeeIdError(true);
                        }
                    }
                    if (errors.event_type_id) {
                        setEventTypeIdError(true);
                    }
                    if (errors.client_id) {
                        setClientError(true);
                    }
                    if (errors.budget_type_id) {
                        setBudgetIdError(true);
                    }
                    if (errors.service_id) {
                        setServiceError(true);
                    }
                    if (errors.title) {
                        setTitleError(true);
                    }
                    if (errors.location) {
                        setLocationError(true);
                    }
                    if (errors.start) {
                        setStartError(true);
                    }
                    if (errors.end) {
                        setEndError(true);
                    }
                } else {
                    console.log(error);
                }
            });
        } else {
            setDisabled(false);
        }
    };

    const handleUpdateEventStatus = (status) => {
        if (status === 'PERFORMED') {
            setConfirmationModalMessage(t('team-calendar.confirmation-modal.message-performed', 'Are you sure you want to mark this appointment as performed?'))
            setConfirmationModalData({action: 'status', status: status, eventId: eventId});
        } else if (status === 'CONFIRMED') {
            setConfirmationModalMessage(t('team-calendar.confirmation-modal.message-confirmed', 'Are you sure you want to confirm this appointment?'))
            setConfirmationModalData({action: 'status', status: status, eventId: eventId});
        } else if (status === 'CANCELLED') {
            setConfirmationModalMessage(t('team-calendar.confirmation-modal.message-cancelled', 'Are you sure you want to delete this appointment?'))
            setConfirmationModalData({action: 'delete', eventId: eventId});
        }
        setShowConfirmationModal(true);
    }

    const onCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
    }

    const onConfirm = (data) => {
        if (data.action === 'status') {
            updateEventStatus(data.eventId, data.status, event.employee_ids).then((response) => {
                onClose(response.data, 'updated');
                setStatus(data.status);
                refetch();
                setShowConfirmationModal(false);
            }).catch((error) => {
                setShowConfirmationModal(false);
            });
        } else if (data.action === 'assign') {
            let employeeIds = [currentEmployeeId];
            updateEventStatus(data.eventId, event.status, employeeIds).then((response) => {
                setEmployeeId(currentUserId);
                refetch();
                onClose(response.data, 'updated', true);
                setShowConfirmationModal(false);
            }).catch((error) => {
                setShowConfirmationModal(false);
            });
        } else if (data.action === 'unassign') {
            let data = {...event};
            let employeeIds = [];
            updateEventStatus(data.eventId, event.status, employeeIds).then((response) => {
                setEmployeeId(-1);
                refetch();
                onClose(response.data, 'updated', true);
                setShowConfirmationModal(false);
            }).catch((error) => {
                setShowConfirmationModal(false);
            });
        } else if (data.action === 'delete') {
            deleteEvent(data.eventId).then((response) => {
                onClose({id: data.eventId}, 'deleted', true);
                setShowConfirmationModal(false);
            }).catch((error) => {
                setShowConfirmationModal(false);
            });

        }
    }

    const handleUnassignFromEvent = () => {
        setConfirmationModalMessage(t('team-calendar.confirmation-modal.message-unassign-from-event', 'Do you want to unassign from the event?'))
        setConfirmationModalData({action: 'unassign', eventId: eventId});
        setShowConfirmationModal(true);
    }

    const handleAssignToEvent = () => {
        setConfirmationModalMessage(t('team-calendar.confirmation-modal.message-assign-to-event', 'Do you want to assign to the event?'))
        setConfirmationModalData({action: 'assign', eventId: eventId});
        setShowConfirmationModal(true);
    }

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-20 overflow-y-auto"
                    onClose={() => onClose(null)}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30"/>
                        {isLoadingEvent || (!editable && isLoadingClients) || isLoadingEmployees || isLoadingEventTypes || isLoadingServices || isLoadingBudgets ?
                            <Spinner/> :
                            <div
                                className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title as="h3"
                                              className="flex items-start justify-between pb-4 border-b text-gray-900 text-xl lg:text-2xl font-semibold">
                                    {eventId ?
                                        <Trans i18nKey="team-calendar.event-modal.title-edit">Edit Event</Trans>
                                        :
                                        type ?
                                            <Trans i18nKey="team-calendar.event-modal.title-new-event-type"
                                                   values={{eventType: t('team-calendar.event-types.' + type, type)}}>Create
                                                type</Trans>
                                            :
                                            <Trans i18nKey="team-calendar.event-modal.title-new-event">Create
                                                Event</Trans>
                                    }
                                    <button
                                        onClick={() => onClose(null)}
                                        type="button"
                                        className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                        aria-label="Close">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="h-6 w-6">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"/>
                                        </svg>
                                    </button>
                                </Dialog.Title>
                                <div className="mt-2">
                                    {/*<form>*/}
                                    <div className="space-y-4">
                                        {!editable && (canTeamEvents || event?.employee_ids.includes(currentEmployeeId)) &&
                                            <div className="flex items-center justify-end space-x-2">
                                                <button onClick={() => setEditable(true)}
                                                        className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                                                    <PencilIcon className="h-5 w-5 text-gray-500" aria-hidden="true"/>
                                                </button>
                                                {event?.employee_ids.includes(currentEmployeeId) && eventTypeId === 'CLIENT_APPOINTMENT' && status !== 'PERFORMED' && status !== 'CONFIRMED' && moment(start).isAfter(moment()) &&
                                                    <button onClick={handleUnassignFromEvent}
                                                            className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                                                        <XMarkIcon className="h-5 w-5 text-gray-500" aria-hidden="true"/>
                                                    </button>
                                                }
                                                {eventTypeId === 'CLIENT_APPOINTMENT' && status === 'PERFORMED' && moment(start).isBefore(moment()) &&
                                                    <button onClick={(e) => handleUpdateEventStatus('CONFIRMED')}
                                                            className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                            title={t('team-calendar.event-modal.switch-status-performed', 'Set status to confirmed')}>
                                                        <DocumentCheckIcon className="h-5 w-5 text-gray-500"
                                                                           aria-hidden="true"/>
                                                    </button>
                                                }
                                                {eventTypeId === 'CLIENT_APPOINTMENT' && status !== 'PERFORMED' && status !== 'CONFIRMED' && moment(start).isBefore(moment()) &&
                                                    <button onClick={(e) => handleUpdateEventStatus('PERFORMED')}
                                                            className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                            title={t('team-calendar.event-modal.switch-status-performed', 'Set status to performed')}>
                                                        <CheckIcon className="h-5 w-5 text-gray-500"
                                                                   aria-hidden="true"/>
                                                    </button>
                                                }
                                                {(eventTypeId !== 'CLIENT_APPOINTMENT' || (status !== 'PERFORMED' && status !== 'CONFIRMED')) &&
                                                    <button onClick={(e) => handleUpdateEventStatus('CANCELLED')}
                                                            className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                            title={t('team-calendar.event-modal.switch-status-performed', 'Set status to performed')}>
                                                        <TrashIcon className="h-5 w-5 text-gray-500" aria-hidden="true"/>
                                                    </button>
                                                }
                                            </div>
                                        }
                                        {!editable && !canTeamEvents && event?.employee_ids.length === 0 &&
                                            <div className="flex items-center justify-end space-x-2">
                                                {eventTypeId === 'CLIENT_APPOINTMENT' && status !== 'PERFORMED' && status !== 'CONFIRMED' && moment(start).isAfter(moment()) &&
                                                    <button onClick={(e) => handleAssignToEvent()}
                                                            className="border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                                            title={t('team-calendar.event-modal.switch-status-performed', 'Set status to performed')}>
                                                        <PlusIcon className="h-5 w-5 text-gray-500"
                                                                    aria-hidden="true"/>
                                                    </button>
                                                }
                                            </div>
                                        }
                                        {(canTeamEvents || event && !event.employee_ids.includes(currentEmployeeId)) && eventTypeId !== 'INTERNAL' &&
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                                <label htmlFor="employee"
                                                       className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                                    <Trans i18nKey="team-calendar.event-modal.employee">Employee</Trans>
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    {editable ?
                                                        <select
                                                            id="employee"
                                                            name="employee"
                                                            value={employeeId}
                                                            onChange={(e) => setEmployeeId(e.target.value)}
                                                            className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none  sm:text-sm ${employeeIdError ? "border-red-500" : "border-gray-300"}`}
                                                        >
                                                            {canTeamEvents ?
                                                                <option value={-1}><Trans
                                                                    i18nKey="team-calendar.event-modal.all-employees-of-team">all
                                                                    employees of team</Trans></option>
                                                                :
                                                                <option disabled value={-1}><Trans
                                                                    i18nKey="team-calendar.event-modal.select-employee">select
                                                                    an employee</Trans></option>
                                                            }
                                                            {employees && employees.data.map(employee => (
                                                                <option key={employee.id}
                                                                        value={employee.id}>{employee.person.first_name} {employee.person.last_name}</option>
                                                            ))}
                                                        </select>
                                                        :
                                                        <div
                                                            className="text-sm text-gray-700 sm:mt-px sm:pt-2">{getEmployeeName(employeeId)}</div>
                                                    }
                                                </div>
                                            </div>
                                        }

                                        {/*<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">*/}
                                        {/*    <label htmlFor="event-type" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">*/}
                                        {/*        <Trans i18nKey="team-calendar.event-modal.event-type">Event Type</Trans>*/}
                                        {/*    </label>*/}
                                        {/*    <div className="mt-1 sm:mt-0 sm:col-span-2">*/}
                                        {/*        { editable ?*/}
                                        {/*            <select*/}
                                        {/*                id="event-type"*/}
                                        {/*                name="event-type"*/}
                                        {/*                value={eventTypeId}*/}
                                        {/*                onChange={(e) => setEventTypeId(e.target.value)}*/}
                                        {/*                className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none sm:text-sm ${eventTypeIdError ? "border-red-500" : "border-gray-300"}`}*/}
                                        {/*            >*/}
                                        {/*                <option disabled value={"NONE"}><Trans i18nKey="team-calendar.event-modal.select-event-type">select an event type</Trans></option>*/}
                                        {/*                {eventTypes && Object.entries(eventTypes).map(([key, value]) => (*/}
                                        {/*                    <option key={key} value={key}>{value.translated_string}</option>*/}
                                        {/*                ))}*/}
                                        {/*            </select>*/}
                                        {/*        :*/}
                                        {/*            <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">{eventTypes && eventTypeId && eventTypeId !== "NONE" ? eventTypes[eventTypeId].translated_string : "-"}</div>*/}
                                        {/*        }*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {eventTypeId === "CLIENT_APPOINTMENT" &&
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                                <label htmlFor="patient"
                                                       className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                                    <Trans i18nKey="team-calendar.event-modal.patient">Patient</Trans>
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    {editable ?
                                                        <Autocomplete options={clients}
                                                                      error={clientError}
                                                                      value={client}
                                                                      onSelect={(c) => setClient(c)}
                                                                      renderOption={renderClient}
                                                                      renderValue={renderClient}
                                                                      filterFunction={filterClients}
                                                                      sortFunction={sortClients}
                                                                      placeholder={t('team-calendar.event-modal.select-patient')}/>
                                                        :
                                                        <div
                                                            className="text-sm text-gray-700 sm:mt-px sm:pt-2">{client ? renderClient(client) : '-'}</div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {eventTypeId === "CLIENT_APPOINTMENT" &&
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                                <label htmlFor="service"
                                                       className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                                    <Trans i18nKey="team-calendar.event-modal.service">Service</Trans>
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    {editable ?
                                                        <Autocomplete options={services}
                                                                      error={serviceError}
                                                                      value={service}
                                                                      onSelect={(service) => setService(service)}
                                                                      renderOption={renderServices}
                                                                      renderValue={renderServices}
                                                                      filterFunction={filterServices}
                                                                      placeholder={t('team-calendar.event-modal.select-service')}/>
                                                        :
                                                        <div
                                                            className="text-sm text-gray-700 sm:mt-px sm:pt-2">{service ? renderServices(service) : '-'}</div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {eventTypeId === "CLIENT_APPOINTMENT" &&
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                                <label
                                                    htmlFor="budget"
                                                    className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                                >
                                                    <Trans i18nKey="team-calendar.event-modal.budget">Budget</Trans>
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    {editable ?
                                                        <select
                                                            id="budget"
                                                            name="budget"
                                                            value={budgetId}
                                                            onChange={(e) => setBudgetId(e.target.value)}
                                                            className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none sm:text-sm ${budgetIdError ? "border-red-500" : "border-gray-300"}`}
                                                        >
                                                            <option disabled value={-1}><Trans
                                                                i18nKey="team-calendar.event-modal.select-budget">select
                                                                a budget</Trans></option>
                                                            {budgets && budgets.map(budget => (
                                                                <option key={budget.id}
                                                                        value={budget.id}>{budget.name}</option>
                                                            ))}
                                                        </select>
                                                        :
                                                        <div
                                                            className="text-sm text-gray-700 sm:mt-px sm:pt-2">{budgets && budgetId && budgetId !== -1 ? getBudget(budgetId).name : '-'}</div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {(eventTypeId === "CLIENT_APPOINTMENT" || eventTypeId === "INTERNAL")  &&
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                                <label htmlFor="title"
                                                       className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                                    <Trans i18nKey="team-calendar.event-modal.title">Title</Trans>
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    {editable ?
                                                        <input
                                                            type="text"
                                                            id="title"
                                                            name="title"
                                                            value={title}
                                                            onChange={(e) => setTitle(e.target.value)}
                                                            className={`mt-1 block w-full py-2 px-3 border border-gray-300 bg-white shadow-sm focus:outline-none  sm:text-sm ${titleError ? "border-red-500" : "border-gray-300"}`}
                                                            placeholder={t('team-calendar.event-modal.enter-title')}
                                                        />
                                                        :
                                                        <div
                                                            className="text-sm text-gray-700 sm:mt-px sm:pt-2">{title}</div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {eventTypeId === "CLIENT_APPOINTMENT" &&
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                                <label htmlFor="location"
                                                       className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                                    <Trans i18nKey="team-calendar.event-modal.location">Location</Trans>
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    {editable ?
                                                        <input
                                                            type="text"
                                                            id="location"
                                                            name="location"
                                                            value={location}
                                                            onChange={(e) => setLocation(e.target.value)}
                                                            className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none  sm:text-sm ${locationError ? "border-red-500" : "border-gray-300"}`}
                                                            placeholder={t('team-calendar.event-modal.enter-location')}
                                                        />
                                                        :
                                                        <div
                                                            className="text-sm text-gray-700 sm:mt-px sm:pt-2">{location}</div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {eventTypeId === "INTERNAL" && (
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200">
                                                <label
                                                    htmlFor="paid"
                                                    className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px"
                                                    style={{paddingTop: '0'}}
                                                >
                                                    <Trans i18nKey="team-calendar.event-modal.paid">Paid</Trans>
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    {editable ?
                                                        <input
                                                            id="paid"
                                                            type="checkbox"
                                                            checked={isPaid}
                                                            onChange={(e) => setIsPaid(e.target.checked)}
                                                            className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                        />
                                                        :
                                                        <input
                                                        id="paid"
                                                        type="checkbox"
                                                        checked={isPaid}
                                                        className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                                        disabled
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        {eventTypeId === "INTERNAL" &&
                                            <div
                                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200">
                                                <label htmlFor="employees"
                                                       className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                                    <Trans
                                                        i18nKey="team-calendar.event-modal.employees">employees</Trans>
                                                </label>

                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    {editable ? (
                                                        <div>
                                                            <div className="mt-2">
                                                                {employeeIds.map((employeeId, index) => (
                                                                    <div key={"employee_" + employeeId}
                                                                         className="flex items-center justify-between py-1">
                                                                        {renderEmployeeById(employeeId)}
                                                                        <button
                                                                            className="text-red-500 hover:text-red-700"
                                                                            onClick={() => {
                                                                                setEmployeeIds(employeeIds.filter(id => id !== employeeId));
                                                                            }}
                                                                        >
                                                                            <svg className="h-4 w-4" fill="currentColor"
                                                                                 viewBox="0 0 20 20">
                                                                                <path
                                                                                    fillRule="evenodd"
                                                                                    d="M14.293 5.293a1 1 0 0 0-1.414 0L10 8.586 6.707 5.293a1 1 0 0 0-1.414 1.414L8.586 10l-3.293 3.293a1 1 0 1 0 1.414 1.414L10 11.414l3.293 3.293a1 1 0 0 0 1.414-1.414L11.414 10l3.293-3.293a1 1 0 0 0 0-1.414z"
                                                                                />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <Autocomplete
                                                                options={employees.data.filter(emp => !employeeIds.some(employeeId => emp.id === employeeId))}
                                                                error={listOfEmployeesError}
                                                                renderOption={renderEmployee}
                                                                renderValue={renderEmployee}
                                                                resetAfterSelection={true}
                                                                onSelect={(employee) => {
                                                                    setEmployeeIds([...employeeIds, employee.id]);
                                                                }}
                                                                filterFunction={filterEmployees}
                                                                placeholder={t('team-calendar.event-modal.add-employee')}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="text-sm text-gray-700 sm:mt-px sm:pt-2">
                                                            {
                                                                employeeIds.map((employeeId, index) => {
                                                                    return <div key={"employee_" + employeeId}>{renderEmployeeById(employeeId)}</div>
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                            <label htmlFor="start-time"
                                                   className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                                <Trans
                                                    i18nKey="team-calendar.event-modal.start.label">Start</Trans>
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                {editable ?
                                                    <input
                                                        type={eventTypeId === "CLIENT_APPOINTMENT" || eventTypeId === 'INTERNAL' ? "datetime-local" : "date"}
                                                        id="start-time"
                                                        name="start-time"
                                                        value={start}
                                                        onChange={onChangeStart}
                                                        onBlur={onChangedStart}
                                                        className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none  sm:text-sm ${startError ? "border-red-500" : "border-gray-300"}`}
                                                    />
                                                    :
                                                    eventTypeId === 'CLIENT_APPOINTMENT' || eventTypeId === 'INTERNAL' ?
                                                        <div
                                                            className="text-sm text-gray-700 sm:mt-px sm:pt-2">{start ? t('team-calendar.event-modal.end.date-time', {date: moment(start).toDate()}) : '-'}</div>
                                                        :
                                                        <div
                                                            className="text-sm text-gray-700 sm:mt-px sm:pt-2">{start ? t('team-calendar.event-modal.end.date', {date: moment(start).toDate()}) : '-'}</div>
                                                }
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                            <label htmlFor="end-time"
                                                   className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                                <Trans
                                                    i18nKey="team-calendar.event-modal.end.label">End</Trans>
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                {editable ?
                                                    <input
                                                        type={eventTypeId === "CLIENT_APPOINTMENT" || eventTypeId === 'INTERNAL' ? "datetime-local" : "date"}
                                                        id="end-time"
                                                        name="end-time"
                                                        value={end}
                                                        onChange={onChangeEnd}
                                                        onBlur={onChangedEnd}
                                                        className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none  sm:text-sm ${endError ? "border-red-500" : "border-gray-300"}`}
                                                    />
                                                    :
                                                    eventTypeId === 'CLIENT_APPOINTMENT' || eventTypeId === 'INTERNAL' ?
                                                        <div
                                                            className="text-sm text-gray-700 sm:mt-px sm:pt-2">{end ? t('team-calendar.event-modal.end.date-time', {date: moment(end).toDate()}) : '-'}</div>
                                                        :
                                                        <div
                                                            className="text-sm text-gray-700 sm:mt-px sm:pt-2">{end ? t('team-calendar.event-modal.end.date', {date: moment(end).toDate()}) : '-'}</div>
                                                }
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                            <label htmlFor="note"
                                                   className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                                <Trans i18nKey="team-calendar.event-modal.note">Note</Trans>
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                {editable ?
                                                    <textarea
                                                        id="note"
                                                        name="note"
                                                        rows={3}
                                                        value={notes ?? ''}
                                                        onChange={(e) => setNotes(e.target.value)}
                                                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white shadow-sm focus:outline-none  sm:text-sm"
                                                        placeholder={t('team-calendar.event-modal.enter-note')}
                                                    />
                                                    :
                                                    <div
                                                        className="text-sm text-gray-700 sm:mt-px sm:pt-2">{notes ?? '-'}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/*</form>*/}
                                </div>
                                {errorMessage &&
                                    <Transition
                                        show={true}
                                        enter="transition-opacity duration-800"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="transition-opacity duration-800"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div
                                            className="flex justify-between text-sm mt-4 mb-4 bg-red-100 border border-red-200 text-gray-700 px-4 py-3 relative">
                                            {errorMessage}
                                        </div>
                                    </Transition>
                                }
                                {!errorMessage && (
                                    <Transition
                                        show={true}
                                        enter="transition-opacity duration-800"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="transition-opacity duration-800"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="h-0"></div>
                                    </Transition>
                                )}
                                <div className="mt-4 flex justify-between">
                                    {editable ?
                                        <>
                                            <button
                                                type="button"
                                                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => onClose(null)}

                                            >
                                                <Trans i18nKey="team-calendar.event-modal.cancel">Cancel</Trans>
                                            </button>
                                            <button
                                                type="submit"
                                                className="inline-flex items-center border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-3 focus:ring-blue-400"
                                                onClick={handleSave}
                                            >
                                                <Trans i18nKey="team-calendar.event-modal.save">Save</Trans>
                                            </button>
                                        </>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Dialog>
            </Transition>
            <ConfirmationModal isOpen={showConfirmationModal} onClose={onCloseConfirmationModal} onConfirm={onConfirm}
                               data={confirmationModalData} message={confirmationModalMessage}/>
        </>
    );
};
export default EventModal;
