import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Trans} from "react-i18next";

const FormWarningDialog = ({show, text, onCancel, onConfirm}) => {

    return (
        <div>
            <Dialog
                open={show}
                onClose={onCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="form-warning-dialog.title">
                    <Trans i18nKey="form-warning-dialog.title">Confirmation alert</Trans>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="form-warning-dialog.text">
                        {text ?? <Trans i18nKey="form-warning-dialog.text">You have unsaved changes. Do you want to discard your changes?</Trans>}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => onCancel()}>
                        <Trans i18nKey="form-warning-dialog.cancel">Cancel</Trans>
                    </Button>
                    <Button variant="contained" onClick={onConfirm} autoFocus>
                        <Trans i18nKey="form-warning-dialog.confirm">Confirm</Trans>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default FormWarningDialog
