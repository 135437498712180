import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    CircularProgress,
    Grid,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import useApi from "../../hooks/useApi";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import moment from "moment";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {DateTime} from "luxon";
import useRouting from "../../hooks/useRouting";
import "./Employees.css";
import useAccess from "../access/useAccess";
import AccessControl from "../access/AccessControl";
import FormLeavingGuard from "../forms/FormLeavingGuard";
import FormAutocomplete from "../forms/FormAutocomplete";

const Employee = () => {

    const {t} = useTranslation();
    const {id} = useParams();
    const {isAllowed} = useAccess();

    const {queryEmployee, queryTeams, queryRoles, saveOrUpdateEmployee} = useApi();
    const {goToEmployees, goToEmployee} = useRouting();
    const {data: employee = null, isLoading, refetch} = queryEmployee(id);
    const {data: teams = null} = queryTeams();
    const {data: allRoles = null} = queryRoles();
    const teamsArray = Array.isArray(teams?.data) ? teams.data : [];


    const [editable, setEditable] = useState(id === 'new');
    const [disabled, setDisabled] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [showLeavingGuard, setShowLeavingGuard] = useState(false);
    const [companyEmail, setCompanyEmail] = useState('');
    const [privateEmail, setPrivateEmail] = useState('');
    const [teamName, setTeamName] = useState(null);
    const [teamId, setTeamId] = useState(null);
    const [roles, setRoles] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [employeeNumber, setEmployeeNumber] = useState('');
    const [employeeColor, setEmployeeColor] = useState('#000000');
    const [employeeTraining, setEmployeeTraining] = useState('');
    const [contractStartDate, setContractStartDate] = useState(null);
    const [birthDate, setBirthDate] = useState(null);
    const [gender, setGender] = useState('x');
    const [contractEndDate, setContractEndDate] = useState(null);
    const [errCompanyEmail, setErrCompanyEmail] = useState(false);
    const [errPrivateEmail, setErrPrivateEmail] = useState(false);
    const [errEmployeeNumber, setErrEmployeeNumber] = useState(false);
    const [errEmployeeTraining, setErrEmployeeTraining] = useState(false);
    const [errContractStartDate, setErrContractStartDate] = useState(false);
    const [errBirthDate, setErrBirthDate] = useState(false);
    const [errTeamName, setErrTeamName] = useState(false);
    const [errGender, setErrGender] = useState(false);
    const [errContractEndDate, setErrContractEndDate] = useState(false);
    const [street, setStreet] = useState('');
    const [houseNumber, setHouseNumber] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [errStreet, setErrStreet] = useState(false);
    const [errHouseNumber, setErrHouseNumber] = useState(false);
    const [errZip, setErrZip] = useState(false);
    const [errCity, setErrCity] = useState(false);
    const navigate = useNavigate();
    const [selectedQualification, setSelectedQualification] = useState('specialist');
    const [hoursPerWeek, setHoursPerWeek] = useState(0);
    const [errHoursPerWeek, setErrHoursPerWeek] = useState(false);
    const [errFirstName, setErrFirstName] = useState(false);
    const [errLastName, setErrLastName] = useState(false);
    const [errBusinessPhoneNumber, setErrBusinessPhoneNumber] = useState(false);
    const [errPrivatePhoneNumber, setErrPrivatePhoneNumber] = useState(false);
    const [errPrivateCountry, setErrPrivateCountry] = useState(false);
    const [countryCode, setCountryCode] = useState('DE');
    const [privateCountryCode, setPrivateCountryCode] = useState('DE');
    const [businessPhone, setBusinessPhone] = useState('');
    const [privatePhone, setPrivatePhone] = useState('');
    const [countryCodes, setCountryCodes] = useState([]);

    const employeeQualifications = {
        SPECIALIST: 'specialist',
        NON_SPECIALIST: 'non_specialist',
        OTHER: 'other',
    };


    const labelSx = {
        padding: 2,
        fontSize: 14,
        fontWeight: 600,
        color: '#6B7280',
        '@media (max-width:600px)': {
            paddingBottom: 0
        }
    }

    const labelMultilineSx = {
        paddingTop: editable ? 1 : 2,
        paddingBottom: editable ? 1 : 2,
        paddingLeft: 2,
        paddingRight: 2,
        fontSize: 14,
        fontWeight: 600,
        color: '#6B7280',
        '@media (max-width:600px)': {
            paddingBottom: 0
        }
    }

    const valueSx = {
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: editable ? 1 : 2,
        paddingBottom: editable ? 1 : 2,
        fontSize: 14,
    }

    const init = () => {
        setCompanyEmail(employee.user.email);
        setEmployeeNumber(employee.employee_number);
        if (employee.phone && employee.phone.phone_number) {
            setBusinessPhone(employee.phone.phone_number);
        } else {
            setBusinessPhone("");

        }
        setContractStartDate(employee.employment_start);
        setContractEndDate(employee.employment_end);
        if (employee.employment_start) {
            setContractStartDate(DateTime.fromFormat(employee.employment_start, 'yyyy-MM-dd'));
        } else {
            setContractStartDate(null);
        }
        if (employee.employment_end) {
            setContractEndDate(DateTime.fromFormat(employee.employment_end, 'yyyy-MM-dd'));
        } else {
            setContractEndDate(null);
        }
        if (employee.teams.length >0) {
            setTeamName(employee.teams[0].name);
        } else {
            setTeamName(null);
        }

        setRoles(employee.roles);

        setSelectedQualification(employee.qualification);
        setHoursPerWeek(employee.hours_per_week);
        setEmployeeColor(employee.employee_color);
        setFirstName(employee.person.first_name);
        setLastName(employee.person.last_name);
        if (employee.person.birthdate) {
            setBirthDate(DateTime.fromFormat(employee.person.birthdate, 'yyyy-MM-dd'));
        } else {
            setBirthDate(null);
        }
        if (employee.personal_phone && employee.personal_phone.phone_number) {
            setPrivatePhone(employee.personal_phone.phone_number);

        } else {
            setPrivatePhone('');
        }
        setGender(employee.person.gender);
        setPrivateCountryCode(employee.personal_phone ? employee.personal_phone.country_code : 'DE');
        setCountryCode(employee.phone? employee.phone.country_code : 'DE');
        setPrivateEmail(employee.person.email);
        setStreet(employee.address.street);
        setHouseNumber(employee.address.street_number);
        setZip(employee.address.zip);
        setCity(employee.address.city);
        setEmployeeTraining(employee.education ?? '');

    }
   // console.log(employee)

    useEffect(() => {
        if (employee && employee.id) {
            init();
        }
    }, [employee]);


    const handleCompanyEmailChange = (event) => {
        setIsChanged(true);
        setCompanyEmail(event.target.value);
        setErrCompanyEmail(false);
    }

    const handlePrivateEmailChange = (event) => {
        setIsChanged(true);
        setPrivateEmail(event.target.value);
        setErrPrivateEmail(false);
    }

    const handleFirstNameChange = (event) => {
        setIsChanged(true);
        setFirstName(event.target.value);
        setErrFirstName(false);
    }

    const handleLastNameChange = (event) => {
        setIsChanged(true);
        setLastName(event.target.value);
        setErrLastName(false);
    }
    const handleEmployeeNumberChange = (event) => {
        setIsChanged(true);
        setEmployeeNumber(event.target.value);
        setErrEmployeeNumber(false);
    }

    const handleEmployeeTrainingChange = (event) => {
        setIsChanged(true);
        setEmployeeTraining(event.target.value);
        setErrEmployeeTraining(false);
    }
    const handleHoursPerWeekChange = (event) => {
        setIsChanged(true);
        setHoursPerWeek(event.target.value);
        setErrHoursPerWeek(false);
    }
    const handleEmployeeColorChange = (event) => {
        setIsChanged(true);
        setEmployeeColor(event.target.value);
    };

    const handleCountryCodeChange = (event) => {
        setIsChanged(true);
        setCountryCode(event.target.value);
    };
    const handleBusinnesPhoneChange = (event) => {
        setIsChanged(true);
        setBusinessPhone(event.target.value);
        setErrBusinessPhoneNumber(false);
    };

    const handlePrivatePhoneChange = (event) => {
        setIsChanged(true);
        setPrivatePhone(event.target.value);
        setErrPrivatePhoneNumber(false);
    };

    const handlePrivateCountryCodeChange = (event) => {
        setIsChanged(true);
        setPrivateCountryCode(event.target.value);
        setErrPrivateCountry(false);
    };

    const handleStreetChange = (event) => {
        setIsChanged(true);
        setStreet(event.target.value);
        setErrStreet(false);
    };

    const handleCityChange = (event) => {
        setIsChanged(true);
        setCity(event.target.value);
        setErrCity(false);
    };

    const handleZipChange = (event) => {
        setIsChanged(true);
        setZip(event.target.value);
        setErrZip(false);
    };

    const handleHouseNumberChange = (event) => {
        setIsChanged(true);
        setHouseNumber(event.target.value);
        setErrHouseNumber(false);
    };

    const handleCancel = () => {
        if (isChanged) {
            setShowLeavingGuard(true);
        } else {
            setEditable(false);
            if (employee && employee.id) {
                init();
            } else {
                goToEmployees();
                setCompanyEmail('');
            }
        }
    };


    const validate = () => {
        let valid = true;

        if (companyEmail === "" ) {
            setErrCompanyEmail(true);
            valid = false;
        } else {
            setErrCompanyEmail(false);
        }

        if (employeeNumber === "") {
            setErrEmployeeNumber(true);
            valid = false;
        } else {
            setErrEmployeeNumber(false);
        }

        if (contractStartDate === null) {
            setErrContractStartDate(true);
            valid = false;
        } else {
            setErrContractStartDate(false);
        }

        if (employeeTraining === '') {
            setErrEmployeeTraining(true);
            valid = false;
        } else {
            setErrEmployeeTraining(false);
        }

        if (firstName === '') {
            setErrFirstName(true);
            valid = false;
        } else {
            setErrFirstName(false);
        }

        if (lastName === '') {
            setErrLastName(true);
            valid = false;
        } else {
            setErrLastName(false);
        }

        if (birthDate === null) {
            setErrBirthDate(true);
            valid = false;
        } else {
            setErrBirthDate(false);
        }
        if (gender === '') {
            setErrGender(true);
            valid = false;
        } else {
            setErrGender(false);
        }

        if (privateEmail === '') {
            setErrPrivateEmail(true);
            valid = false;
        } else {
            setErrPrivateEmail(false);
        }


        if (houseNumber === '') {
            setErrHouseNumber(true);
            valid = false;
        } else {
            setErrHouseNumber(false);
        }
        if (street === '') {
            setErrStreet(true);
            valid = false;
        } else {
            setErrStreet(false);
        }
        if (zip === '') {
            setErrZip(true);
            valid = false;
        } else {
            setErrZip(false);
        }
        if (city === '') {
            setErrCity(true);
            valid = false;
        } else {
            setErrCity(false);
        }

        const phoneRegex = /^(\+?[0-9\s\-\(\)]){0,14}[0-9]{1,15}$/


        if (!phoneRegex.test(businessPhone) || businessPhone === '') {
            setErrBusinessPhoneNumber(true);
            valid = false;
        } else {
            setErrBusinessPhoneNumber(false);
        }
      if (privatePhone === '') {
            setErrPrivatePhoneNumber(true);
            valid = false;
        } else {
            setErrPrivatePhoneNumber(false);
        }
        return valid;

    }
    const handleSave = () => {
        setDisabled(true);

        if (validate()) {
            setIsChanged(false);
            let data = {};
            if (id) {
                data.id = id;
            }

            data.username = companyEmail;
            data.employee_number = employeeNumber;
            data.employee_color = employeeColor;
            data.phone = businessPhone;
            data.phone_country = countryCode;
            data.qualification = selectedQualification;
            data.education = employeeTraining;
            data.team = teamId;
            data.hours_per_week = hoursPerWeek;
            data.first_name = firstName;
            data.last_name = lastName;
            data.gender = gender;
            data.email = privateEmail;
            data.private_phone_country = privateCountryCode;
            data.private_phone = privatePhone;
            data.street = street;
            data.zip = zip;
            data.city = city;
            data.street_number = houseNumber;
            data.roles = roles;

            if (contractStartDate) {
                data.employment_start = contractStartDate.toFormat('yyyy-MM-dd');
            }
            if (contractEndDate) {
                data.employment_end = contractEndDate.toFormat('yyyy-MM-dd');
            }
            if (birthDate) {
                data.birthdate = birthDate.toFormat('yyyy-MM-dd');
            }

            saveOrUpdateEmployee(data).then((response) => {
                //console.log(response);
                setDisabled(false);
                setEditable(false);
                if (data.id === 'new' && response.data.id) {
                    goToEmployee(response.data.id);
                } else {
                    refetch();
                }
            }).catch((error) => {
                setDisabled(false);
                if (error.response?.status === 422 || error.response?.status === 500) {
                    let errors = error.response.data.errors;
                    if (errors.username) {
                        setErrCompanyEmail(true);
                    }
                    if (errors.phone) {
                        setErrBusinessPhoneNumber(true);
                    }
                    if (errors.private_phone) {
                        setErrPrivatePhoneNumber(true);
                    }
                    if (errors.employee_number) {
                        setErrEmployeeNumber(true);
                    }
                    if (errors.employment_start) {
                        setErrContarctStartDate(true);
                    }

                }

            });
        } else {
            setDisabled(false);
        }
    };

    const handleCancelLeavingGuard = () => {
        setShowLeavingGuard(false);
    }

    const handleConfirmLeavingGuard = () => {
        setShowLeavingGuard(false);
        setIsChanged(false);
        if (employee && employee.id) {
            setEditable(false);
            init();
        } else {
            goToEmployees();
        }
    }

    return (
        <AccessControl permissions={["employees.create", "employees"]} or={id !== 'new'}>
        <Box sx={{padding: 2}}>
            {isLoading ?
                <Stack sx={{flexGrow: 1, padding: 2}} alignItems="center">
                    <CircularProgress/>
                </Stack>
                :
                <>
                    <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: 2}}>
                        <NavLink underline="hover" color="inherit" to="/employees">
                            <Trans i18nKey="employees.title">Employees</Trans>
                        </NavLink>
                        { employee?.id ?
                            <Typography
                            color="text.primary">{employee.person && employee.person.last_name + " " + employee.person.first_name}</Typography>
                            :
                            <Typography color="text.primary"><Trans i18nKey="employees.new-employee">New employee</Trans></Typography>
                        }
                    </Breadcrumbs>
                    <Paper elevation={0} sx={{marginBottom: 2, padding: 2, maxWidth: 1024}}>
                        <Grid container alignItems="center">
                            <Grid item xs={8}>
                                <Box sx={{paddingLeft: 2, display: 'flex', justifyContent: 'flex-start'}}>
                                    {editable && employee?.id &&
                                        <Typography variant="h5"><Trans i18nKey={"employee.edit-title"}>Edit
                                            employee</Trans></Typography>
                                    }
                                    {editable && !employee?.id &&
                                        <Typography variant="h5"><Trans
                                            i18nKey={"employee.create-new-employee"}>Create new employee
                                        </Trans></Typography>
                                    }
                                    {!editable &&
                                        <Typography
                                            variant="h5">{employee.person?.last_name + " " + employee.person?.first_name}</Typography>
                                    }

                                </Box>
                            </Grid>
                            <Grid item xs={4} justifyContent="flex-end">
                                <AccessControl permissions={["employees.edit", "employees"]}>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {!editable &&
                                        <Button
                                            sx={{textTransform: 'none'}}
                                            variant="outlined"
                                            size="small"
                                            disableElevation
                                            onClick={() => {
                                                setEditable(!editable)
                                                //console.log(id, employee.id);
                                            }}
                                        >
                                            <Trans i18nKey="employee.edit">Edit</Trans>
                                            {/*<EditOutlinedIcon />*/}
                                        </Button>
                                    }
                                </Box>
                                </AccessControl>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={0} sx={{padding: 2, marginBottom: 2, maxWidth: 1024}}>
                        <Grid container alignItems="center" >
                            <Grid item xs={12} sx={{paddingLeft: 2}}>
                                <Typography variant="h6">Stammdaten</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="employees.company-address-email">Company email address (login)</Trans>
                            </Grid>

                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <TextField
                                        disabled={disabled}
                                        id="shortname"
                                        fullWidth
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={companyEmail}
                                        placeholder={t('employee.email.placeholder', 'Enter Email')}
                                        InputProps={{style: {fontSize: 14, backgroundColor: '#fff'}}}
                                        onChange={handleCompanyEmailChange}
                                        error={errCompanyEmail}
                                    />
                                    :
                                    employee?.email ? employee.email : '-'
                                }
                            </Grid>

                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="employee.employee-number.label">Employee number</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <TextField
                                        disabled={disabled}
                                        id="employee-number"
                                        fullWidth
                                        /*multiline*/
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={employeeNumber}
                                        placeholder={t('employee.employee-number.placeholder', 'Enter Employee number')}
                                        InputProps={{style: {fontSize: 14}}}
                                        sx={{fontSize: 14, backgroundColor: '#fff'}}
                                        onChange={handleEmployeeNumberChange}
                                        error={errEmployeeNumber}
                                    />
                                    :
                                    employee?.employee_number
                                }
                            </Grid>

                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="employee.bussiness-phone.label">Business phone number</Trans>
                            </Grid>
                            <Grid item xs={12} md={6} sx={valueSx}>
                                {editable ? (
                                    <Select
                                        value={countryCode}
                                        size="small"
                                        onChange={handleCountryCodeChange}
                                        style={{
                                            marginRight: '3px',
                                            width: '18%',
                                            height: '38px',
                                            fontSize: 14,
                                            backgroundColor: '#fff',
                                        }}
                                    >
                                        {['DE', 'AT', 'CH'].map((code) => (
                                            <MenuItem key={code} value={code}>
                                                {code}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                ) : (
                                    ''
                                )}
                                {editable ? (
                                    <TextField
                                        value={businessPhone}
                                        hiddenLabel
                                        size="small"
                                        onChange={handleBusinnesPhoneChange}
                                        InputProps={{style: {fontSize: 14}}}
                                        placeholder={t('employee.bussiness-phone.placeholder', 'Enter Phone Number')}
                                        sx={{fontSize: 14, backgroundColor: '#fff', width: '60%'}}
                                        error={errBusinessPhoneNumber}
                                        helperText={errBusinessPhoneNumber ? t('employee.bussiness-phone.error', 'Please enter a valid phone number.') : ''}
                                    />
                                ) : (
                                    employee.phone?.phone_number ? employee.phone.phone_number : '-'

                                )}
                            </Grid>

                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="employee.contract-start.label">Start of contract</Trans>
                            </Grid>

                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <DesktopDatePicker
                                        disabled={disabled}
                                        disableToolbar
                                        inputVariant="filled"
                                        format="dd.MM.yyyy"
                                        id="valid-from"
                                        value={contractStartDate}
                                        placeholder={t('employee.contract-start.placeholder', 'Enter Contract start Date')}
                                        sx={{
                                            backgroundColor: '#fff', '& input': {
                                                fontSize: 14,
                                            },
                                            '& input::placeholder': {
                                                fontSize: 14
                                            }
                                        }}
                                        onChange={(date) => {
                                            setContractStartDate(date)
                                        }}
                                        slotProps={{textField: {size: 'small', error: errContractStartDate}}}
                                        textField={(params) => <TextField variant="filled" {...params}
                                                                          inputProps={
                                                                              {
                                                                                  ...params.inputProps,
                                                                                  placeholder: "dd.MM.yyyy"
                                                                              }
                                                                          } placeholder="dd.MM.yyyy"/>}
                                    />
                                    :
                                    employee?.employment_start ? t('employee.contract-start.date', {date: moment(employee?.employment_start).toDate()}) : '-'
                                }
                            </Grid>

                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="employee.contract-end.label">End of Contract</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <DesktopDatePicker
                                        disabled={disabled}
                                        disableToolbar
                                        inputVariant="filled"
                                        format="dd.MM.yyyy"
                                        id="valid-until"
                                        value={contractEndDate}
                                        placeholder={t('employee.contract-end.placeholder', 'Enter Contract end Date')}
                                        sx={{
                                            backgroundColor: '#fff', '& input': {
                                                fontSize: 14,
                                            },
                                            '& input::placeholder': {
                                                fontSize: 14
                                            }
                                        }}
                                        onChange={(date) => {
                                            setContractEndDate(date)
                                        }}
                                        slotProps={{textField: {size: 'small', error: errContractEndDate}}}
                                        textField={(params) => <TextField variant="filled" {...params}
                                                                          inputProps={
                                            {
                                                ...params.inputProps,
                                                placeholder: "dd.MM.yyyy"
                                            }
                                        } placeholder="dd.MM.yyyy"/>}
                                    />
                                    :
                                    employee?.employment_end ? t('employee.contract-end.date', {date: moment(employee?.employment_end).toDate()}) : '-'
                                }
                            </Grid>
                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey={"employee.team-assignment.label"}>Team assignment (as employee)</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <Autocomplete
                                        disabled={disabled}
                                        size="small"
                                        value={teamName}
                                        onChange={(event, newValue) => {
                                            const selectedTeam = teamsArray.find(team => team.name === newValue);
                                            const selectedTeamId = selectedTeam ? selectedTeam.id : null;

                                            setTeamName(newValue);
                                            setTeamId(selectedTeamId);
                                        }}
                                        options={teamsArray.map(team => team.name)}
                                        sx={{
                                            backgroundColor: '#fff',
                                            fontSize: 14,
                                            '& input': {
                                                fontSize: 14,
                                            },
                                            '.MuiAutocomplete-listbox': {
                                                fontSize: '14px !important',
                                            }
                                        }}
                                        ListboxProps={{
                                            sx: {fontSize: 14},
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={errTeamName}
                                                placeholder={t('employee.team-assignment.placeholder', 'Select Team')}
                                            />
                                        )}
                                        />
                                    :
                                    employee.teams.length >0 ?  employee.teams[0].name : ""
                                }
                            </Grid>

                            <FormAutocomplete
                                multiple={true}
                                value={roles}
                                editable={editable}
                                onChange={(newValue) => {
                                    setRoles(newValue);
                                }}
                                i18nKeyLabel="employee.roles.label"
                                i18nKeyPlaceholder="employee.roles.placeholder"
                                options={allRoles}
                                getOptionLabel={(option) => t('employee.roles.' + option.name, option.name)}
                                valueText={roles.length > 0 ? roles.map((role) => t('employee.roles.' + role.name, role.name)).join(', ') : '-'}
                                disabled={disabled}
                                />


                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey={"employee.qualification.label"}>Qualification</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <Select
                                        labelId="qualificationLabel"
                                        id="qualificationSelect"
                                        value={selectedQualification}
                                        onChange={(event) => setSelectedQualification(event.target.value)}
                                        size="small"
                                        style={{
                                            backgroundColor: '#fff',
                                            fontSize: 14,
                                            '& input': {
                                                fontSize: 14,
                                            },
                                            '.MuiAutocompleteListbox': {
                                                fontSize: '14px !important',
                                            },
                                            width: 215
                                        }}
                                        listboxprops={{
                                            sx: {fontSize: 14},
                                        }}
                                    >{Object.values(employeeQualifications).map((qualification) => (
                                        <MenuItem key={qualification} value={qualification}>
                                            {t(`employee.qualification.${qualification}`, qualification)}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                    :

                                    t(`employee.qualification.${employee?.qualification}`, employee?.qualification)
                                }
                            </Grid>

                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="employee.training.label">Training</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <TextField
                                        disabled={disabled}
                                        id="employee-number"
                                        fullWidth
                                        //multiline
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={employeeTraining}
                                        placeholder={t('employee.training.placeholder', 'Enter Training')}
                                        InputProps={{style: {fontSize: 14}}}
                                        sx={{fontSize: 14, backgroundColor: '#fff'}}
                                        onChange={handleEmployeeTrainingChange}
                                        error={errEmployeeTraining}
                                    />
                                    :
                                    employee?.education ? employee?.education : "-"
                                }
                            </Grid>
                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="employee.hours-per-week.label">hours per week</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <TextField
                                        type="number"
                                        value={hoursPerWeek}
                                        hiddenLabel
                                        size="small"
                                        onChange={handleHoursPerWeekChange}
                                        InputProps={{style: {fontSize: 14}}}
                                        sx={{fontSize: 14, backgroundColor: '#fff', width: 210}}
                                        error={errHoursPerWeek}
                                    />
                                    :
                                    employee?.hours_per_week ? employee?.hours_per_week : 0
                                }
                            </Grid>

                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="employee.employee-color.label">Employee color</Trans>
                            </Grid>
                            <Grid item xs={12} md={6} sx={valueSx}>
                                {editable ? (
                                    <input
                                        type="color"
                                        style={{width: 220}}
                                        value={employeeColor}
                                        onChange={handleEmployeeColorChange}
                                    />
                                ) : (
                                    <Paper
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '90px',
                                            height: '25px',
                                            backgroundColor: employee?.employee_color,
                                        }}
                                    >{employee?.employee_color}</Paper>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper elevation={0} sx={{padding: 2, maxWidth: 1024}}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sx={{paddingLeft: 2}}>
                                <Typography variant="h6">Persönliche Informationen</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="employee.first-name.label">First Name</Trans>
                            </Grid>

                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <TextField
                                        disabled={disabled}
                                        id="firstName"
                                        fullWidth
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={firstName}
                                        placeholder={t('employee.first-name.placeholder', 'Enter First Name')}
                                        InputProps={{style: {fontSize: 14, backgroundColor: '#fff'}}}
                                        onChange={handleFirstNameChange}
                                        error={errFirstName}
                                    />
                                    :
                                    employee.person?.first_name ? employee?.person.first_name : '-'
                                }
                            </Grid>
                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="employee.last-name.label">Last Name</Trans>
                            </Grid>

                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <TextField
                                        disabled={disabled}
                                        id="lastName"
                                        fullWidth
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={lastName}
                                        placeholder={t('employee.last-name.placeholder', 'Enter Last Name')}
                                        InputProps={{style: {fontSize: 14, backgroundColor: '#fff'}}}
                                        onChange={handleLastNameChange}
                                        error={errLastName}
                                    />
                                    :
                                    employee.person?.last_name ? employee.person.last_name : '-'
                                }
                            </Grid>
                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="employee.birthday.label">Birthday</Trans>
                            </Grid>

                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <DesktopDatePicker
                                        disabled={disabled}
                                        disableToolbar
                                        inputVariant="filled"
                                        format="dd.MM.yyyy"
                                        id="valid-from"
                                        value={birthDate}
                                        placeholder={t('employee.birthday.placeholder', 'Enter Birthday')}
                                        sx={{
                                            backgroundColor: '#fff', '& input': {
                                                fontSize: 14,
                                            },
                                            '& input::placeholder': {
                                                fontSize: 14
                                            }
                                        }}
                                        onChange={(date) => {
                                            setBirthDate(date)
                                        }}
                                        slotProps={{textField: {size: 'small', error: errBirthDate}}}
                                        textField={(params) => <TextField variant="filled" {...params}
                                                                          inputProps={
                                                                              {
                                                                                  ...params.inputProps,
                                                                                  placeholder: "dd.MM.yyyy"
                                                                              }
                                                                          } placeholder="dd.MM.yyyy"/>}
                                    />
                                    :
                                    employee?.person.birthdate ? t('employee.contract-start.date', {date: moment(employee?.person.birthdate).toDate()}) : '-'
                                }
                            </Grid>
                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey={"employee.gender.label"}>Gender</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <Select
                                        labelId="gender"
                                        id="gender"
                                        value={gender}
                                        onChange={(event) => setGender(event.target.value)}
                                        size="small"
                                        error={errGender}
                                        style={{
                                            backgroundColor: '#fff',
                                            fontSize: 14,
                                            '& input': {
                                                fontSize: 14,
                                            },
                                            '.MuiAutocompleteListbox': {
                                                fontSize: '14px !important',
                                            },
                                            width: 214
                                        }}
                                        listboxprops={{
                                            sx: {fontSize: 14},
                                        }}
                                    >
                                        {['m', 'f', 'x'].map((gender) => (
                                            <MenuItem key={gender} value={gender}>
                                                {t(`employee.gender.${gender}`, gender)}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    :

                                    employee.person?.gender ? t(`employee.gender.${employee.person?.gender}`, employee.person.gender) : "x"
                                }
                            </Grid>

                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="employees.private-email.label">Private email address</Trans>
                            </Grid>

                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <TextField
                                        disabled={disabled}
                                        id="email"
                                        fullWidth
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={privateEmail}
                                        placeholder={t('employee.email.placeholder', 'Enter Email')}
                                        InputProps={{style: {fontSize: 14, backgroundColor: '#fff'}}}
                                        onChange={handlePrivateEmailChange}
                                        error={errPrivateEmail}
                                    />
                                    :
                                    employee.person?.email ? employee.person.email : '-'
                                }
                            </Grid>

                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="employee.private-phone.label">Private phone number</Trans>
                            </Grid>
                            <Grid item xs={12} md={6} sx={valueSx}>
                                {editable ? (
                                    <Select
                                        value={privateCountryCode}
                                        size="small"
                                        onChange={handlePrivateCountryCodeChange}
                                        style={{
                                            marginRight: '3px',
                                            width: '18%',
                                            height: '38px',
                                            fontSize: 14,
                                            backgroundColor: '#fff',
                                        }}
                                    >
                                        {['DE', 'AT', 'CH'].map((code) => (
                                            <MenuItem key={code} value={code}>
                                                {code}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                ) : (
                                    ''
                                )}
                                {editable ? (
                                    <TextField
                                        value={privatePhone}
                                        hiddenLabel
                                        size="small"
                                        onChange={handlePrivatePhoneChange}
                                        InputProps={{style: {fontSize: 14}}}
                                        placeholder={t('employee.private-phone.placeholder', 'Enter Phone Number')}
                                        sx={{fontSize: 14, backgroundColor: '#fff', width: '60%'}}
                                        error={errPrivatePhoneNumber}
                                        helperText={errPrivatePhoneNumber ? t('employee.bussiness-phone.error', 'Please enter a valid phone number.') : ''}
                                    />
                                ) : (
                                    employee.personal_phone ? employee.personal_phone.phone_number : '-'

                                )}
                            </Grid>

                            {editable ? (
                                    <>
                                        <Grid item xs={12} sm={4} sx={labelSx}>
                                            <Trans i18nKey="employee.street.label">Street</Trans>
                                        </Grid>

                                        <Grid item xs={12} sm={8} sx={valueSx}>
                                            <TextField
                                                disabled={disabled}
                                                id="street"
                                                fullWidth
                                                hiddenLabel
                                                size="small"
                                                variant="outlined"
                                                value={street}
                                                placeholder={t('employee.street.placeholder', 'Enter Street Name')}
                                                InputProps={{style: {fontSize: 14, backgroundColor: '#fff'}}}
                                                onChange={handleStreetChange}
                                                error={errStreet}
                                            />

                                        </Grid>
                                        <Grid item xs={12} sm={4} sx={labelSx}>
                                            <Trans i18nKey="employee.street-number.label">House Number</Trans>
                                        </Grid>

                                        <Grid item xs={12} sm={8} sx={valueSx}>
                                            <TextField
                                                disabled={disabled}
                                                id="house"
                                                //fullWidth
                                                sx={{width:215}}
                                                hiddenLabel
                                                size="small"
                                                variant="outlined"
                                                value={houseNumber}
                                                placeholder={t('employee.street-number.placeholder', 'Enter House Number')}
                                                InputProps={{style: {fontSize: 14, backgroundColor: '#fff'}}}
                                                onChange={handleHouseNumberChange}
                                                error={errHouseNumber}
                                            />

                                        </Grid>

                                        <Grid item xs={12} sm={4} sx={labelSx}>
                                            <Trans i18nKey="employee.zip.label">Zip Code</Trans>
                                        </Grid>

                                        <Grid item xs={12} sm={8} sx={valueSx}>
                                            <TextField
                                                disabled={disabled}
                                                id="zip"
                                                //fullWidth
                                                hiddenLabel
                                                size="small"
                                                variant="outlined"
                                                value={zip}
                                                sx={{width:215}}
                                                placeholder={t('employee.zip.placeholder', 'Enter Zip Code')}
                                                InputProps={{style: {fontSize: 14, backgroundColor: '#fff'}}}
                                                onChange={handleZipChange}
                                                error={errZip}
                                            />

                                        </Grid>

                                        <Grid item xs={12} sm={4} sx={labelSx}>
                                            <Trans i18nKey="employee.city.label">City</Trans>
                                        </Grid>

                                        <Grid item xs={12} sm={8} sx={valueSx}>
                                            <TextField
                                                disabled={disabled}
                                                id="city"
                                                fullWidth
                                                hiddenLabel
                                                size="small"
                                                variant="outlined"
                                                value={city}
                                                placeholder={t('employee.city.placeholder', 'Enter City Name')}
                                                InputProps={{style: {fontSize: 14, backgroundColor: '#fff'}}}
                                                onChange={handleCityChange}
                                                error={errCity}
                                            />
                                        </Grid></>)
                                : (
                                    <>
                                    <Grid item xs={12} sm={4} sx={labelSx}>
                                        <Trans i18nKey="employees.address">Address</Trans>
                                    </Grid>

                                    <Grid item xs={12} sm={8} sx={valueSx}>
                                        {employee?.person.address ? employee.person.address.street + " " + employee.person.address.street_number + ", " + employee.person.address.zip+ " " + employee.person.address.city: ""}
                                    </Grid>
                                </>
                                )
                            }


                        </Grid>

                        {editable && (<>
                            <Grid container maxWidth="1024px">
                                <Grid item xs={12}>
                                    <Box sx={{padding: 2, display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                marginRight: 2,
                                                textTransform: 'none', background: '#fff'
                                            }}
                                            onClick={handleCancel}
                                        >
                                            <Trans i18nKey="institutions.cancel">Cancel</Trans>
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            disableElevation
                                            sx={{textTransform: 'none'}}
                                            onClick={handleSave}
                                        >
                                            <Trans i18nKey="institutions.save">Save</Trans>
                                        </Button>

                                    </Box>
                                </Grid>
                            </Grid>
                                <FormLeavingGuard when={isChanged} show={showLeavingGuard} onCancel={handleCancelLeavingGuard} onConfirm={handleConfirmLeavingGuard} />
                            </>
                        )}
                    </Paper>

                </>
            }
        </Box>
        </AccessControl>
    );
}

export default Employee;
