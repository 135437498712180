import React, {Fragment, useEffect, useRef, useState} from "react";
import { Menu, Transition } from "@headlessui/react";

import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import useApi from "../hooks/useApi";
import useConfig from "../hooks/useConfig";
import {Trans} from "react-i18next";

const TeamMemberDropdown = ({teamSelectionHandler, teamId}) => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef(null);
    const menuRef = useRef(null);
    const [selected, setSelected] = useState([]);
    const {queryTeamMembers, queryAllEmployees} = useApi();
    const {eventColors} = useConfig();
    let queryFunction = null;
    if (!teamId) {
        queryFunction = () => {
            return queryAllEmployees((data) => {
                let s = [];
                s.push(-1);
                for (const employee of data) {
                    s.push(employee.id);
                }
                setSelected(s);
            });
        }
    } else {
        queryFunction = () => {
            return queryTeamMembers(teamId, (data) => {
                let s = [];
                s.push(-1);
                for (const teamMember of data) {
                    s.push(teamMember.id);
                }
                setSelected(s);
            });
        }
    }
    const {data} = queryFunction();
    const teamMembers = data?.data ?? data ?? [];

    useEffect(() => {
        teamSelectionHandler(selected);
    }, [selected]);

    const handleClickOutside = (event) => {
        if (buttonRef.current && !buttonRef.current.contains(event.target) &&
            menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    };

    const onClickTeamMember = (teamMemberId) => {
        if (selected.includes(teamMemberId)) {
            setSelected(selected.filter((id) => id !== teamMemberId));
        } else {
            setSelected([...selected, teamMemberId]);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Menu as="div" className="relative inline-block text-left mr-2" style={{ zIndex: 8 }}>
            {({ open }) => (
                <>
                    <div ref={buttonRef}>
                        <Menu.Button
                            disabled={teamMembers.length === 0}
                            onClick={handleButtonClick}
                            className="inline-flex justify-center items-center w-full px-4 py-2 text-sm font-medium  text-gray-400 bg-white rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="md:hidden w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                            </svg>
                            <div className="hidden md:block text-gray-700"><Trans i18nKey="team-calendar.team-members">Team members</Trans></div>
                            <ChevronDownIcon className="ml-2 -mr-1 h-5 w-5" />
                        </Menu.Button>
                    </div>

                    <Transition
                        show={isOpen}
                        enter="transition ease-out duration-100 transform"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-75 transform"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Menu.Items
                            ref={menuRef}
                            static
                            className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                            <div className="py-1">
                                <Menu.Item key={'team-member-none'}>
                                    <a
                                        onClick={(event) => {
                                            event.preventDefault();
                                            onClickTeamMember(-1)}
                                        }
                                        href="#"
                                        className={"text-gray-700 flex items-center px-4 py-2 text-sm"}
                                    >
                                        { selected.includes(-1) ?
                                            <div className="rounded-sm w-4 h-4 mr-2" style={{backgroundColor: eventColors.notAssigned}}></div>
                                            :
                                            <div className="ring-1 ring-black ring-opacity-50 rounded-sm w-4 h-4 mr-2"></div>
                                        }
                                        <Trans i18nKey="team-calendar.not-assigned">Not assigned</Trans>
                                    </a>
                                </Menu.Item>
                                {teamMembers.map((teamMember) => (
                                <Menu.Item key={'team-member-'+teamMember.id}>
                                        <a
                                            onClick={(event) => {
                                                event.preventDefault();
                                                onClickTeamMember(teamMember.id)}
                                            }
                                            href="#"
                                            className={"text-gray-700 flex items-center px-4 py-2 text-sm"}
                                        >
                                            { selected.includes(teamMember.id) ?
                                                <div className="rounded-sm w-4 h-4 mr-2" style={{backgroundColor: teamMember.employee_color}}></div>
                                                :
                                                <div className="ring-1 ring-black ring-opacity-50 rounded-sm w-4 h-4 mr-2"></div>
                                            }
                                            {teamMember.person.first_name} {teamMember.person.last_name}
                                        </a>
                                </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
};

export default TeamMemberDropdown;
