import {Fragment, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import useApi from "../hooks/useApi";
import {Trans, useTranslation} from "react-i18next";
import Autocomplete from "./Autocomplete";
import moment from "moment";
import {useParams} from "react-router-dom";
import Spinner from "./Spinner";
import {CheckIcon, DocumentCheckIcon, PencilIcon} from "@heroicons/react/24/outline";
import ConfirmationModal from "./ConfirmationModal";
import {PlusIcon, XMarkIcon} from "@heroicons/react/24/solid";

const EventsExportModal = ({isOpen, onClose}) => {
    const {t} = useTranslation();

    const {
        queryEventTypes,
        downloadEvents,
    } = useApi();
    const {data: eventTypes = [], isLoading: isLoadingEventTypes = true} = queryEventTypes();


    // const userIdMeta = document.querySelector('meta[name="user_id"]');
    // const userId = userIdMeta ? userIdMeta.content : null;
    const [disabled, setDisabled] = useState(false);
    const [eventTypeId, setEventTypeId] = useState("ALL");
    const [eventTypeIdError, setEventTypeIdError] = useState(false);
    const [start, setStart] = useState("");
    const [startError, setStartError] = useState(false);
    const [end, setEnd] = useState("");
    const [endError, setEndError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationModalMessage, setConfirmationModalMessage] = useState("");
    const [confirmationModalData, setConfirmationModalData] = useState(null);


    const filterServices = (options, inputValue) => {
        return options.filter((option) => {
            if (inputValue === "")
                return true;
            let found = false;
            inputValue.split(" ").filter(Boolean).forEach((word) => {
                if (option.name.toLowerCase().startsWith(word.toLowerCase()))
                    found = true;
            });
            return found;
        });
    };

    const renderServices = (option) => {
        return option ? option.name : "";
    }

    const onChangeStart = (e) => {
        setStart(e.target.value);
        if (end === "" || moment(e.target.value).isAfter(end)) {
            if (eventTypeId === "CLIENT_APPOINTMENT")
                setEnd(moment(e.target.value).add(1, 'hours').format('YYYY-MM-DDTHH:mm'));
            else
                setEnd(moment(e.target.value).format('YYYY-MM-DDTHH:mm'));
        }
    }

    const onChangeEnd = (e) => {
        setEnd(e.target.value);
        if (start === "" || moment(e.target.value).isBefore(start)) {
            if (eventTypeId === "CLIENT_APPOINTMENT")
                setStart(moment(e.target.value).subtract(1, 'hours').format('YYYY-MM-DDTHH:mm'));
            else
                setStart(moment(e.target.value).format('YYYY-MM-DDTHH:mm'));
        }
    }

    const validate = () => {
        let valid = true;
        setErrorMessage("");
        if (start === '') {
            setStartError(true);
            valid = false;
        } else {
            setStartError(false);
        }
        if (end === '') {
            setEndError(true);
            valid = false;
        } else {
            setEndError(false);
        }
        return valid;
    }

    const handleSave = () => {
        setDisabled(true);
        if (validate()) {
            let data = {};
            data.type = eventTypeId !== 'ALL' ? eventTypeId : null;
            data.start = start;
            data.end = end;

            downloadEvents(data);
            setDisabled(false);
            onClose();
        } else {
            setDisabled(false);
        }
    };

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-20 overflow-y-auto"
                    onClose={() => onClose()}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30"/>
                        {isLoadingEventTypes ?
                            <Spinner/> :
                            <div
                                className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title as="h3"
                                              className="flex items-start justify-between pb-4 border-b text-gray-900 text-xl lg:text-2xl font-semibold">
                                        <Trans i18nKey="team-calendar.events-export.title">Export events</Trans>
                                    <button
                                        onClick={onClose}
                                        type="button"
                                        className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                        aria-label="Close">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="h-6 w-6">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"/>
                                        </svg>
                                    </button>
                                </Dialog.Title>
                                <div className="mt-2">
                                    {/*<form>*/}
                                    <div className="space-y-4">


                                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                            <label htmlFor="event-type" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                                <Trans i18nKey="team-calendar.event-modal.event-type">Event Type</Trans>
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    <select
                                                        id="event-type"
                                                        name="event-type"
                                                        value={eventTypeId}
                                                        onChange={(e) => setEventTypeId(e.target.value)}
                                                        className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none sm:text-sm ${eventTypeIdError ? "border-red-500" : "border-gray-300"}`}
                                                    >
                                                        <option value={"ALL"}><Trans i18nKey="team-calendar.events-export.all-types">All event types</Trans></option>
                                                        {eventTypes && Object.entries(eventTypes).map(([key, value]) => (
                                                            <option key={key} value={key}>{value.translated_string}</option>
                                                        ))}
                                                    </select>
                                            </div>
                                        </div>


                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                            <label htmlFor="start-time"
                                                   className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                                <Trans
                                                    i18nKey="team-calendar.event-modal.start.label">Start</Trans>
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    <input
                                                        type={"date"}
                                                        id="start-time"
                                                        name="start-time"
                                                        value={start}
                                                        onChange={onChangeStart}
                                                        className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none  sm:text-sm ${startError ? "border-red-500" : "border-gray-300"}`}
                                                    />
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 ">
                                            <label htmlFor="end-time"
                                                   className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                                <Trans
                                                    i18nKey="team-calendar.event-modal.end.label">End</Trans>
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    <input
                                                        type={"date"}
                                                        id="end-time"
                                                        name="end-time"
                                                        value={end}
                                                        onChange={onChangeEnd}
                                                        className={`mt-1 block w-full py-2 px-3 border bg-white shadow-sm focus:outline-none  sm:text-sm ${endError ? "border-red-500" : "border-gray-300"}`}
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                    {/*</form>*/}
                                </div>
                                {errorMessage &&
                                    <Transition
                                        show={true}
                                        enter="transition-opacity duration-800"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="transition-opacity duration-800"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div
                                            className="flex justify-between text-sm mt-4 mb-4 bg-red-100 border border-red-200 text-gray-700 px-4 py-3 relative">
                                            {errorMessage}
                                        </div>
                                    </Transition>
                                }
                                {!errorMessage && (
                                    <Transition
                                        show={true}
                                        enter="transition-opacity duration-800"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="transition-opacity duration-800"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="h-0"></div>
                                    </Transition>
                                )}
                                <div className="mt-4 flex justify-between">
                                    <button
                                        type="button"
                                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={onClose}

                                    >
                                        <Trans i18nKey="team-calendar.events-export.cancel">Cancel</Trans>
                                    </button>
                                    <button
                                        type="submit"
                                        className="inline-flex items-center border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-3 focus:ring-blue-400"
                                        onClick={handleSave}
                                    >
                                        <Trans i18nKey="team-calendar.events-export.save">Export</Trans>
                                    </button>
                                </div>
                            </div>

                        }
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};
export default EventsExportModal;
