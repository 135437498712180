import React, {useEffect, useRef, useState} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import deLocale from '@fullcalendar/core/locales/de';
import useApi from "../hooks/useApi";
import useConfig from "../hooks/useConfig";
import TeamMemberDropdown from "./TeamMemberDropdown";
import {Trans, useTranslation} from "react-i18next";
import EventModal from "./EventModal";
import moment from "moment";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import useRouting from "../hooks/useRouting"
import EventDropdown from "./EventDropdown";
import "./TeamCalendar.css";
import GridDropdown from "./GridDropdown";
import EventsExportModal from "./EventsExportModal";
import useUser from "../hooks/useUser";

export default function TeamCalendar() {
    const {t} = useTranslation();
    const {goToTeamCalendar, goToTeamCalendarNewEvent, goToTeamCalendarEvent} = useRouting();
    const {getEmployeeId} = useUser();
    const employeeId = getEmployeeId();
    const userIdMeta = document.querySelector('meta[name="user_id"]');
    const userId = userIdMeta ? parseInt(userIdMeta.content) : null;
    const teamMeta = document.querySelector('meta[name="team_id"]');
    const teamId = teamMeta ? teamMeta.content : null;
    const permissionsMeta = document.querySelector('meta[name="permissions"]');
    const permissions = permissionsMeta ? permissionsMeta.content.split(',') : [];
    const canTeamEvents = permissions.includes('team.events') || permissions.includes('teams');
    const canExportEvents = permissions.includes('events.export');
    const calendarRef = useRef(null);
    const [initialView, setInitialView] = React.useState('timeGridWeek');
    const [hiddenDays, setHiddenDays] = React.useState(moment().isoWeekday() === 6 || moment().isoWeekday() ? [] : [0, 6]);
    const [range, setRange] = React.useState({start: null, end: null});
    const [teamSelection, setTeamSelection] = React.useState([]);
    const [filteredEvents, setFilteredEvents] = React.useState([]);
    const [isEventsExportModalOpen, setIsEventsExportModalOpen] = React.useState(false);
    const [selectedEvent, setSelectedEvent] = React.useState(null);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const {queryTeamEvents, queryEvents} = useApi();
    let queryFunction = null;
    if (canTeamEvents && teamId) {
        queryFunction = () => {
            return queryTeamEvents(range, teamId);
        };
    } else {
        queryFunction = () => {
            return queryEvents(range);
        }
    }
    const {data: events = null, isLoadingEvents, refetch: eventsRefetch} = queryFunction();
    const [isLoading, setIsLoading] = useState(true);
    const [eventAddedorUpdated, setEventAddedorUpdated] = useState(false);
    const {eventColors} = useConfig();

    const getStatusColor = (event) => {
        if (event.event_type_id === 'CLIENT_APPOINTMENT') {
            if (event.status === 'NOT_PERFORMED') {
                if (event.employee_ids.includes(employeeId)) {
                    return eventColors.notPerformed;
                } else {
                    return eventColors.notPerformedLight;
                }
            } else if (event.status === 'PERFORMED') {
                if (event.employee_ids.includes(employeeId)) {
                    return eventColors.performed;
                } else {
                    return eventColors.performedLight;
                }
            } else if (event.status === 'CONFIRMED') {
                return eventColors.confirmed;
            }
        }
    }

    const getUserEventColor = (event) => {
        if (event.event_type_id === 'CLIENT_APPOINTMENT') {
            if (event.employee_ids.length > 0) {
                if (event.status === 'NOT_PERFORMED') {
                    if (event.employee_ids.includes(employeeId)) {
                        return eventColors.notPerformed;
                    } else {
                        return eventColors.notPerformedLight;
                    }
                } else if (event.status === 'PERFORMED') {
                    if (event.employee_ids.includes(employeeId)) {
                        return eventColors.performed;
                    } else {
                        return eventColors.performedLight;
                    }
                } else if (event.status === 'CONFIRMED') {
                    return eventColors.confirmed;
                }
            } else {
                return eventColors.notAssigned;
            }
        } else if (event.event_type_id === 'INTERNAL') {
            return eventColors.internal;
        }
    }

    const handleCalendarLoad = () => {
        setIsLoading(false);
    };

    const onTeamSelectionChange = (newTeamSelection) => {
        setTeamSelection(newTeamSelection);
    }

    useEffect(() => {
        if (events) {
            let result = [];
            if (canTeamEvents && teamId) {
                let nEvents = 0;
                let lastPercentage = 0;
                for (const userEvents of events) {
                    if ((!userEvents.employee_id && teamSelection.includes(-1)) || teamSelection.includes(userEvents.employee_id)) {
                        nEvents += userEvents.events.length;
                    }
                }
                for (const userEvents of events) {
                    if (userEvents.employee_id) {
                        for (const event of userEvents.events) {
                            event.color = 'transparent';
                            if (event.event_type_id !== 'INTERNAL' && teamSelection.includes(userEvents.employee_id)) {
                                event.stripeColor = userEvents.color;
                                event.allDay = event.all_day === 1;
                                result.push(event);
                            } else if (event.event_type_id === 'INTERNAL') {
                                // event.color = eventColors.internal;
                                if (!result.find((e) => event.id === e.id)) {
                                    result.push(event);
                                }
                            }
                        }
                    } else if ((!userEvents.employee_id && teamSelection.includes(-1))) {
                        for (const event of userEvents.events) {
                            event.color = 'transparent';
                            // event.color = eventColors.notAssigned;
                            event.allDay = event.all_day === 1;
                            result.push(event);
                        }
                    }
                }
            } else {
                for (const event of events) {
                    if (canTeamEvents) {
                        if ((teamSelection.filter((id) => event.employee_ids.includes(id)).length > 0 && (
                            event.event_type_id === 'CLIENT_APPOINTMENT' || event.event_type_id === 'HOLIDAY' || event.event_type_id === 'SICK')) || event.event_type_id === 'INTERNAL' || event.event_type_id === 'CLIENT_APPOINTMENT' && !event.employee_ids.length && teamSelection.includes(-1)) {
                            event.stripeColor = eventColors.internal;
                            if (event.event_type_id === 'CLIENT_APPOINTMENT' || event.event_type_id === 'HOLIDAY' || event.event_type_id === 'SICK') {
                                event.stripeColor = event.employee_colors.length > 0 ? event.employee_colors[0] : eventColors.notAssigned;
                            }
                            event.color = 'transparent';
                            event.allDay = event.all_day === 1;
                            result.push(event);
                        }
                    } else {
                        event.color = 'transparent';
                        event.stripeColor = eventColors.internal;
                        event.allDay = event.all_day === 1;
                        result.push(event);
                    }
                }
            }
            for (const event of result) {
                if (event.event_type_id === 'HOLIDAY') {
                    event.title = t('team-calendar.event-types.holiday', 'Holiday');
                } else if (event.event_type_id === 'SICK') {
                    event.title = t('team-calendar.event-types.sick', 'Sick');
                }
                else if (event.event_type_id === 'INTERNAL') {
                    // event.title = t('team-calendar.event-types.internal', 'internal');
                }
            }

            setFilteredEvents(result);
            setIsLoading(false);
        }

        if(eventAddedorUpdated){
            eventsRefetch().then(() => {
                setEventAddedorUpdated(false);
            });
        }
    }, [teamSelection, events, eventAddedorUpdated]);

    const newUpdatedEventCallback = (value) => {
        setEventAddedorUpdated(value);
    };

    const handlePrevClick = () => {
        calendarRef.current.getApi().prev();
    };

    const handleNextClick = () => {
        calendarRef.current.getApi().next();
    };

    const handleTodayClick = () => {
        calendarRef.current.getApi().today();
    };

    const handleEventModalClose = (event = null, action = 'created', close = true) => {
        if (event) {
            if (action === 'created' || action === 'updated') {
                event.allDay = event.all_day === 1;
                if (event.event_type_id === 'HOLIDAY') {
                    event.title = t('team-calendar.event-types.holiday', 'Holiday');
                } else if (event.event_type_id === 'SICK') {
                    event.title = t('team-calendar.event-types.sick', 'Sick');
                }
                let newFilteredEvents = [...filteredEvents];
                event.stripeColor = eventColors.internal;
                if (canTeamEvents && event.event_type_id !== 'INTERNAL' && teamSelection.includes(event.employee_id)) {
                    event.stripeColor = events.find((userEvent) => userEvent.employee_id === event.employee_id).color;
                } else if (event.event_type_id === 'CLIENT_APPOINTMENT' && !event.employee_id) {
                    event.stripeColor = eventColors.notAssigned;
                }
                event.color = 'transparent';
                if (action === 'created') {
                    newFilteredEvents.push(event);
                    setFilteredEvents(newFilteredEvents);
                } else if (action === 'updated') {
                    for (let i = 0; i < newFilteredEvents.length; i++) {
                        if (newFilteredEvents[i].id === event.id) {
                            newFilteredEvents[i] = event;
                            setFilteredEvents(newFilteredEvents);
                            break;
                        }
                    }
                }
            } else if (action === 'deleted') {
                let newFilteredEvents = [...filteredEvents];
                for (let i = 0; i < newFilteredEvents.length; i++) {
                    if (newFilteredEvents[i].id === event.id) {
                        newFilteredEvents.splice(i, 1);
                        setFilteredEvents(newFilteredEvents);
                        break;
                    }
                }
            }
        }
        if (close) {
            setSelectedDate(null);
            goToTeamCalendar();
        }
    }

    const handleDateChange = (dateInfo) => {
        setRange({
            start: moment(dateInfo.star).subtract(1, 'week').format('YYYY-MM-DD'),
            end: moment(dateInfo.end).add(1, 'week').format('YYYY-MM-DD')
        });
    }
    const handleDateClick = (arg) => {
        setSelectedDate(arg);
        if (arg.allDay && arg.view.type !== 'timeGridMonth') {
            goToTeamCalendarNewEvent('holiday');
        } else {
            goToTeamCalendarNewEvent('client_appointment');
        }
    }

    const handleEventClick = (arg) => {
        if (arg.event.id) {
            goToTeamCalendarEvent(arg.event.id);
        }
    }

    const eventButtonHandler = (eventType) => {
        goToTeamCalendarNewEvent(eventType);
    }

    const gridButtonHandler = (gridType) => {
        const calendarApi = calendarRef.current.getApi();
        if (gridType === 'five-days') {
            setHiddenDays([0, 6]);
            calendarApi.changeView('timeGridWeek')
        } else if (gridType === 'single-day') {
            setHiddenDays([]);
            calendarApi.changeView('timeGridDay')
        } else if (gridType === 'week') {
            setHiddenDays([]);
            calendarApi.changeView('timeGridWeek')
        } else if (gridType === 'month') {
            setHiddenDays([]);
            calendarApi.changeView('dayGridMonth')
        } else if (gridType === 'list') {
            setHiddenDays([]);
            calendarApi.changeView('listDay')
        }
    }

    const downloadEventsHandler = () => {
        setIsEventsExportModalOpen(true);
    }

    const ProgressBar = ({ progressPercentage }) => {
        return (
            <div className='h-1 w-full bg-gray-300'>
                <div
                    style={{ width: `${progressPercentage}%`}}
                    className={`h-full ${
                        progressPercentage < 70 ? 'bg-blue-600' : 'bg-green-600'}`}>
                </div>
            </div>
        );
    };

    const eventRender = (info) => {
        return (
            <div>
                <strong>mmmm {info.event.title}</strong> {/* Customize the rendering of the event title */}
                <p>mmmm {info.event.start.toLocaleString()}</p> {/* Example: Display the event start date */}
            </div>
        );
    };

    const renderEventContent = (eventInfo) => {;
        const color = getStatusColor(eventInfo.event.extendedProps);
        let stripeColor = eventColors.internal;
        if (canTeamEvents) {
            if ((eventInfo.event.extendedProps.event_type_id === 'CLIENT_APPOINTMENT' || eventInfo.event.extendedProps.event_type_id === 'HOLIDAY' || eventInfo.event.extendedProps.event_type_id === 'SICK') && eventInfo.event.extendedProps.employee_ids.length !== 0) {
                stripeColor = eventInfo.event.extendedProps.stripeColor;
            }
        }
        if (eventInfo.event.extendedProps.event_type_id === 'CLIENT_APPOINTMENT' && eventInfo.event.extendedProps.employee_ids.length === 0) {
            stripeColor = eventColors.notAssigned;
        }
        let title = eventInfo.event.title;

        return (
            <div style={{
                backgroundColor: '#ffffff',
                borderRadius: '4px',
                border: '1px solid #aaaaaa',
                borderLeft: '8px solid ' + stripeColor,
                height: '100%',
                maxWidth: '100%',
                position: 'relative',
                cursor: 'pointer',
                fontSize: '12px',
                padding: '4px',
                color: '#aaa'
            }}>
                { eventInfo.event.extendedProps.event_type_id === 'CLIENT_APPOINTMENT' &&
                    <div style={{float: 'right', width: '16px', height: '16px'}}>
                        <div
                            className={'h-3 w-3 rounded-full cursor-pointer'}
                            style={{backgroundColor: color}}
                        ></div>
                    </div>
                }
                <div>{t('team-calendar.event.time', {date: eventInfo.event.start})} - {t('team-calendar.event.time', {date: eventInfo.event.end})}</div>
                <div style={{
                    position: 'relative',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                }}>{title}</div>
                { eventInfo.event.extendedProps.location &&
                    <div style={{
                        position: 'relative',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}>{eventInfo.event.extendedProps.location}</div>
                }
            </div>
        );
    };

    return <div className="relative">
        {isLoading &&
            <div className="h-1 inset-x-0 overflow-hidden absolute top-0">
                <div className="absolute inset-0 bg-blue-500 animate-pulse"></div>
                {/*<div className="absolute left-0 top-0 bg-blue-500 h-full animate-slide"></div>*/}
            </div>
        }
        <header className="sticky top-0 items-center md:justify-between border-b border-gray-200 py-4 px-2 md:px-6 bg-white flex z-10">
                <div className="flex">
                    { canTeamEvents &&
                        <TeamMemberDropdown teamSelectionHandler={onTeamSelectionChange} teamId={teamId} />
                    }
                    { canExportEvents &&
                        <button onClick={downloadEventsHandler} type="button" className="inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-gray-400 bg-white rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none text-gray-700">
                            <span className="hidden md:block text-gray-700"><Trans i18nKey="team-calendar.export">Export</Trans></span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="sm:block md:hidden w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                            </svg>
                        </button>
                    }
                </div>
                <div className="flex justify-end flex-grow">
                    <GridDropdown gridHandler={gridButtonHandler} />
                    <div className="md:hidden mx-1 w-px"></div>
                    <div className="hidden md:block mx-2 w-px"></div>
                    <div className="flex items-center rounded-md shadow-sm flex-shrink-0">
                        <button type="button" onClick={handlePrevClick}
                                className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-1 md:pr-2 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50">
                            <span className="sr-only">Previous</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true" className="h-5 w-5">
                                <path fillRule="evenodd"
                                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                      clipRule="evenodd"></path>
                            </svg>
                        </button>
                        <button type="button" onClick={handleTodayClick}
                                className="py-2 block border-t border-b border-gray-300 bg-white px-0 md:px-3.5 text-sm font-medium text-gray-400 hover:bg-gray-50 hover:text-gray-900 focus:relative">
                            <span className="hidden md:block text-gray-700"><Trans i18nKey="team-calendar.today">Today</Trans></span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="sm:block md:hidden w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                            </svg>
                        </button>
                        {/*<span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden"></span>*/}
                        <button type="button" onClick={handleNextClick}
                                className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 sm:pl-1 pl-1 md:pl-2 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50">
                            <span className="sr-only">Next</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true" className="h-5 w-5">
                                <path fillRule="evenodd"
                                      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                      clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </div>
                    <div className="hidden md:block mx-6 my-2 h-5 w-px bg-gray-300 flex-shrink-0"></div>
                    <div className="md:hidden mx-1 w-px"></div>
                    <EventDropdown eventHandler={eventButtonHandler}/>
                </div>
        </header>
        <div style={{backgroundColor: 'white'}}>
        <FullCalendar
            ref={calendarRef}
            eventContent={renderEventContent}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
            initialView={initialView}
            dateClick={handleDateClick}
            eventClick={handleEventClick}
            allDayText={''}
            datesSet={handleDateChange}
            slotEventOverlap={false}
            headerToolbar={false}
            height={'100vh'}
            contentHeight={'auto'}
            locale={deLocale}
            events={filteredEvents}
            slotDuration={'00:15:00'}
            slotLabelInterval={'01:00:00'}
            hiddenDays={hiddenDays}
            views={{
                timeGridDay: {
                    type: 'timeGrid',
                    duration: { days: 1 },
                },
                timeGridWeek: {
                    type: 'timeGrid',
                    duration: { weeks: 1 },
                },
            }}
            slotLabelFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: 'short'
            }}
            // eventContent={eventRender}
        />
        </div>
        <EventsExportModal isOpen={isEventsExportModalOpen} onClose={() => setIsEventsExportModalOpen(false)} />
        <Routes>
            <Route path="/new-event/:type" element={<EventModal currentUserId={userId} currentEmployeeId={employeeId} teamId={teamId} canTeamEvents={canTeamEvents} isOpen={true} event={selectedEvent} onClose={handleEventModalClose} date={selectedDate} />} />
            <Route path="/events/:id" element={<EventModal currentUserId={userId} currentEmployeeId={employeeId} teamId={teamId} canTeamEvents={canTeamEvents} isOpen={true} event={selectedEvent} onClose={handleEventModalClose} date={selectedDate} />} />
        </Routes>
    </div>
}
