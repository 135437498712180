import useApi from "../../hooks/useApi";
import {useSearchParams} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import useRouting from "../../hooks/useRouting";
import FormTable from "./../forms/FormTable";

const Institutions = (props) => {
    const isSmallScreen = window.innerWidth < 600;
    const [columnVisibility, setColumnVisibility] = React.useState({
        addresses: false,
        description: !isSmallScreen,
        type: !isSmallScreen,
    });
    const [searchParams] = useSearchParams();
    const routing = useRouting();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {queryInstitutions} = useApi();
    const [columns, setColumns] = React.useState([
        {
            field: 'ik_number',
            headerName: t('institutions.ik-number.label', 'IK number'),
            width: 150,
            editable: false,
            headerClassName: 'data-grid-header'
        },
        {
            field: 'shortname',
            headerName: t('institutions.shortname.label', 'Name'),
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header'
        },
        {
            field: 'description',
            headerName: t('institutions.description.label', 'Description'),
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header'
        },
        {
            field: 'type',
            headerName: t('institutions.type.label', 'Type'),
            renderCell: (params) => {
                return t(`institutions.type.${params.value}`, params.value);
            },
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header'
        },
        {
            field: 'addresses',
            headerName: t('institutions.addresses.label', 'Addresses'),
            flex: 1,
            sortable: false,
            editable: false,
            headerClassName: 'data-grid-header'
        },
    ]);
    const [sortModel, setSortModel] = React.useState(searchParams.get('sortField') && searchParams.get('searchOrder') ? [{
        field: searchParams.get('sortField'),
        sort: searchParams.get('sortOrder') ?? 'asc'
    }] : []);
    const [searchText, setSearchText] = React.useState(searchParams.get('search') ?? '');
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize')) : props.paginationModel.pageSize,
        page: searchParams.get('page') ? parseInt(searchParams.get('page')) : props.paginationModel.page,
    });
    const {data: institutions = null, isLoading} = queryInstitutions(
        paginationModel.pageSize,
        paginationModel.page + 1,
        sortModel.length > 0 ? sortModel[0].field : null,
        sortModel.length > 0 ? sortModel[0].sort : null,
        searchText);

    const handleRowClick = (params, event) => {
        navigate(`/institutions/${params.id}`);
    }

    const handleNewInstitutionClick = (event) => {
        navigate(`/institutions/new`);
    }

    const handleScreenSizeChange = (isSmallScreen) => {
        let newColumnVisibility = {
            ...columnVisibility,
        }
        newColumnVisibility.description = !isSmallScreen;
        newColumnVisibility.type = !isSmallScreen;
        setColumnVisibility(newColumnVisibility);
    }

    return (
        <FormTable
            createButtonLabel={t("institutions.new-institution", "New Institution")}
            columns={columns}
            data={institutions}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            onSearchTextChange={setSearchText}
            onRowClick={handleRowClick}
            onNewClick={handleNewInstitutionClick}
            sortModel={sortModel}
            setSortModel={setSortModel}
            onScreenSizeChange={handleScreenSizeChange}
            isLoading={isLoading}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            />
    );
}

export default Institutions;
