import {useQuery, useQueryClient} from "react-query";
import axios from "axios";
import useRouting from "./useRouting";

const useApi = () => {
    const reactQueryClient = useQueryClient();
    const path = '/api/v1';
    const routing = useRouting();

    const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
    const csrfToken = csrfTokenMeta ? csrfTokenMeta.content : null;

    const responseSuccessHandler = response => {
        return response;
    };

    const responseErrorHandler = async (error) => {
        if (error && error.response && error.response.status === 401) {
            window.location.href = '/login';
        }

        return Promise.reject(error);
    }

    axios.interceptors.response.use(
        response => responseSuccessHandler(response),
        error => responseErrorHandler(error)
    );

    let config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': csrfToken
        },
    };

    const queryEvents = (range, onSuccess = null) => {
        if (!range.start || !range.end) {
            return useQuery(
                ["events", range],
                async () => {
                    return [];
                }
            );
        }
        return useQuery(
            ["events", range],
            async () => {
                config = {
                    ...config,
                    params: {
                        start: range.start,
                        end: range.end
                    }
                }
                const response = await axios.get(path + "/events", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const queryTeamEvents = (range, teamId, onSuccess = null) => {

        if (!range.start || !range.end) {
            return useQuery(
                ["team-events", range, teamId],
                async () => {
                    return [];
                }
            );
        }
        let url = path + "/teams/" + teamId + "/events";
        if (!teamId)
            url = path + "/events";
        if (range.start && range.end)
            url += "?start=" + range.start + "&end=" + range.end;
        return useQuery(
            ["team-events", range, teamId],
            async () => {
                const response = await axios.get(url, config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryEventTypes = (onSuccess = null) => {
        return useQuery(
            ["event-types"],
            async () => {
                const response = await axios.get(path + "/events/event-types", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryTeamMembers = (teamId, onSuccess = null) => {
        return useQuery(
            ["team-members"],
            async () => {
                const response = await axios.get(path + "/teams/" + teamId + "/members", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };


    const queryEmployeesByTeam = (teamId, onSuccess = null) => {
        return useQuery(
            ["employees", teamId],
            async () => {
                const response = await axios.get(path + "/teams/" + teamId + "/members", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryEmployee = (id, onSuccess = null) => {
        return useQuery(
            ["employee", id],
            async () => {
                if (id === 'new') {
                    return {};
                }
                const response = await axios.get(path + "/employees/" + id, config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const saveEmployee = async (data) => {
        const response = await axios.post(path + '/employees', data, config);
        return response.data;

    };

    const updateEmployee = async (data) => {
        const response = await axios.patch(path + '/employees/' + data.id, data, config);
        return response.data;
    };

    const deleteEmployee = async (id) => {
        const response = await axios.delete(path + '/employees/' + id, config);
        return response.data;
    }

    const queryAllEmployees = (onSuccess = null) => {
        return useQuery(
            ["all-employees"],
            async () => {
                const response = await axios.get(path + "/employees", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const queryEmployees = (pageSize = null, page = null, sortField = null, sortOrder= null, search = null, onSuccess = null) => {
        return useQuery(
            ["employees", pageSize, page, search, sortField, sortOrder],
            async () => {
                config = {
                    ...config,
                    params: {
                        pageSize: pageSize,
                        page: page,
                        search: search,
                        sortField: sortField,
                        sortOrder: sortOrder
                    }
                }
                const response = await axios.get(path + "/employees", config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const saveOrUpdateEmployee = async (data) => {
        if (data.id && data.id !== "new") {
            return updateEmployee(data);
        } else {
            return saveEmployee(data);
        }
    }

    const queryRoles = (onSuccess = null) => {
        return useQuery(
            ["roles"],
            async () => {
                const response = await axios.get(path + "/employees/roles", config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const queryPatients = (employeeId, teamId, onSuccess = null) => {
        if (!employeeId && !teamId || parseInt(employeeId) === -1 && !teamId) {
            return useQuery(
                ["patients"],
                async () => {
                    const response = await axios.get(path + "/clients", config);
                    return response.data.data;
                },
                {onSuccess: onSuccess}
            );
        }
        if (!employeeId || parseInt(employeeId) === -1) {
            return useQuery(
                ["patients-team", teamId],
                async () => {
                    const response = await axios.get(path + "/teams/" + teamId + "/clients", config);
                    return response.data.data;
                },
                {onSuccess: onSuccess}
            );
        }

        return useQuery(
            ["patients", employeeId],
            async () => {
                const response = await axios.get(path + "/employees/" + employeeId + "/clients", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryServices = (onSuccess = null) => {
        return useQuery(
            ["services"],
            async () => {
                const response = await axios.get(path + "/services", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryBudgets = (onSuccess = null) => {
        return useQuery(
            ["budgets"],
            async () => {
                const response = await axios.get(path + "/budgets", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryActiveBudgets = (client, onSuccess = null) => {
        if (!client) {
            return queryBudgets(onSuccess);
        } else {
            return useQuery(
                ["active-budgets", client.id],
                async () => {
                    const response = await axios.get(path + "/clients/" + client.id + "/active-budgets", config);
                    return response.data;
                },
                {onSuccess: onSuccess}
            );
        }
    }


    const queryClientBudgets = (clientId, pageSize, page, sortField, sortOrder, search = '', onSuccess = null) => {
        return useQuery(
            ["client-budgets", clientId, pageSize, page, sortField, sortOrder, search],
            async () => {
                let url = path + "/clients/" + clientId + "/budgets?pageSize=" + pageSize + "&page=" + page + '&search=' + search;
                if (sortField && sortOrder) {
                    url += '&sortField=' + sortField + '&sortOrder=' + sortOrder;
                }
                const response = await axios.get(url, config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryClientBudget = (clientId, budgetId, onSuccess = null) => {
        return useQuery(
            ["client-budget", clientId, budgetId],
            async () => {
                if (budgetId === 'new') {
                    return {};
                }
                const response = await axios.get(path + "/clients/" + clientId + "/budgets/" + budgetId, config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const saveClientBudget = async (clientId, data) => {
        const response = await axios.post(path + '/clients/' + clientId + '/budgets/create', data, config);
        return response.data;
    }
    const updateClientBudget = async (clientId, data) => {
        const response = await axios.patch(path + '/clients/' + clientId + '/budgets/' + data.id, data, config);
        return response.data;
    }
    const saveOrUpdateClientBudget = async (clientId, data) => {
        if (data.id && data.id !== "new") {
            return updateClientBudget(clientId, data)
        } else {
            return saveClientBudget(clientId, data);
        }
    }

    const queryInvoiceReceivers = (clientId,pageSize, page, sortField, sortOrder, search = '', onSuccess = null) => {
        return useQuery(
            ["invoice-receivers",clientId, pageSize, page, sortField, sortOrder, search],
            async () => {
                let url = path + "/clients/" + clientId + "/invoice-receivers?pageSize=" + pageSize + "&page=" + page + '&search=' + search;
                if (sortField && sortOrder) {
                    url += '&sortField=' + sortField + '&sortOrder=' + sortOrder;
                }
                const response =  await axios.get(url, config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryInvoiceReceiver = (clientId,invoiceReceiverId, onSuccess = null) => {
        return useQuery(
            ["client-budget", clientId, invoiceReceiverId],
            async () => {
                if (invoiceReceiverId === 'new') {
                    return {};
                }
                const response =  await axios.get(path + "/clients/" + clientId + "/invoice-receivers/" + invoiceReceiverId, config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const saveInvoiceReceiver = async (clientId,data) =>{
        const response = await axios.post(path + '/clients/' +clientId+ '/invoice-receivers' , data, config);
        return response.data;
    }
    const updateInvoiceReceiver = async (clientId,data) =>{
        const response = await axios.patch(path + '/clients/' +clientId+ '/invoice-receivers/' + data.id, data, config);
        return response.data;
    }
    const saveOrUpdateInvoiceReceiver = async (clientId,data) => {
        if (data.id && data.id !== "new") {
            return updateInvoiceReceiver(clientId,data)
        } else {
            return saveInvoiceReceiver(clientId,data);
        }
    }

    const queryClientProtocols = (clientId, pageSize, page, sortField, sortOrder, search = '', onSuccess = null) => {
        return useQuery(
            ["client-protocols", clientId, pageSize, page, sortField, sortOrder, search],
            async () => {
                config = {
                    ...config,
                    params: {
                        pageSize: pageSize,
                        page: page,
                        search: search,
                        sortField: sortField,
                        sortOrder: sortOrder
                    }
                }
                const response = await axios.get(path + "/clients/" + clientId + "/protocols", config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const queryClientProtocol = (clientId, protocolId, onSuccess = null) => {
        return useQuery(
            ["client-protocol", clientId, protocolId],
            async () => {
                if (protocolId === 'new') {
                    return {};
                }
                const response = await axios.get(path + "/clients/" + clientId + "/protocols/" + protocolId, config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const saveClientProtocol = async (clientId, data) => {
        const response = await axios.post(path + '/clients/' + clientId + '/protocols/', data, config);
        return response.data;
    }

    const updateClientProtocol = async (clientId, data) => {
        const response = await axios.patch(path + '/clients/' + clientId + '/protocols/' + data.id, data, config);
        return response.data;
    }

    const saveOrUpdateClientProtocol = async (clientId, data) => {
        if (data.id && data.id !== "new") {
            return updateClientProtocol(clientId, data);
        } else {
            return saveClientProtocol(clientId, data);
        }
    }


    const queryPhyisicians = (onSuccess = null) => {
        return useQuery(
            ["physicians"],
            async () => {
                const response =  await axios.get(path + "/physicians", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const queryTeams = (onSuccess = null) => {
        return useQuery(
            ["teams"],
            async () => {
                const response =  await axios.get(path + "/teams", config);
                return response.data;
                //console.log(response.data);
            },
            {onSuccess: onSuccess}
        );
    };


    const saveOrUpdateEvent = async (data) => {
        if (data.id) {
            return updateEvent(data);
        } else {
            return saveEvent(data);
        }
    }

    const saveEvent = async (data) => {
        const response = await axios.post(path + '/events', data, config);
        return response.data;
    };

    const updateEvent = async (data) => {
        const response = await axios.patch(path + '/events/' + data.id, data, config);
        return response.data;
    };

    const queryEvent = (id, onSuccess = null) => {
        if (!id) {
            return useQuery(
                ["event", id],
                async () => {
                    return null;
                }
            );
        }

        return useQuery(
            ["event", id],
            async () => {
                const response =  await axios.get(path + "/events/" + id, config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const updateEventStatus = async (eventId, status, employeeIds) => {
        const response = await axios.post(path + '/events/' + eventId + '/update-status', {status: status, employee_ids: employeeIds}, config);
        return response.data;
    };

    const deleteEvent = async (eventId) => {
        const response = await axios.delete(path + '/events/' + eventId, config);
        return response.data;
    }

    const queryInstitutions = (pageSize, page, sortField, sortOrder, search = '', onSuccess = null) => {
        return useQuery(
            ["institutions", pageSize, page, sortField, sortOrder, search],
            async () => {
                let url = path + "/institutions?pageSize=" + pageSize + "&page=" + page + '&search=' + search;
                if (sortField && sortOrder) {
                    url += '&sortField=' + sortField + '&sortOrder=' + sortOrder;
                }
                const response =  await axios.get(url, config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryInstitution = (id, onSuccess = null) => {
        return useQuery(
            ["institution", id],
            async () => {
                if (id === 'new') {
                    return {};
                }
                const response =  await axios.get(path + "/institutions/" + id, config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    };
    const saveInstitution = async (data) =>{
        const response = await axios.post(path + '/institutions' , data, config);
        return response.data;

    };

    const updateInstitution = async (data) =>{
        const response = await axios.patch(path + '/institutions/' + data.id, data, config);
        return response.data;
    };

    const saveOrUpdateInstitution = async (data) => {
        if (data.id && data.id !== "new") {
            return updateInstitution(data);
        } else {
            return saveInstitution(data);
        }
    }

    const queryCareDegrees = (onSuccess = null) => {
        return useQuery(
            ["care-degrees"],
            async () => {
                const response =  await axios.get(path + "/care-degrees", config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const queryClients = (pageSize, page, search, onSuccess = null) => {
        return useQuery(
            ["clients", pageSize, page, search],
            async () => {
                config = {
                    ...config,
                    params: {
                        pageSize: pageSize,
                        page: page,
                        search: search
                    }
                }
                const response =  await axios.get(path + "/clients", config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryClientContacts = (clientId, pageSize = null, page = null, sortField = null, sortOrder = null, search = null, onSuccess = null) => {
        return useQuery(
            ["client-contacts",clientId, pageSize, page, sortField, sortOrder, search],
            async () => {
                config = {
                    ...config,
                    params: {
                        pageSize: pageSize,
                        page: page,
                        search: search,
                        sortField: sortField,
                        sortOrder: sortOrder
                    }
                }
                const response =  await axios.get(path + "/clients/" + clientId + "/contacts", config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const queryClientContact = (clientId,contactId, onSuccess = null) => {
        return useQuery(
            ["client-contact", clientId, contactId],
            async () => {
                if (contactId === 'new') {
                    return {};
                }
                const response =  await axios.get(path + "/clients/" + clientId + "/contacts/" + contactId, config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    };

    const saveClientContact = async (clientId,data) =>{
        const response = await axios.post(path + '/clients/' +clientId+ '/contacts/create' , data, config);
        return response.data;
    }
    const updateClientContact = async (clientId,data) =>{
        const response = await axios.patch(path + '/clients/' +clientId+ '/contacts/' + data.id, data, config);
        return response.data;
    }
    const saveOrUpdateClientContact = async (clientId,data) => {
        if (data.id && data.id !== "new") {
            return updateClientContact(clientId,data);
        } else {
            return saveClientContact(clientId,data);
        }
    }


    const queryClient = (id, onSuccess = null) => {
        return useQuery(
            ["client", id],
            async () => {
                if (id === 'new') {
                    return {};
                }
                const response =  await axios.get(path + "/clients/" + id, config);
                return response.data.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const saveClient = async (data) =>{
        const response = await axios.post(path + '/clients' , data, config);
        return response.data;
    }

    const updateClient = async (data) =>{
        const response = await axios.patch(path + '/clients/' + data.id, data, config);
        return response.data;
    }

    const saveOrUpdateClient = async (data) => {
        if (data.id && data.id !== "new") {
            return updateClient(data);
        } else {
            return saveClient(data);
        }
    }

    const queryClientsWithoutEvents = (onSuccess = null) => {
        return useQuery(
            ["clients-without-events"],
            async () => {
                const response =  await axios.get(path + "/clients/without-events", config);
                return response.data;
            },
            {onSuccess: onSuccess}
        );
    }

    const downloadEvents = (data) => {
        let url = path + '/events/export?start=' + data.start + '&end=' + data.end;
        if (data.type) {
            url += '&type=' + data.type;
        }
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    // const downloadEvents = async (data) => {
    //     const response = await axios.post(path + '/events/export', data, config);
    //     return response.data;
    // };

    return {
        queryEvents,
        queryTeamEvents,
        queryEventTypes,
        queryTeamMembers,
        queryTeams,
        queryEmployee,
        queryEmployeesByTeam,
        queryAllEmployees,
        queryEmployees,
        deleteEmployee,
        saveOrUpdateEmployee,
        queryRoles,
        queryPatients,
        queryServices,
        queryBudgets,
        queryActiveBudgets,
        queryClientBudgets,
        queryClientBudget,
        saveOrUpdateClientBudget,
        queryInvoiceReceivers,
        queryInvoiceReceiver,
        saveOrUpdateInvoiceReceiver,
        queryClientProtocols,
        queryClientProtocol,
        saveOrUpdateClientProtocol,
        queryPhyisicians,
        saveEvent,
        updateEvent,
        saveOrUpdateEvent,
        queryEvent,
        updateEventStatus,
        deleteEvent,
        queryInstitutions,
        queryInstitution,
        updateInstitution,
        saveInstitution,
        saveOrUpdateInstitution,
        queryCareDegrees,
        queryClients,
        queryClient,
        queryClientContacts,
        queryClientContact,
        saveOrUpdateClientContact,
        saveOrUpdateClient,
        queryClientsWithoutEvents,
        downloadEvents
    };
};
export default useApi;
