import React from "react";
import "../../i18n/i18n";
import {QueryClient, QueryClientProvider} from "react-query";
import ReactDOMClient from "react-dom/client";
import {
    BrowserRouter,
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
    Routes
} from "react-router-dom";
import Employees from "./Employees";
import { deDE } from '@mui/x-data-grid';
import {createTheme, ThemeProvider} from "@mui/material";
import Employee from "./Employee";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {LocalizationProvider} from "@mui/x-date-pickers";

const theme = createTheme({
        palette: {
            primary: {
                main: '#4E80EE',
            },
            secondary: {
                main: '#E89D27',
            },
        },
        typography: {
            fontFamily: "'Roboto', sans-serif",
            fontSize: 14,
        },
    },  deDE, // x-data-grid translations
);

const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            }
        }
    }
);

export default function EmployeesContainer() {

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 10,
        page: 0,
    });

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route>
                <Route exact path={"employees/:id"} element={<Employee />} />
                <Route exact path={"employees"} element={<Employees paginationModel={paginationModel} setPaginationModel={setPaginationModel} />} />
            </Route>
        )
    );

    return <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
                {/*<BrowserRouter>*/}
                {/*    <Routes>*/}
                {/*        <Route exact path={"employees/:id"} element={<Employee />} />*/}
                {/*        <Route exact path={"employees"} element={<Employees paginationModel={paginationModel} setPaginationModel={setPaginationModel} />} />*/}
                {/*    </Routes>*/}
                {/*</BrowserRouter>*/}
            </QueryClientProvider>
        </LocalizationProvider>
    </ThemeProvider>;
}

if (document.getElementById('employees-container')) {
    ReactDOMClient.createRoot(document.getElementById('employees-container')).render(<EmployeesContainer/>);
}
