import {useNavigate} from "react-router-dom";


const useRouting = () => {
    const navigate = useNavigate();
    const teamCalendarPath = '/calendar';
    const institutionsPath = '/institutions';
    const employeesPath = '/employees';
    const employeesNewPath = '/employees';
    const clientsPath = '/clients';

    const getTeamCalendarPath = () => {
        return teamCalendarPath;
    }

    const getTeamCalendarEventPath = (id) => {
        return teamCalendarPath + '/events/' + id;
    }

    const getInstitutionPath = () => {
        return teamCalendarPath;
    }

    const getClientsPath = () => {
        return clientsPath;
    }

    const getClientRootPath = () => {
        return clientsPath;
    }

    const getClientPath = (id) => {
        return clientsPath + '/' + id + '/general';
    }

    const getClientTabPath = (id, tab) => {
        return clientsPath + '/' + id + '/' + tab;
    }

    const goTo = (path) => {
        navigate(path);
    }

    const goToLogin = () => {
        navigate('/login');
    }

    const goToTeamCalendar = () => {
        navigate(teamCalendarPath);
    }

    const goToInstitutions = () => {
        navigate(institutionsPath);
    }

    const goToClients = () => {
        navigate(clientsPath);
    }

    const goToEmployees = () => {
        navigate(employeesNewPath);
    }
    const goToEmployee = (id) => {
        navigate(employeesNewPath + '/' + id);
    }
    const goToClientContact = (id, contactId) => {
       let path = clientsPath + '/' + id + "/contacts";
        navigate(path + '/' + contactId);
    }

    const goToClientContacts = (id) => {
        let path = clientsPath + '/' + id + "/contacts";
        navigate(path);
    }

    const goToClientBudget = (id, budgetId) => {
        let path = clientsPath + '/' + id + "/budgets";
        navigate(path + '/' + budgetId);
    }

    const goToClientBudgets = (id) => {
        let path = clientsPath + '/' + id + "/budgets";
        navigate(path);
    }

    const goToInvoiceTypes = (id) => {
        let path = clientsPath + '/' + id + "/invoicing";
        navigate(path);
    }

    const goToInvoiceType = (id,invoiceReceiverId)=> {
        let path = clientsPath + '/' + id + "/invoicing";
        navigate(path + '/' + invoiceReceiverId);
    }

    const goToClientProtocol = (id, protocolId) => {
        let path = clientsPath + '/' + id + "/protocols";
        navigate(path + '/' + protocolId);
    }

    const goToClientProtocols = (id) => {
        let path = clientsPath + '/' + id + "/protocols";
        navigate(path);
    }

    const goToTeamCalendarNewEvent = (type) => {
        navigate(teamCalendarPath + '/new-event' + '/' + type);
    }

    const goToTeamCalendarEvent = (id) => {
        navigate(teamCalendarPath + '/events/' + id);
    }

    const goToInstitution = (id) => {
        navigate(institutionsPath + '/' + id);
    }

    const goToClient = (id, tab = null) => {
        let path = clientsPath + '/' + id;
        if (tab) {
            path += '/' + tab;
        } else {
            path += '/general';
        }
        navigate(path);
    }

    const pushInstitutionsParams = (params) => {
        navigate({
            pathname: institutionsPath,
            search: "?" + new URLSearchParams(params).toString(),
        });
    };

    const pushEmployeesParams = (params) => {
        navigate({
            pathname: employeesNewPath,
            search: "?" + new URLSearchParams(params).toString(),
        });
    };

  const pushClientsParams = (params) => {
        navigate({
            pathname: clientsPath,
            search: "?" + new URLSearchParams(params).toString(),
        });
    };

    return {
        getTeamCalendarPath,
        getTeamCalendarEventPath,
        getInstitutionPath,
        getClientsPath,
        getClientRootPath,
        getClientPath,
        getClientTabPath,
        goToInstitutions,
        goToInstitution,
        goToClients,
        goToClient,
        goTo,
        goToLogin,
        goToTeamCalendar,
        goToTeamCalendarNewEvent,
        goToTeamCalendarEvent,
        pushInstitutionsParams,
        pushEmployeesParams,
        goToEmployees,
        goToEmployee,
        pushClientsParams,
        goToClientContact,
        goToClientContacts,
        goToClientBudgets,
        goToClientBudget,
        goToInvoiceTypes,
        goToInvoiceType,
        goToClientProtocols,
        goToClientProtocol,
    }
}


export default useRouting;
