import {Box, Button, Grid, Paper, Typography} from "@mui/material";
import {Trans} from "react-i18next";
import useConfig from "../../hooks/useConfig";

const FormSection = ({ children, title = null, showButtons, editable, onSave, onCancel }) => {
    const {headlineSx} = useConfig();

    return <Paper elevation={0} sx={{marginBottom: 2, padding: 2, maxWidth: 1024}}>

        { title &&
        <Grid item xs={12} sx={headlineSx}>
            <Typography variant="h6">{title}</Typography>
        </Grid>
        }

        <Grid container>
            {children}
        </Grid>

        {showButtons && editable && (
            <Grid container maxWidth="1024px" sx={{marginTop: 3}}>
                <Grid item xs={12}>
                    <Box sx={{padding: 2, display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{
                                marginRight: 2,
                                textTransform: 'none', background: '#fff'
                            }}
                            onClick={onCancel}
                        >
                            <Trans i18nKey="forms.cancel">Cancel</Trans>
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disableElevation
                            sx={{textTransform: 'none'}}
                            onClick={onSave}
                        >
                            <Trans i18nKey="forms.save">Save</Trans>
                        </Button>

                    </Box>
                </Grid>
            </Grid>
        )}
    </Paper>
}

export default FormSection;
