import {
    Autocomplete,
    Box, Breadcrumbs,
    Button,
    CircularProgress, DialogActions, DialogContent, DialogTitle,
    Grid,
    Link, Paper,
    Stack,
    TextField, Typography
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import useApi from "../../hooks/useApi";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import moment from "moment";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {DateTime} from "luxon";
import useRouting from "../../hooks/useRouting";
import "./Institution.css";
import Dialog from '@mui/material/Dialog';;

const Institution = () => {

    const {t} = useTranslation();
    const {id} = useParams();

    const {queryInstitution, queryInstitutions, queryBudgets, saveOrUpdateInstitution} = useApi();
    const {goToInstitution, goToInstitutions} = useRouting();
    const {data: institution = null, isLoading, refetch} = queryInstitution(id);

    const [editable, setEditable] = useState(id === 'new');
    const [disabled, setDisabled] = useState(false);
    const [shortname, setShortname] = useState('');
    const [type, setType] = useState(null);
    const [ikNumber, setIkNumber] = useState('');
    const [description, setDescription] = useState('');
    const [validFrom, setValidFrom] = useState(null);
    const [validUntil, setValidUntil] = useState(null);
    const [errShortname, setErrShortname] = useState(false);
    const [errType, setErrType] = useState(false);
    const [errIkNumber, setErrIkNumber] = useState(false);
    const [errDescription, setErrDescription] = useState(false);
    const [errValidFrom, setErrValidFrom] = useState(false);
    const [errValidUntil, setErrValidUntil] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openPayingAgentModal, setOpenPayingAgentModal] = useState(false);
    const [payingAgents, setPayingAgents] = useState([]);
    const [payingAgent, setPayingAgent] = useState(null);
    const [payingAgentType, setPayingAgentType] = useState(null);
    const [payingAgentBudget, setPayingAgentBudget] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const [addressIndex, setAddressIndex] = useState(null);
    const [street, setStreet] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [budgets, setBudgets] = useState([]);
    const [freeBudgets, setFreeBudgets] = useState([]);
    const [errStreet, setErrStreet] = useState(false);
    const [errZip, setErrZip] = useState(false);
    const [errCity, setErrCity] = useState(false);
    const navigate = useNavigate();
    const {data: allBudgets = null} = queryBudgets();
    const {data: institutions = []} = queryInstitutions(2000, 0);
    const payingAgentTypes = [
        {value: '01', label: t('institutions.paying-agent.type.01', '01')},
        {value: '02', label: t('institutions.paying-agent.type.02', '02')},
        {value: '03', label: t('institutions.paying-agent.type.03', '03')},
        {value: '09', label: t('institutions.paying-agent.type.09', '09')},
    ];
    const types = [
        {value: 'CARE_INSURANCE', label: t('institutions.type.CARE_INSURANCE', 'CARE_INSURANCE')},
        {
            value: 'PRIVATE_CARE_INSURANCE',
            label: t('institutions.type.PRIVATE_CARE_INSURANCE', 'PRIVATE_CARE_INSURANCE')
        },
        {value: 'HEALTH_INSURANCE', label: t('institutions.type.HEALTH_INSURANCE', 'HEALTH_INSURANCE')},
        {
            value: 'PRIVATE_HEALTH_INSURANCE',
            label: t('institutions.type.PRIVATE_HEALTH_INSURANCE', 'PRIVATE_HEALTH_INSURANCE')
        },
        {value: 'PAYMENT_AGENT', label: t('institutions.type.PAYMENT_AGENT', 'PAYMENT_AGENT')},
        {value: 'OTHERS', label: t('institutions.type.OTHERS', 'OTHERS')},
    ];

    const labelSx = {
        padding: 2,
        fontSize: 14,
        fontWeight: 600,
        color: '#6B7280',
        '@media (max-width:600px)': {
            paddingBottom: 0
        }
    }

    const labelMultilineSx = {
        paddingTop: editable ? 1 : 2,
        paddingBottom: editable ? 1 : 2,
        paddingLeft: 2,
        paddingRight: 2,
        fontSize: 14,
        fontWeight: 600,
        color: '#6B7280',
        '@media (max-width:600px)': {
            paddingBottom: 0
        }
    }

    const valueSx = {
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: editable ? 1 : 2,
        paddingBottom: editable ? 1 : 2,
        fontSize: 14,
    }

    const init = () => {
        setShortname(institution.shortname);
        setType(types.find((type) => type.value === institution.type));
        setIkNumber(institution.ik_number ?? '');
        setDescription(institution.description);
        if (institution.valid_from) {
            setValidFrom(DateTime.fromFormat(institution.valid_from, 'yyyy-MM-dd'));
        } else {
            setValidFrom(null);
        }
        if (institution.valid_to) {
            setValidUntil(DateTime.fromFormat(institution.valid_to, 'yyyy-MM-dd'));
        } else {
            setValidUntil(null);
        }
        setPayingAgents(institution.institution_linking_rules);
        setAddresses(institution.addresses);
    }

    useEffect(() => {
        if (institution && institution.id) {
            init();
        }
    }, [institution]);

    const getFreeBudgets = () => {
        let result = [...allBudgets];
        for (let address of addresses) {
            for (let addressBudget of address.budgets) {
                result = result.filter((budget) => budget.id !== addressBudget.id);
            }
        }
        return result;
    }

    useEffect(() => {
        if (allBudgets && addresses) {
            setFreeBudgets(getFreeBudgets());
        }
    }, [allBudgets, addresses]);

    const handleStreetChange = (event) => {
        setStreet(event.target.value);
    }

    const handleZipChange = (event) => {
        setZip(event.target.value);
    }

    const handleCityChange = (event) => {
        setCity(event.target.value);
    }
    const handleGoingBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            goToInstitutions();
        }
    }

    const validateAddress = () => {
        let valid = true;
        if (zip === "") {
            setErrZip(true);
            valid = false;

        } else {
            setErrZip(false);
        }
        if (city === "") {
            setErrCity(true);
            valid = false;

        } else {
            setErrCity(false);
        }
        if (street === "") {
            setErrStreet(true);
            valid = false;

        } else {
            setErrStreet(false);
        }
        return valid;
    }

    const handleAddAddressValues = () => {
        if (validateAddress()) {
            const newAddress = {
                address: {
                    street: street,
                    zip: zip,
                    city: city
                },
                budgets: budgets
            };
            if (addressIndex === null) {
                setAddresses([...addresses, newAddress]);
            } else {
                let addressesCopy = [...addresses];
                addressesCopy[addressIndex] = newAddress;
                setAddresses(addressesCopy);
            }

            setOpenModal(false);
            setStreet('');
            setCity('');
            setZip('');
            setBudgets([]);
        }
    }

    const handlePayingAgentModalClose = () => {
        setOpenPayingAgentModal(false);
    }

    const handleRemovePayingAgent = (index) => {
        let payingAgentsCopy = [...payingAgents];
        payingAgentsCopy.splice(index, 1);
        setPayingAgents(payingAgentsCopy);
    }

    const handleRemoveAddress = (index) => {
        let addressesCopy = [...addresses];
        addressesCopy.splice(index, 1);
        setAddresses(addressesCopy);
    }

    const handleTypeChange = (event) => {
        setType(event.target.value);
        setErrType(false);
    }

    const handleIkNumberChange = (event) => {
        setIkNumber(event.target.value);
        setErrIkNumber(false);
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
        setErrDescription(false);
    }

    const handleShortnameChange = (event) => {
        setShortname(event.target.value);
        setErrShortname(false);
    }
    const handleCancel = () => {
        setEditable(false);
        if (institution && institution.id) {
            init();
        } else {
            goToInstitutions();
            setShortname('');
            setType(null);
            setIkNumber('');
            setDescription('');
            setValidFrom(null);
            setValidUntil(null);
            setAddresses([]);
            setPayingAgents([]);
        }
    };

    const handleAddPayingAgent = () => {
        setPayingAgent(null);
        setPayingAgentType(null);
        setPayingAgentBudget(null);
        setOpenPayingAgentModal(true);
    }

    const handleAddPayingAgentValues = () => {
        if (payingAgent) {
            const newPayingAgent = {
                institution_id: institution.id,
                paying_agent: payingAgent,
                institution_paying_agent_id: payingAgent.id,
                type: payingAgentType !== '' ? payingAgentType.value : null,
                budget_type: payingAgentBudget?.code
            };
            setPayingAgents([...payingAgents, newPayingAgent]);
            setOpenPayingAgentModal(false);
            setPayingAgent(null);
            setPayingAgentType(null);
            setPayingAgentBudget(null);
        }
    }

    const handleAddAddress = () => {
        setAddressIndex(null);
        setCity('');
        setStreet('');
        setZip('');
        setBudgets([]);
        setOpenModal(true);
    };

    const onClickAddress = (institutionAddress, index) => {
        setAddressIndex(index);
        setCity(institutionAddress.address.city);
        setStreet(institutionAddress.address.street);
        setZip(institutionAddress.address.zip);
        setBudgets(institutionAddress.budgets);
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleClosePayingAgentModal = () => {
        setOpenPayingAgentModal(false);
    }

    const validate = () => {
        let valid = true;

        if (type === "") {
            setErrType(true);
            valid = false;
        } else {
            setErrType(false);
        }
        if (shortname === "") {
            setErrShortname(true);
            valid = false;
        } else {
            setErrShortname(false);
        }
        if (validFrom === null) {
            setErrValidFrom(true);
            valid = false;
        } else {
            setErrValidFrom(false);
        }

        return valid;

    }

    const handleSave = () => {
        setDisabled(true);

        if (validate()) {
            let data = {};
            if (id) {
                data.id = id;
            }

            data.shortname = shortname;
            data.type = type.value;
            data.ik_number = parseInt(ikNumber);
            data.description = description;
            if (validFrom) {
                data.valid_from = validFrom.toFormat('yyyy-MM-dd');
            }
            if (validUntil) {
                data.valid_to = validUntil.toFormat('yyyy-MM-dd');
            }
            data.addresses = addresses;
            data.payingAgents = payingAgents;

            saveOrUpdateInstitution(data).then((response) => {

                setDisabled(false);
                setEditable(false);
                if (data.id === 'new' && response.data.id) {
                    goToInstitution(response.data.id);
                } else {
                    refetch();
                }
            }).catch((error) => {
                setDisabled(false);
                if (error.response?.status === 422) {
                    let errors = error.response.data.errors;
                    if (errors.shortname) {
                        setErrShortname(true);
                    }

                    if (errors.type) {
                        setErrType(true);
                    }
                    if (errors.valid_from) {
                        setErrValidFrom(true);
                    }
                }

            });
        } else {
            setDisabled(false);
        }
    };

    return (
        <Box sx={{padding: 2}}>
            {isLoading ?
                <Stack sx={{flexGrow: 1, padding: 2}} alignItems="center">
                    <CircularProgress/>
                </Stack>
                :
                <>
                    <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: 2}}>
                        <NavLink underline="hover" color="inherit" to="/institutions">
                            <Trans i18nKey="institutions.title">Institutions</Trans>
                        </NavLink>
                        <Typography color="text.primary">{institution?.shortname}</Typography>
                    </Breadcrumbs>
                    <Paper elevation={0} sx={{marginBottom: 2, padding: 2, maxWidth: 1024}}>
                        <Grid container alignItems="center">
                            <Grid item xs={8}>
                                <Box sx={{paddingLeft: 2, display: 'flex', justifyContent: 'flex-start'}}>
                                    {editable && institution?.id &&
                                        <Typography variant="h5"><Trans i18nKey={"institutions.edit-title"}>Edit
                                            institution</Trans></Typography>
                                    }
                                    {editable && !institution?.id &&
                                        <Typography variant="h5"><Trans
                                            i18nKey={"institutions.create-title"}>Create
                                            institution</Trans></Typography>
                                    }
                                    {!editable &&
                                        <Typography variant="h5">{institution?.shortname}</Typography>
                                    }

                                </Box>
                            </Grid>
                            <Grid item xs={4} justifyContent="flex-end">
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {!editable &&
                                        <Button
                                            sx={{textTransform: 'none'}}
                                            variant="outlined"
                                            size="small"
                                            disableElevation
                                            onClick={() => setEditable(!editable)}
                                        >
                                            <Trans i18nKey="institutions.edit">Edit</Trans>
                                            {/*<EditOutlinedIcon />*/}
                                        </Button>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={0} sx={{padding: 2, maxWidth: 1024}}>
                        <Grid container alignItems="center">

                            {editable && <>
                                <Grid item xs={12} sm={4} sx={labelSx}>
                                    <Trans i18nKey="institutions.shortname.label">Name</Trans>
                                </Grid>
                                <Grid item xs={12} sm={8} sx={valueSx}>
                                    <TextField
                                        disabled={disabled}
                                        id="shortname"
                                        fullWidth
                                        /*    multiline*/
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={shortname}
                                        placeholder={t('institutions.shortname.placeholder', 'Enter name')}
                                        InputProps={{style: {fontSize: 14, backgroundColor: '#fff'}}}
                                        onChange={handleShortnameChange}
                                        error={errShortname}
                                    />
                                </Grid>
                            </>}

                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey={"institutions.type.label"}>Type</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <Autocomplete
                                        disabled={disabled}
                                        size="small"
                                        value={type}
                                        onChange={(event, newValue) => {
                                            setType(newValue);
                                        }}
                                        options={types}
                                        getOptionLabel={(option) => option.label}
                                        defaultValue={null}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        filterSelectedOptions
                                        sx={{
                                            backgroundColor: '#fff',
                                            fontSize: 14,
                                            '& input': {
                                                fontSize: 14,
                                            },
                                            '.MuiAutocomplete-listbox': {
                                                fontSize: '14px !important',
                                            }
                                        }}
                                        ListboxProps={{
                                            sx: {fontSize: 14},
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={t('institutions.type.placeholder', 'Select type')}
                                            />
                                        )}
                                    />
                                    :
                                    <Trans
                                        i18nKey={"institutions.type." + institution?.type}>{institution?.type}</Trans>
                                }
                            </Grid>

                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="institutions.ik-number.label">IK number</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <TextField
                                        disabled={disabled}
                                        id="ik-number"
                                        fullWidth
                                        /*multiline*/
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={ikNumber}
                                        placeholder={t('institutions.ik-number.placeholder', 'Enter IK number')}
                                        InputProps={{style: {fontSize: 14}}}
                                        sx={{fontSize: 14, backgroundColor: '#fff'}}
                                        onChange={handleIkNumberChange}
                                        error={errIkNumber}
                                    />
                                    :
                                    institution?.ik_number
                                }
                            </Grid>

                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="institutions.description.label">Description</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <TextField
                                        disabled={disabled}
                                        id="description"
                                        fullWidth
                                        /*multiline*/
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={description}
                                        placeholder={t('institutions.description.placeholder', 'Enter description')}
                                        InputProps={{
                                            style: {
                                                fontSize: 14, border: 'none',
                                                outline: 'none',
                                            }
                                        }}
                                        sx={{fontSize: 14, backgroundColor: '#fff'}}
                                        onChange={handleDescriptionChange}
                                        error={errDescription}
                                    />
                                    :
                                    institution?.description
                                }
                            </Grid>

                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="institutions.valid-from.label">Valid from</Trans>
                            </Grid>

                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <DesktopDatePicker
                                        disabled={disabled}
                                        disableToolbar
                                        inputVariant="filled"
                                        format="dd.MM.yyyy"
                                        id="valid-from"
                                        value={validFrom}
                                        placeholder={t('institutions.valid-from.placeholder', 'Enter valid from')}
                                        sx={{
                                            backgroundColor: '#fff', '& input': {
                                                fontSize: 14,
                                            },
                                            '& input::placeholder': {
                                                fontSize: 14
                                            }
                                        }}
                                        onChange={(date) => {
                                            setValidFrom(date)
                                        }}
                                        slotProps={{textField: {size: 'small', error: errValidFrom}}}
                                        renderInput={(params) => <TextField variant="filled" {...params}
                                                                            inputProps={
                                                                                {
                                                                                    ...params.inputProps,
                                                                                    placeholder: "dd.MM.yyyy"
                                                                                }
                                                                            } placeholder="dd.MM.yyyy"/>}
                                    />
                                    :
                                    institution?.valid_from ? t('institutions.valid-from.date', {date: moment(institution?.valid_from).toDate()}) : '-'
                                }
                            </Grid>

                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="institutions.valid-until.label">Valid until</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <DesktopDatePicker
                                        disabled={disabled}
                                        disableToolbar
                                        inputVariant="filled"
                                        format="dd.MM.yyyy"
                                        id="valid-until"
                                        value={validUntil}
                                        placeholder={t('institutions.valid-until.placeholder', 'Enter valid until')}
                                        sx={{
                                            backgroundColor: '#fff', '& input': {
                                                fontSize: 14,
                                            },
                                            '& input::placeholder': {
                                                fontSize: 14
                                            }
                                        }}
                                        onChange={(date) => {
                                            setValidUntil(date)
                                        }}
                                        slotProps={{textField: {size: 'small', error: errValidUntil}}}
                                        renderInput={(params) => <TextField variant="filled" {...params}
                                                                            error={errValidUntil} inputProps={
                                            {
                                                ...params.inputProps,
                                                placeholder: "dd.MM.yyyy"
                                            }
                                        } placeholder="dd.MM.yyyy"/>}
                                    />
                                    :
                                    institution?.valid_to ? t('institutions.valid-until.date', {date: moment(institution?.valid_to).toDate()}) : '-'
                                }
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start">
                            <Grid item xs={12} sm={4} sx={labelMultilineSx}>
                                <Trans i18nKey="institutions.paying-agents">Paying agents</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {payingAgents.length > 0 ?
                                    <Box sx={{marginBottom: '11px'}}>
                                        {payingAgents.map((linking_rule, index) => {
                                            return (
                                                <Box sx={{fontSize: 14}} key={'linking_rule-' + index}>
                                                    <Link
                                                        href={'/institutions/' + linking_rule.paying_agent.id}>{linking_rule.paying_agent.shortname} ({linking_rule.paying_agent.ik_number})
                                                        {linking_rule.type && <>- {t('institutions.paying-agent.type.' + linking_rule.type, linking_rule.type)}</>}</Link>
                                                    {editable && (
                                                        <button
                                                            className="text-red-500 hover:text-red-700"
                                                            style={{marginLeft: "10px", verticalAlign: 'middle'}}
                                                            onClick={() => handleRemovePayingAgent(index)}
                                                        >
                                                            <svg className="h-4 w-4" fill="currentColor"
                                                                 viewBox="0 0 20 20">
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M14.293 5.293a1 1 0 0 0-1.414 0L10 8.586 6.707 5.293a1 1 0 0 0-1.414 1.414L8.586 10l-3.293 3.293a1 1 0 1 0 1.414 1.414L10 11.414l3.293 3.293a1 1 0 0 0 1.414-1.414L11.414 10l3.293-3.293a1 1 0 0 0 0-1.414z"
                                                                />
                                                            </svg>
                                                        </button>
                                                    )}
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                    :
                                    !editable && "-"
                                }
                                {editable && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        sx={{
                                            backgroundColor: '#fff',
                                            textTransform: 'none'
                                        }}
                                        onClick={handleAddPayingAgent}
                                    >
                                        <Trans i18nKey="institutions.new-paying-agent">Add paying agent</Trans>
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={4} sx={labelMultilineSx} alignItems="flex-start">
                                <Box sx={{padding: 0}}><Trans i18nKey="institutions.addresses">Addresses</Trans></Box>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {addresses.length > 0 ?
                                    <Box sx={{marginBottom: '11px'}}>
                                        {addresses.map((instituteAddress, index) => {
                                            return (
                                                <Box sx={{fontSize: 14}}
                                                     key={'address-' + index}>
                                                    {editable ?
                                                        <Link component="button"
                                                              onClick={() => onClickAddress(instituteAddress, index)}>{instituteAddress.address.street} {instituteAddress.address.zip} - {instituteAddress.address.city}</Link>
                                                        :
                                                        <div>{instituteAddress.address.street} - {instituteAddress.address.zip} {instituteAddress.address.city}</div>
                                                    }
                                                    {editable && (
                                                        <button
                                                            className="text-red-500 hover:text-red-700"
                                                            style={{marginLeft: "10px", verticalAlign: 'middle'}}
                                                            onClick={() => handleRemoveAddress(index)}
                                                        >
                                                            <svg className="h-4 w-4" fill="currentColor"
                                                                 viewBox="0 0 20 20">
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M14.293 5.293a1 1 0 0 0-1.414 0L10 8.586 6.707 5.293a1 1 0 0 0-1.414 1.414L8.586 10l-3.293 3.293a1 1 0 1 0 1.414 1.414L10 11.414l3.293 3.293a1 1 0 0 0 1.414-1.414L11.414 10l3.293-3.293a1 1 0 0 0 0-1.414z"
                                                                />
                                                            </svg>
                                                        </button>
                                                    )}

                                                </Box>
                                            );
                                        })
                                        }
                                    </Box>
                                    :
                                    !editable && "-"
                                }
                                {editable && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        sx={{
                                            backgroundColor: '#fff',
                                            textTransform: 'none'
                                        }}
                                        onClick={handleAddAddress}
                                    >
                                        <Trans i18nKey="institutions.institutions-modal.new-address">Add new
                                            address</Trans>
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                        <Dialog
                            open={openModal}
                            onClose={handleCloseModal}
                            maxWidth={'sm'}
                            fullWidth={true}
                        >
                            <DialogTitle><Trans i18nKey="institutions.institutions-modal.new-address">New
                                Address</Trans></DialogTitle>
                            <DialogContent dividers>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={4} sx={{fontSize: 14, paddingTop: 2, paddingBottom: 2}}>
                                        <Trans i18nKey="institutions.institutions-modal.street">Street</Trans>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <TextField
                                            error={errStreet}
                                            size="small"
                                            id="street"
                                            name="street"
                                            value={street}
                                            fullWidth
                                            onChange={handleStreetChange}
                                            inputProps={{style: {fontSize: 14}}}
                                            placeholder={t('institutions.institutions-modal.enter-street')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} sx={{fontSize: 14, paddingTop: 2, paddingBottom: 2}}>
                                        <Trans i18nKey="institutions.institutions-modal.zip">Zip</Trans>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <TextField
                                            error={errZip}
                                            size="small"
                                            id="zip"
                                            name="zip"
                                            value={zip}
                                            fullWidth
                                            onChange={handleZipChange}
                                            inputProps={{style: {fontSize: 14}}}
                                            placeholder={t('institutions.institutions-modal.enter-zip')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} sx={{fontSize: 14, paddingTop: 2, paddingBottom: 2}}>
                                        <Trans i18nKey="institutions.institutions-modal.city">City</Trans>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <TextField
                                            error={errCity}
                                            size="small"
                                            id="city"
                                            name="city"
                                            value={city}
                                            fullWidth
                                            onChange={handleCityChange}
                                            inputProps={{style: {fontSize: 14}}}
                                            placeholder={t('institutions.institutions-modal.enter-city')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} sx={{fontSize: 14, paddingTop: 2, paddingBottom: 2}}>
                                        <Trans i18nKey="institutions.institutions-modal.budgets">Budgets</Trans>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        {allBudgets &&
                                            <Autocomplete
                                                multiple
                                                size="small"
                                                id="tags-outlined"
                                                value={budgets}
                                                onChange={(event, newValue) => {
                                                    setBudgets(newValue);
                                                }}
                                                options={freeBudgets}
                                                getOptionLabel={(option) => option.name}
                                                defaultValue={[]}
                                                filterSelectedOptions
                                                sx={{
                                                    fontSize: 14,
                                                    '& input': {
                                                        fontSize: 14,
                                                    },
                                                    '.MuiAutocomplete-listbox': {
                                                        fontSize: '14px !important',
                                                    }
                                                }}
                                                ListboxProps={{
                                                    sx: {fontSize: 14},
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder={t('institutions.institutions-modal.select-budgets', 'Select budgets')}
                                                    />
                                                )}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    sx={{textTransform: 'none', width: "93px", height: "34px", marginRight: 0}}
                                    onClick={handleCloseModal}
                                >
                                    <Trans i18nKey="institutions.institutions-modal.cancel">Cancel</Trans>
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    disableElevation
                                    sx={{textTransform: 'none', width: "93px", height: "34px", marginRight: 0}}
                                    onClick={handleAddAddressValues}
                                >
                                    <Trans i18nKey="institutions.institutions-modal.add">Add</Trans>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={openPayingAgentModal}
                            onClose={handlePayingAgentModalClose}
                            maxWidth={'sm'}
                            fullWidth={true}
                        >
                            <DialogTitle><Trans i18nKey="institutions.paying-agent-modal.title">Paying
                                agent</Trans></DialogTitle>
                            <DialogContent dividers>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={4} sx={{fontSize: 14, paddingTop: 2, paddingBottom: 2}}>
                                        <Trans i18nKey="institutions.paying-agent-modal.paying-agent.label">Paying
                                            agent</Trans>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Autocomplete
                                            size="small"
                                            value={payingAgent}
                                            onChange={(event, newValue) => {
                                                setPayingAgent(newValue);
                                            }}
                                            options={institutions.data ?? []}
                                            getOptionLabel={(option) => option.shortname + ' (' + option.ik_number + ')'}
                                            defaultValue={null}
                                            filterSelectedOptions
                                            sx={{
                                                fontSize: 14,
                                                '& input': {
                                                    fontSize: 14,
                                                },
                                                '.MuiAutocomplete-listbox': {
                                                    fontSize: '14px !important',
                                                }
                                            }}
                                            ListboxProps={{
                                                sx: {fontSize: 14},
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder={t('institutions.paying-agent-modal.paying-agent.placeholder', 'Select institution')}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} sx={{fontSize: 14, paddingTop: 2, paddingBottom: 2}}>
                                        <Trans i18nKey="institutions.paying-agent-modal.type.label">Type</Trans>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Autocomplete
                                            size="small"
                                            value={payingAgentType}
                                            onChange={(event, newValue) => {
                                                setPayingAgentType(newValue);
                                            }}
                                            options={payingAgentTypes}
                                            getOptionLabel={(option) => option.label}
                                            defaultValue={null}
                                            filterSelectedOptions
                                            sx={{
                                                fontSize: 14,
                                                '& input': {
                                                    fontSize: 14,
                                                    backgroundColor: '#fff'
                                                },
                                                '.MuiAutocomplete-listbox': {
                                                    fontSize: '14px !important',
                                                }
                                            }}
                                            ListboxProps={{
                                                sx: {fontSize: 14},
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder={t('institutions.paying-agent-modal.type.placeholder', 'Select type')}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} sx={{fontSize: 14, paddingTop: 2, paddingBottom: 2}}>
                                        <Trans i18nKey="institutions.paying-agent-modal.budget.label">Budget</Trans>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        {allBudgets &&
                                            <Autocomplete
                                                size="small"
                                                value={payingAgentBudget}
                                                onChange={(event, newValue) => {
                                                    setPayingAgentBudget(newValue);
                                                }}
                                                options={allBudgets}
                                                getOptionLabel={(option) => option.name}
                                                defaultValue={null}
                                                filterSelectedOptions
                                                sx={{
                                                    fontSize: 14,
                                                    '& input': {
                                                        fontSize: 14,
                                                    },
                                                    '.MuiAutocomplete-listbox': {
                                                        fontSize: '14px !important',
                                                    }
                                                }}
                                                ListboxProps={{
                                                    sx: {fontSize: 14},
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder={t('institutions.paying-agent-modal.budget.placeholder', 'Select budgets')}
                                                    />
                                                )}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    sx={{textTransform: 'none', marginRight: 0}}
                                    onClick={handleClosePayingAgentModal}
                                >
                                    <Trans i18nKey="institutions.paying-agent-modal.cancel">Cancel</Trans>
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    sx={{textTransform: 'none', marginRight: 0}}
                                    onClick={handleAddPayingAgentValues}
                                >
                                    <Trans i18nKey="institutions.paying-agent-modal.add">Add</Trans>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {editable && (
                            <Grid container maxWidth="1024px">
                                <Grid item xs={12}>
                                    <Box sx={{padding: 2, display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                marginRight: 2,
                                                textTransform: 'none', background: '#fff'
                                            }}
                                            onClick={handleCancel}
                                        >
                                            <Trans i18nKey="institutions.cancel">Cancel</Trans>
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            disableElevation
                                            sx={{textTransform: 'none'}}
                                            onClick={handleSave}
                                        >
                                            <Trans i18nKey="institutions.save">Save</Trans>
                                        </Button>

                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Paper>
                </>
            }
        </Box>
    )
}

export default Institution;
