import useApi from "../../hooks/useApi";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React from "react";
import {Paper} from "@mui/material";
import FormTable from ".././forms/FormTable";
import useRouting from "../../hooks/useRouting";
import moment from "moment";


const ClientProtocols = (props) => {
    const isSmallScreen = window.innerWidth < 600;
    const [columnVisibility, setColumnVisibility] = React.useState({
        address: !isSmallScreen,
        email: !isSmallScreen,
        phone: !isSmallScreen,
    });
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {queryClientProtocols} = useApi();
    const routing = useRouting();

    const [columns, setColumns] = React.useState([
        {
            field: 'content',
            headerName: 'Beschreibung',
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header'
        },
        {
            field: 'created_at',
            headerName: t('clients.protocols.created-at.label', 'Created at'),
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header',
            renderCell: (params) => {
                return t('clients.protocols.created-at.date-time', {date: moment(params.row.created_at).toDate()})
            },
        }
    ]);
    const [sortModel, setSortModel] = React.useState(searchParams.get('sortField') && searchParams.get('sortOrder') ? [{
        field: searchParams.get('sortField'),
        sort: searchParams.get('sortOrder')
    }] : []);
    const [searchText, setSearchText] = React.useState(searchParams.get('search') ?? '');
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize')) : props.paginationModel.pageSize,
        page: searchParams.get('page') ? parseInt(searchParams.get('page')) : props.paginationModel.page,
    });

    const {data: clientProtocols = null, isLoading} = queryClientProtocols(
        props.client.id,
        paginationModel.pageSize,
        paginationModel.page + 1,
        sortModel.length > 0 ? sortModel[0].field : null,
        sortModel.length > 0 ? sortModel[0].sort : null,
        searchText);
     //console.log(clientBudgets);


    const handleRowClick = (params) => {
        const protocolId = params.row?.id;
        routing.goToClientProtocol(props.client.id, protocolId);
    }
    const handleNewClientContactClick = (event) => {
        const protocolId = 'new';
        routing.goToClientProtocol(props.client.id, protocolId);
    }

    const handleScreenSizeChange = (isSmallScreen) => {
        let newColumnVisibility = {
            ...columnVisibility,
        }
        setColumnVisibility(newColumnVisibility);
    }

    return (
        <Paper elevation={0} sx={{marginBottom: 2, padding: 2, maxWidth: 1024}}>
            <FormTable
                createButtonLabel={t("clients.protocols.create-new-protocol", "New Protocol")}
                columns={columns}
                data={clientProtocols}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                onSearchTextChange={setSearchText}
                onRowClick={handleRowClick}
                onNewClick={handleNewClientContactClick}
                sortModel={sortModel}
                setSortModel={setSortModel}
                isLoading={isLoading}
                onScreenSizeChange={handleScreenSizeChange}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                createPermissions={['clients', 'client', 'client.budgets', 'client.budgets.update']}
            />
        </Paper>
    );
}

export default ClientProtocols;
