import {Box, CircularProgress, Divider, Paper, Typography} from "@mui/material";
import {Trans} from "react-i18next";
import useApi from "../../hooks/useApi";
import {grey} from "@mui/material/colors";
import useRouting from "../../hooks/useRouting";

const ClientsWithoutEvents = () => {
    const routing = useRouting();
    const {queryClientsWithoutEvents} = useApi();
    const {data: clientsWithoutEvents, isLoading} = queryClientsWithoutEvents();

    const handleClientClick = (id) => {
        window.location = routing.getClientPath(id);
    }
    return <Paper elevation={0} sx={{height: '400px'}}>
        <Box sx={{padding: 2}}>
            <Typography variant="h6"><Trans i18nKey="dashboard.clients-without-events.title">Clients without
                events</Trans></Typography>
        </Box>
        <Divider/>
        {isLoading ?
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '300px',
            }}>
                <CircularProgress/>
            </Box>
            :
            <Box sx={{padding: 2, overflow: 'auto', height: '340px'}}>
                {clientsWithoutEvents?.length > 0 ? clientsWithoutEvents.map((client, index) => {
                        return <Box key={index} sx={{marginBottom: 2, cursor: 'pointer'}} onClick={() => handleClientClick(client.id)}>
                            <Typography variant="body1">{client.person?.last_name}, {client.person?.first_name}</Typography>
                            <Typography color={grey.A700} variant="body1">{client.person?.address?.street}</Typography>
                        </Box>
                    })
                    :
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '280px',
                    }}>
                        <Typography variant="body1"><Trans i18nKey="dashboard.clients-without-events.no-clients">No
                            clients without events</Trans></Typography>
                    </Box>
                }
            </Box>
        }
    </Paper>
}

export default ClientsWithoutEvents;
