import React, {Fragment, useEffect, useRef, useState} from "react";
import { Menu, Transition } from "@headlessui/react";

import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import useApi from "../hooks/useApi";
import {Trans} from "react-i18next";
import moment from "moment/moment";

const GridDropdown = ({gridHandler, className}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [gridType, setGridType] = useState(moment().isoWeekday() === 6 || moment().isoWeekday() ? 'week' : 'five-days');
    const buttonRef = useRef(null);
    const menuRef = useRef(null);

    const handleClickOutside = (event) => {
        if (buttonRef.current && !buttonRef.current.contains(event.target) &&
            menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleDropdownButtonClick = (event) => {
        event.stopPropagation();
        setIsOpen(!isOpen);
    };

    const handleGridButtonClick = (event, gridType) => {
        event.preventDefault();
        setGridType(gridType);
        setIsOpen(false);
        gridHandler(gridType);
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Menu as="div" className={"relative inline-block text-left " + className} style={{ zIndex: 8 }}>
            {({ open }) => (
                <>
                    <div ref={buttonRef}>
                        <Menu.Button
                            className="inline-flex justify-center items-center w-full pl-4 py-0 text-sm font-medium text-gray-400 bg-white rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="md:hidden w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                            </svg>

                            {/*<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"*/}
                            {/*     stroke="currentColor" className="md:hidden w-5 h-5">*/}
                            {/*    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>*/}
                            {/*</svg>*/}

                            <div className="hidden md:block text-gray-700"><Trans i18nKey={"team-calendar.grid-types."+gridType}>Grid type</Trans></div>
                            <div
                                className="flex items-center ml-2 -mr-1 h-9 w-9"
                                onClick={handleDropdownButtonClick}
                            >
                            <ChevronDownIcon className="h-5 w-5" />
                            </div>
                        </Menu.Button>
                    </div>

                    <Transition
                        show={isOpen}
                        enter="transition ease-out duration-100 transform"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-75 transform"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Menu.Items
                            ref={menuRef}
                            static
                            className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                            <div className="py-1">
                                <Menu.Item key='list-grid'>
                                    <a
                                        href="#"
                                        className={"text-gray-700 flex items-center px-4 py-2 text-sm"}
                                        onClick={(event) => handleGridButtonClick(event, 'list')}
                                    >
                                        <Trans i18nKey={"team-calendar.grid-types.list"}>List</Trans>
                                    </a>
                                </Menu.Item>
                                <Menu.Item key='single-day-grid'>
                                    <a
                                        href="#"
                                        className={"text-gray-700 flex items-center px-4 py-2 text-sm"}
                                        onClick={(event) => handleGridButtonClick(event, 'single-day')}
                                    >
                                        <Trans i18nKey={"team-calendar.grid-types.single-day"}>Single day</Trans>
                                    </a>
                                </Menu.Item>
                                <Menu.Item key='five-days-grid'>
                                        <a
                                            href="#"
                                            className={"text-gray-700 flex items-center px-4 py-2 text-sm"}
                                            onClick={(event) => handleGridButtonClick(event, 'five-days')}
                                        >
                                            <Trans i18nKey={"team-calendar.grid-types.five-days"}>Five days</Trans>
                                        </a>
                                </Menu.Item>
                                <Menu.Item key='week-grid'>
                                    <a
                                        href="#"
                                        className={"text-gray-700 flex items-center px-4 py-2 text-sm"}
                                        onClick={(event) => handleGridButtonClick(event, 'week')}
                                    >
                                        <Trans i18nKey={"team-calendar.grid-types.week"}>Week</Trans>
                                    </a>
                                </Menu.Item>
                                <Menu.Item key='month-grid'>
                                    <a
                                        href="#"
                                        className={"text-gray-700 flex items-center px-4 py-2 text-sm"}
                                        onClick={(event) => handleGridButtonClick(event, 'month')}
                                    >
                                        <Trans i18nKey={"team-calendar.grid-types.month"}>Month</Trans>
                                    </a>
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
};

export default GridDropdown;
