import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    CircularProgress, FormControl,
    Grid, InputAdornment,
    MenuItem, OutlinedInput,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import useApi from "../../hooks/useApi";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import useRouting from "../../hooks/useRouting";
import FormSection from "../forms/FormSection";
import FormTextField from "../forms/FormTextField";
import FormSelect from "../forms/FormSelect";

const ClientContact = (props) => {

    const {t} = useTranslation();
    const {id} = useParams();
    const {contactId} = useParams();
    const routing = useRouting();
    //console.log(contactId);
    const {queryClient, queryClientContact, saveOrUpdateClientContact} = useApi();
    const {goToClientContacts, goToClientContact} = useRouting();

    const {data: client = null} = queryClient(id);
    const {data: clientContact = null, isLoading, refetch} = queryClientContact(id,contactId);
   //console.log(clientContact);


    const [editable, setEditable] = useState(contactId === 'new');

    const [disabled, setDisabled] = useState(false);
    const [title, setTitle] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [type, setType] = useState('relative_contact');
    const [typeNote, setTypeNote] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('x');
    const [street, setStreet] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [countryCode, setCountryCode] = useState('DE');
    const [phone, setPhone] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');

    const [errStreet, setErrStreet] = useState(false);
    const [errStreetNumber, setErrStreetNumber] = useState(false);
    const [errZip, setErrZip] = useState(false);
    const [errCity, setErrCity] = useState(false);
    const navigate = useNavigate();
    const [errFirstName, setErrFirstName] = useState(false);
    const [errLastName, setErrLastName] = useState(false);
    const [errPhone, setErrPhone] = useState(false);
    const [errGender, setErrGender] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [errType, setErrType] = useState(false);
    const [showValidation, setShowValidation] = useState(false);

    const employeeQualifications = {
        SPECIALIST: 'specialist',
        NON_SPECIALIST: 'non_specialist',
        OTHER: 'other',
    };


    const labelSx = {
        padding: 2,
        fontSize: 14,
        fontWeight: 600,
        color: '#6B7280',
        '@media (max-width:600px)': {
            paddingBottom: 0
        }
    }

    const labelMultilineSx = {
        paddingTop: editable ? 1 : 2,
        paddingBottom: editable ? 1 : 2,
        paddingLeft: 2,
        paddingRight: 2,
        fontSize: 14,
        fontWeight: 600,
        color: '#6B7280',
        '@media (max-width:600px)': {
            paddingBottom: 0
        }
    }

    const valueSx = {
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: editable ? 1 : 2,
        paddingBottom: editable ? 1 : 2,
        fontSize: 14,
    }

    const init = () => {
        if (clientContact?.data.person.title) {
            setTitle(clientContact?.data.person.title);
        } else {
            setTitle("");
        }

        setFirstName(clientContact?.data.person.first_name);
        setLastName(clientContact?.data.person.last_name);
        setGender(clientContact?.data.person.gender);
        setType(clientContact?.data.type);

        if (clientContact.data.phone) {
            setCountryCode(clientContact?.data.phone.country_code);
            setPhone(clientContact?.data.phone.phone_number);
        } else {
            setCountryCode("DE");
            setPhone("");
        }

        if (clientContact?.data.person.email) {
            setEmail(clientContact?.data.person.email);
        } else {
            setEmail("");
        }

        setStreet(clientContact?.data.address.street);
        setStreetNumber(clientContact?.data.address.street_number);
        setZip(clientContact?.data.address.zip);
        setCity(clientContact?.data.address.city);
        if (clientContact.data.type_note) {
            setAdditionalInfo(clientContact?.data.type_note);
        } else {
            setAdditionalInfo("-");
        }


    }

    useEffect(() => {
        if (clientContact && clientContact?.data?.id) {
            init();
        }
    }, [clientContact]);


    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setErrEmail(false);
    }
    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
        setErrFirstName(false);
    }
    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
        setErrLastName(false);
    }

    const handleGenderChange = (event) => {
        setGender(event.target.value);
        setErrGender(false);
    }

    const handleTypeChange = (event) => {
        setType(event.target.value);
        setErrType(false);
    }


    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
    };
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
        setErrPhone(false);
    };

    const handleStreetChange = (event) => {
        setStreet(event.target.value);
        setErrStreet(false);
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
        setErrCity(false);
    };

    const handleZipChange = (event) => {
        setZip(event.target.value);
        setErrZip(false);
    };

    const handleStreetNumberChange = (event) => {
        setStreetNumber(event.target.value);
        setErrStreetNumber(false);
    };

    const handleAdditionalInfoChange = (event) => {
        setAdditionalInfo(event.target.value);
    };
    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleCancel = () => {
        setEditable(false);
        if (clientContact && clientContact?.data?.id) {
            init();
        } else {
            goToClientContacts(id);
        }
    };


    const validate = () => {
        let valid = true;

        if (email === '') {
            setErrEmail(true);
            valid = false;
        } else {
            setErrEmail(false);
        }

        if (firstName === '') {
            setErrFirstName(true);
            valid = false;
        } else {
            setErrFirstName(false);
        }

        if (lastName === '') {
            setErrLastName(true);
            valid = false;
        } else {
            setErrLastName(false);
        }

        if (streetNumber === '') {
            setErrStreetNumber(true);
            valid = false;
        } else {
            setErrStreetNumber(false);
        }
        if (street === '') {
            setErrStreet(true);
            valid = false;
        } else {
            setErrStreet(false);
        }
        if (zip === '') {
            setErrZip(true);
            valid = false;
        } else {
            setErrZip(false);
        }
        if (phone === '') {
            setErrPhone(true);
            valid = false;
        } else {
            setErrPhone(false);
        }
        if (city === '') {
            setErrCity(true);
            valid = false;
        } else {
            setErrCity(false);
        }

        return valid;

    }
    const handleSave = () => {
        setDisabled(true);

        if (validate()) {
            let data = {};
            if (contactId && contactId !== "new") {
                data.id = contactId;
            }
            data.client_id = id;
            data.type = type;
            data.type_note = typeNote;
            data.email = email;
           // data.title = title;
            data.first_name = firstName;
            data.last_name = lastName;
            data.gender = gender;
            data.title = title;
            data.street = street;
            data.street_number = streetNumber;
            data.zip = zip;
            data.city = city;
            data.phone_country = countryCode;
            data.phone = phone;
            data.type_note = additionalInfo;
            saveOrUpdateClientContact(id, data).then((response) => {
                //console.log(response);
                setDisabled(false);
                setEditable(false);
                if (response.data.id) {
                    goToClientContact(id,response.data.id);
                } else {
                    refetch();
                }
            }).catch((error) => {
                setDisabled(false);
                if (error.response?.status === 422 || error.response?.status === 500) {
                    let errors = error.response.data.errors;
                    if (errors.first_name) {
                        setErrFirstName(true);
                    }
                    if (errors.phone) {
                        setErrPhone(true);
                    }
                    if (errors.email) {
                        setErrEmail(true);
                    }
                }

            });
        } else {
            setShowValidation(true);
            setDisabled(false);
            console.log('validation error');
        }
    };

    return (
        <Box sx={{padding: 2}}>
            {isLoading ?
                <Stack sx={{flexGrow: 1, padding: 2}} alignItems="center">
                    <CircularProgress/>
                </Stack>
                :
                <>
                    <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: 2}}>
                        <NavLink underline="hover" color="inherit" to="/clients">
                            <Trans i18nKey="clients.header">Clients</Trans>
                        </NavLink>
                        <NavLink color="text.primary" underline="hover" to={routing.getClientPath(id)}>
                            <Typography color="text.primary">{client?.person.last_name}, {client?.person.first_name}</Typography></NavLink>
                        <NavLink underline="hover" color="inherit"
                                 to={routing.getClientTabPath(id, 'contacts')}>
                            <Trans i18nKey="clients.contacts.header">contacts</Trans>
                        </NavLink>
                        <Typography
                            color="text.primary">{clientContact?.data ? clientContact.data.person.last_name + " " + clientContact.data.person.first_name : ""}</Typography>
                    </Breadcrumbs>
                    <Paper elevation={0} sx={{marginBottom: 2, padding: 2, maxWidth: 1024}}>
                        <Grid container alignItems="center">
                            <Grid item xs={8}>
                                <Box sx={{paddingLeft: 2, display: 'flex', justifyContent: 'flex-start'}}>
                                    {editable && clientContact?.data?.id &&
                                        <Typography variant="h5"><Trans i18nKey={"clients.contacts.edit-title"}>Edit
                                            Contact</Trans></Typography>
                                    }
                                    {editable && !clientContact?.data &&
                                        <Typography variant="h5"><Trans
                                            i18nKey={"clients.contacts.create-new-contact"}>Create new Contacts
                                        </Trans></Typography>
                                    }
                                    {!editable &&
                                        <Typography
                                            variant="h5">{clientContact?.data?.person ? clientContact.data.person.last_name + " " + clientContact.data.person.first_name : ""}</Typography>
                                    }

                                </Box>
                            </Grid>
                            <Grid item xs={4} justifyContent="flex-end">
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {!editable &&
                                        <Button
                                            sx={{textTransform: 'none'}}
                                            variant="outlined"
                                            size="small"
                                            disableElevation
                                            onClick={() => {
                                                setEditable(!editable)
                                            }}
                                        >
                                            <Trans i18nKey="employee.edit">Edit</Trans>
                                        </Button>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                    <FormSection
                        editable={editable}
                        title={t('clients.master-data', 'Master data')}
                    >

                        <FormTextField
                            value={firstName}
                            editable={editable}
                            onChange={handleFirstNameChange}
                            i18nKeyLabel="employee.first-name.label"
                            i18nKeyPlaceholder="employee.first-name.placeholder"
                            valueText={firstName}
                            disabled={disabled}
                            showValidation={showValidation}
                            error={errFirstName}/>

                        <FormTextField
                            value={lastName}
                            editable={editable}
                            onChange={handleLastNameChange}
                            i18nKeyLabel="employee.last-name.label"
                            i18nKeyPlaceholder="employee.last-name.placeholder"
                            valueText={lastName}
                            disabled={disabled}
                            showValidation={showValidation}
                            error={errLastName}/>

                        <FormSelect
                            i18nKeyLabel="clients.gender.label"
                            i18nKeyPlaceholder="clients.gender.placeholder"
                            editable={editable}
                            valueText={gender ? t('clients.gender.' + clientContact?.data?.person.gender, clientContact?.data?.person.gender) : '-'}
                            value={gender}
                            onChange={handleGenderChange}
                            disabled={disabled}
                            showValidation={showValidation}
                            required={false}
                            displayEmpty={true}>
                            <MenuItem sx={{fontSize: 14}} value={'m'}><Trans
                                i18nKey="clients.gender.m"></Trans></MenuItem>
                            <MenuItem sx={{fontSize: 14}} value={'f'}><Trans
                                i18nKey="clients.gender.f"></Trans></MenuItem>
                            <MenuItem sx={{fontSize: 14}} value={'x'}><Trans
                                i18nKey="clients.gender.x"></Trans></MenuItem>
                        </FormSelect>

                        <FormSelect
                            i18nKeyLabel="clients.contacts.type.label"
                            i18nKeyPlaceholder="clients.contacts.type.placeholder"
                            editable={editable}
                            valueText={type ? t('clients.contacts.type.' + clientContact?.data?.type, clientContact?.data?.type) : '-'}
                            value={type}
                            onChange={handleTypeChange}
                            disabled={disabled}
                            showValidation={showValidation}
                            required={false}
                            displayEmpty={true}>
                            <MenuItem sx={{fontSize: 14}} value={'emergency_contact'}><Trans
                                i18nKey="clients.contacts.type.emergency_contact"></Trans></MenuItem>
                            <MenuItem sx={{fontSize: 14}} value={'relative_contact'}><Trans
                                i18nKey="clients.contacts.type.relative_contact"></Trans></MenuItem>
                            <MenuItem sx={{fontSize: 14}} value={'aid_grant_contact'}><Trans
                                i18nKey="clients.contacts.type.aid_grant_contact"></Trans></MenuItem>
                        </FormSelect>

                        <FormTextField
                            value={additionalInfo}
                            editable={editable}
                            onChange={handleAdditionalInfoChange}
                            i18nKeyLabel="clients.contacts.info.label"
                            i18nKeyPlaceholder="clients.contacts.info.placeholder"
                            valueText={additionalInfo}
                            disabled={disabled}
                            showValidation={showValidation}
                        />


                        <Grid item xs={12} sm={4} sx={labelSx}>
                            <Trans i18nKey="clients.phone.label">Phone</Trans>
                        </Grid>
                        <Grid item xs={12} sm={8} sx={valueSx}>
                            {editable ?
                                <OutlinedInput
                                    disabled={disabled}
                                    id="phone"
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    value={phone}
                                    placeholder={t('clients.phone.placeholder', 'Enter phone number')}
                                    sx={{fontSize: 14, backgroundColor: '#fff'}}
                                    onChange={handlePhoneChange}
                                    error={errPhone}
                                    startAdornment={
                                        <InputAdornment
                                            position="start">
                                            <FormControl
                                                size='small'>
                                                <Select sx={{fontSize: 14}} value={countryCode}
                                                        onChange={handleCountryCodeChange}>
                                                    <MenuItem value={'DE'}>DE</MenuItem>
                                                    <MenuItem value={'AT'}>AT</MenuItem>
                                                    <MenuItem value={'CH'}>CH</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </InputAdornment>
                                    }
                                />
                                :
                                clientContact?.data?.phone ? clientContact.data.phone.phone_number : '-'
                            }
                        </Grid>

                        <FormTextField
                            value={email}
                            editable={editable}
                            onChange={handleEmailChange}
                            i18nKeyLabel="clients.email.label"
                            i18nKeyPlaceholder="clients.email.placeholder"
                            valueText={email}
                            disabled={disabled}
                            showValidation={showValidation}
                            error={errEmail}
                        />

                        <FormTextField
                            value={street}
                            editable={editable}
                            onChange={handleStreetChange}
                            i18nKeyLabel="clients.street.label"
                            i18nKeyPlaceholder="clients.street.placeholder"
                            valueText={street}
                            disabled={disabled}
                            showValidation={showValidation}
                            error={errStreet}/>

                        <FormTextField
                            value={streetNumber}
                            editable={editable}
                            onChange={handleStreetNumberChange}
                            i18nKeyLabel="clients.street-number.label"
                            i18nKeyPlaceholder="clients.street-number.placeholder"
                            valueText={streetNumber}
                            disabled={disabled}
                            showValidation={showValidation}
                            error={errStreetNumber}/>

                        <FormTextField
                            value={zip}
                            editable={editable}
                            onChange={handleZipChange}
                            i18nKeyLabel="clients.zip.label"
                            i18nKeyPlaceholder="clients.zip.placeholder"
                            valueText={zip}
                            disabled={disabled}
                            showValidation={showValidation}
                            error={errZip}/>

                        <FormTextField
                            value={city}
                            editable={editable}
                            onChange={handleCityChange}
                            i18nKeyLabel="clients.city.label"
                            i18nKeyPlaceholder="clients.city.placeholder"
                            valueText={city}
                            disabled={disabled}
                            showValidation={showValidation}
                            error={errCity}/>

                        {editable && (
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        paddingRight: 2,
                                        paddingTop: 2,
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                marginRight: 2,
                                                textTransform: 'none', background: '#fff'
                                            }}
                                            onClick={handleCancel}
                                        >
                                            <Trans i18nKey="institutions.cancel">Cancel</Trans>
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            disableElevation
                                            sx={{textTransform: 'none'}}
                                            onClick={handleSave}
                                        >
                                            <Trans i18nKey="institutions.save">Save</Trans>
                                        </Button>

                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </FormSection>
                </>
            }
        </Box>
    );
}

export default ClientContact;
