import {NavLink, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {
    Box,
    Breadcrumbs,
    Button,
    CircularProgress,
    Grid,
    Link,
    Paper,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import useApi from "../../hooks/useApi";
import useRouting from "../../hooks/useRouting";
import ClientGeneral from "./ClientGeneral";
import ClientContacts from "./ClientContacts";
import ClientBudgets from "./ClientBudgets";
import InvoiceReceivers from "./InvoiceReceivers";
import ClientProtocols from "./ClientProtocols";

function ClientMain(props) {
    const {t} = useTranslation();
    const {id, tab} = useParams();
    const [editable, setEditable] = React.useState(id === 'new');
    const [tabsDisabled, setTabsDisabled] = React.useState(id === 'new');
    const {queryClient} = useApi();
    const routing = useRouting();
    const {data: client, isLoading} = queryClient(id);
    const [currentTab, setCurrentTab] = React.useState(tab || 'general');

    const handleTabChange = (event, newValue) => {
        // setCurrentTab(newValue);
        routing.goToClient(id, newValue);
    }

    useEffect(() => {
        if (tab) {
            setCurrentTab(tab);
        }
    }, [tab]);

    return (
        <Box sx={{padding: 2}}>
            {isLoading ?
                <Stack sx={{flexGrow: 1, padding: 2}} alignItems="center">
                    <CircularProgress/>
                </Stack>
                :
                <>
                    <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: 2}}>
                        <NavLink underline="hover" color="inherit" to={routing.getClientsPath()}>
                            <Trans i18nKey="clients.header">Clients</Trans>
                        </NavLink>
                        <NavLink underline="hover" color="inherit" to={routing.getClientPath(id)}>
                            { client?.person ?
                                <Typography color="text.primary">{client?.person.last_name}, {client?.person.first_name}</Typography>
                                :
                                <Typography color="test.primary"><Trans i18nKey="clients.new-client">New Client</Trans></Typography>
                            }
                        </NavLink>
                        <Link underline="hover" color="inherit" onClick={(e) => handleTabChange(e,'general')}>
                            <Trans i18nKey={"clients.tabs." + currentTab}>Clients</Trans>
                        </Link>
                    </Breadcrumbs>
                    <Paper elevation={0} sx={{marginBottom: 2, padding: 2, paddingBottom: 0, maxWidth: 1024}}>
                        <Grid container alignItems="center">
                            <Grid item xs={8}>
                                <Box sx={{paddingLeft: 2, display: 'flex', justifyContent: 'flex-start'}}>
                                    {editable && client?.id &&
                                        <Typography variant="h5"><Trans i18nKey={"clients.edit-title"}>Edit
                                            client</Trans></Typography>
                                    }
                                    {editable && !client?.id &&
                                        <Typography variant="h5"><Trans
                                            i18nKey={"clients.create-title"}>Create
                                            client</Trans></Typography>
                                    }
                                    {!editable && client?.id &&
                                        <Typography variant="h5">XXX{client?.person.last_name}, {client?.person.first_name}</Typography>
                                    }

                                </Box>
                            </Grid>
                            <Grid item xs={4} justifyContent="flex-end">
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {!editable && currentTab === 'general' &&
                                        <Button
                                            sx={{textTransform: 'none'}}
                                            variant="outlined"
                                            size="small"
                                            disableElevation
                                            onClick={() => setEditable(!editable)}
                                        >
                                            <Trans i18nKey="clients.edit">Edit</Trans>
                                            {/*<EditOutlinedIcon />*/}
                                        </Button>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                        <Tabs
                            sx={{marginTop: 2}}
                            variant="scrollable"
                            scrollButtons="auto"
                            value={currentTab}
                            onChange={handleTabChange}
                            aria-label="nav tabs">
                            <Tab label={t('clients.tabs.general', 'General data')} value="general" />
                            {/*<Tab label={t('clients.tabs.administrative', 'Administrative data')} value="administrative" />*/}
                            <Tab disabled={tabsDisabled} label={t('clients.tabs.protocols', 'Protocols')} value="protocols" />
                            <Tab disabled={tabsDisabled} label={t('clients.tabs.invoicing', 'Invoice addresses')} value="invoicing" />
                            <Tab disabled={tabsDisabled} label={t('clients.tabs.Budgets', 'Budgets')} value="budgets" />
                            <Tab disabled={tabsDisabled} label={t('clients.tabs.contacts', 'Contacts')} value="contacts" />
                        </Tabs>
                    </Paper>
                    { tab === 'general' &&
                        <ClientGeneral client={client} editable={editable} setEditable={setEditable} />
                    }
                    { tab === 'contacts' &&
                        <ClientContacts client={client} editable={editable} setEditable={setEditable} paginationModel={props.paginationModel} setPaginationModel={props.setPaginationModel} />
                    }
                    { tab === 'budgets' &&
                        <ClientBudgets client={client} editable={editable} setEditable={setEditable} paginationModel={props.paginationModel} setPaginationModel={props.setPaginationModel} />
                    }
                    { tab === 'protocols' &&
                        <ClientProtocols client={client} editable={editable} setEditable={setEditable} paginationModel={props.paginationModel} setPaginationModel={props.setPaginationModel} />
                    }
                    { tab === 'invoicing' &&
                        <InvoiceReceivers client={client} editable={editable} setEditable={setEditable} paginationModel={props.paginationModel} setPaginationModel={props.setPaginationModel} />
                    }
                </>
            }
        </Box>
        // <Routes>
        //     <Route exact path={":id"} element={<Institution />} />
        // </Routes>
    );
}

export default ClientMain;
