import React, {useEffect, useState} from 'react'
import {unstable_useBlocker as useBlocker, useNavigate} from 'react-router-dom'
import {Trans} from "react-i18next";
import FormWarningDialog from "./FormWarningDialog";

function FormLeavingGuard({when, show, onCancel, onConfirm, text = null}) {

    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [lastLocation, setLastLocation] = useState(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const showModal = (location) => {
        setOpenModal(true);
        setLastLocation(location);
    }

    const closeModal = () => {
        if (show) {
            onCancel();
        } else {
            setOpenModal(false);
            setLastLocation(null);
        }
    }

    const handleBlockedNavigation = (nextLocation) => {
        if (!confirmedNavigation && when){
            showModal(nextLocation);
            return true;
        }
        return false;
    }

    const handleConfirmNavigationClick = () => {
        if (show) {
            onConfirm();
        } else {
            setOpenModal(false);
            setConfirmedNavigation(true);
        }
    }

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation]);

    useBlocker((tx) => {
        if (show) {
            return false;
        }
        return handleBlockedNavigation(tx.nextLocation);
    });

    useEffect(() => {
        if (show) {
            showModal(null);
        } else {
            closeModal();
        }
    }, [show]);

    return (
        <>
            <FormWarningDialog
                text={text}
                show={openModal}
                onCancel={closeModal}
                onConfirm={handleConfirmNavigationClick}/>
        </>
    )
}

export default FormLeavingGuard
