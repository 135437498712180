import {Grid, TextField, Typography} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import moment from "moment/moment";
import {useTheme} from "@mui/material/styles";
import useConfig from "../../hooks/useConfig";

const FormDateField = ({i18nKeyLabel, i18nKeyPlaceholder, i18nKeyDateFormat, valueText, editable, value, onChange, disabled, error, showValidation, required}) => {
    const theme = useTheme();
    const {t} = useTranslation();

    const {gridItemSxLabel, gridItemSxValue, gridItemSxValueEditable} = useConfig();

    return (<>
        <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
            <Trans i18nKey={i18nKeyLabel}>{i18nKeyLabel}</Trans>
            {editable && required && <Typography component='span' sx={{color: theme.palette.error.main}}>*</Typography>}
        </Grid>

        <Grid item xs={12} sm={8} sx={editable ? gridItemSxValueEditable : gridItemSxValue}>
            {editable ?
                <DesktopDatePicker
                    disabled={disabled}
                    disableToolbar
                    inputVariant="filled"
                    format="dd.MM.yyyy"
                    id={"form-date-field-" + i18nKeyLabel}
                    value={value}
                    placeholder={t(i18nKeyPlaceholder, i18nKeyPlaceholder)}
                    sx={{
                        backgroundColor: '#fff', '& input': {
                            fontSize: 14,
                        },
                        '& input::placeholder': {
                            fontSize: 14
                        }
                    }}
                    onChange={onChange}
                    slotProps={{textField: {size: 'small', error: showValidation && error}}}
                    textFieldProps={{variant: "filled", placeholder: "dd.MM.yyyy"}}
                 />
                :
                valueText ? t(i18nKeyDateFormat, {date: valueText.toJSDate()}) : '-'
            }
        </Grid>
    </>);
}

export default FormDateField;
