import {Fragment, useEffect, useState} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {Trans} from "react-i18next";

const ConfirmationModal = ({ isOpen, onClose, data, onConfirm, message }) => {
  const [isOpenInternal, setIsOpenInternal] = useState(isOpen);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
      setIsOpenInternal(isOpen);
      setIsDisabled(false);
    }, [isOpen]);


  const handleConfirm = () => {
      setIsDisabled(true);
      onConfirm(data);
      closeModal();
  };

  const closeModal = () => {
    // setIsOpenInternal(false);
    onClose();
  };

  return (
    <Transition.Root show={isOpenInternal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-20 inset-0 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="inline-block align-bottom bg-white rounded-lg px-4 py-3 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="mt-2 mb-4">{message}</div>
            <div className="mt-4 flex justify-end">
              <button
                disabled={isDisabled}
                type="button"
                className="mr-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                onClick={closeModal}
              >
                <Trans i18nKey="team-calendar.confirmation-modal.cancel">Cancel</Trans>
              </button>
              <button
                disabled={isDisabled}
                type="button"
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                onClick={handleConfirm}
              >
                <Trans i18nKey="team-calendar.confirmation-modal.confirm">Confirm</Trans>
              </button>
            </div>
          </span>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmationModal;
