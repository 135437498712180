import React from "react";
import "../../i18n/i18n";
import {QueryClient, QueryClientProvider} from "react-query";
import ReactDOMClient from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {deDE} from '@mui/x-data-grid';
import {createTheme, ThemeProvider} from "@mui/material";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {LocalizationProvider} from "@mui/x-date-pickers";
import Clients from "./Clients";
import ClientMain from "./ClientMain";
import ClientContact from "./ClientContact";
import ClientBudget from "./ClientBudget";
import InvoiceReceiver from "./InvoiceReceiver";
import ClientProtocol from "./ClientProtocol";
import useRouting from "../../hooks/useRouting";

const theme = createTheme({
        components: {
            MuiAutocomplete: {
                styleOverrides: {
                    noOptions: {
                        fontSize: '14px !important',
                    }
                }
            }
        },
        palette: {
            primary: {
                main: '#4E80EE',
            },
            secondary: {
                main: '#E89D27',
            },
        },
        typography: {
            fontFamily: "'Roboto', sans-serif",
            fontSize: 14,
        },
    },  deDE, // x-data-grid translations
);

const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            }
        }
    }
);

export default function ClientsContainer() {

    const clientPath = "/clients"

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 10,
        page: 0,
    });

    return <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Routes>
                        <Route exact path={clientPath + "/:id/:tab"} element={<ClientMain paginationModel={paginationModel} setPaginationModel={setPaginationModel}/>} />
                        <Route exact path={clientPath} element={<Clients paginationModel={paginationModel} setPaginationModel={setPaginationModel} />} />
                        <Route exact path={clientPath + "/:id/contacts/:contactId"} element={<ClientContact />} />
                        <Route exact path={clientPath + "/:id/budgets/:budgetId"} element={<ClientBudget />} />
                        <Route exact path={clientPath + "/:id/protocols/:protocolId"} element={<ClientProtocol />} />
                        <Route exact path={clientPath + "/:id/invoicing/:invoiceReceiverId"} element={<InvoiceReceiver />} />
                    </Routes>
                </BrowserRouter>
            </QueryClientProvider>
        </LocalizationProvider>
    </ThemeProvider>;
}

if (document.getElementById('clients-container')) {
    ReactDOMClient.createRoot(document.getElementById('clients-container')).render(<ClientsContainer/>);
}
