import {FormControl, Grid, InputAdornment, MenuItem, OutlinedInput, Select, Typography} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import useConfig from "../../hooks/useConfig";

const FormPhoneField = ({i18nKeyLabel, i18nKeyPlaceholder, valueText, editable, valuePhone, valueCountryCode, onPhoneChange, onCountryCodeChange, disabled, error, showValidation, required}) => {
    const theme = useTheme();
    const {t} = useTranslation();

    const {gridItemSxLabel, gridItemSxValue, gridItemSxValueEditable} = useConfig();

    return (<><Grid item xs={12} sm={4} sx={gridItemSxLabel}>
        <Trans i18nKey={i18nKeyLabel}>{i18nKeyLabel}</Trans>
        {editable && required && <Typography component='span' sx={{color: theme.palette.error.main}}>*</Typography>}
    </Grid>
        <Grid item xs={12} sm={8} sx={gridItemSxValue}>
            {editable ?
                <OutlinedInput
                    disabled={disabled}
                    id="phone"
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={valuePhone}
                    placeholder={t(i18nKeyPlaceholder, i18nKeyPlaceholder)}
                    sx={{fontSize: 14, backgroundColor: '#fff', paddingLeft: 0}}
                    onChange={onPhoneChange}
                    error={showValidation && error}
                    startAdornment={
                        <InputAdornment
                            position="start">
                            <FormControl
                                size='small'>
                                <Select sx={{fontSize: 14, "& fieldset": {
                                        border: "none",
                                    }}} value={valueCountryCode}
                                        onChange={onCountryCodeChange}
                                >
                                    <MenuItem value={'DE'}>DE</MenuItem>
                                    <MenuItem value={'AT'}>AT</MenuItem>
                                    <MenuItem value={'CH'}>CH</MenuItem>
                                </Select>
                            </FormControl>
                        </InputAdornment>
                    }
                />
                :
                valueText ? valueText : '-'
            }
        </Grid></>);
}

export default FormPhoneField;
